import { Stack, Typography } from '@mui/material';
import React from 'react';

export const WidgetHeader = ({
    mode,
    widgetData,
    sortedData,
    count,
    headerAction,
}: {
    mode: string | undefined;
    widgetData: any;
    sortedData?: any;
    count?: number;
    headerAction?: any;
}): JSX.Element => {
    const { widgetName, isTotalCountVisible, maxCount } = widgetData || {};
    const secondaryLength = sortedData?.secondary?.length || count || 0;
    const displayCount = secondaryLength >= (maxCount || 0) ? maxCount : secondaryLength;

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            ml={1}
            sx={
                mode === 'edit'
                    ? { position: 'absolute', top: '0px', height: '48px', left: '50px' }
                    : { height: '48px', padding: '16px' }
            }
        >
            <Typography variant="subtitle2" color="primary" fontWeight={'600'} fontSize={'14px'}>
                {widgetName}
                {isTotalCountVisible && Boolean(secondaryLength) && Boolean(displayCount) && (
                    <span>{`( ${displayCount} )`}</span>
                )}
            </Typography>
            {headerAction}
        </Stack>
    );
};
