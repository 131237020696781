import React from 'react';
import { UserMenu } from '@brightlayer-ui/react-components';

type MenuItem = {
    title: any;
    // Add any other properties you need for the menu items
};

type MenuGroup = {
    items: MenuItem[];
    // Add any other properties you need for menu groups
};

type CustomUserMenuProps = {
    id: string;
    menuGroups: MenuGroup[];
    menuTitle?: string;
    menuSubtitle?: string;
    avatar: React.ReactElement; // ReactNode type is used to allow any valid JSX as the avatar
};

export const CustomUserMenu: React.FC<CustomUserMenuProps> = ({ id, menuGroups, menuTitle, menuSubtitle, avatar }) => (
    <UserMenu
        className="user-menu fw-400 f-16"
        id={id}
        MenuProps={{
            style: {
                top: '16px',
            },
        }}
        avatar={avatar}
        menuGroups={menuGroups}
        menuTitle={menuTitle}
        menuSubtitle={menuSubtitle}
    />
);
