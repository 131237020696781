// eslint-disable-next-line @typescript-eslint/naming-convention
import React from 'react';

/**

* The below type represents an object that contains functions and properties related to a confirmation
* dialog.
* @property onCancel - A function that will be called when the user cancels the confirmation action.
* @property onConfirm - The `onConfirm` property is a function that will be called when the user
* confirms the action in the confirm object.
* @property onClick - The `onClick` property is a function that takes a parameter `clickedData` of
* type `any`. It is used to handle the click event when the user interacts with the object.
* @property {boolean} isVisible - A boolean value indicating whether the confirm object is currently
* visible or not.
* @property {any} data - The `data` property is a variable that can hold any type of data. It is used
* to pass additional information to the `onClick` function when it is called.
*/

type ConfirmObject = {
    onCancel: (args?: any) => void;
    onConfirm: () => void;
    onClick: (clickedData?: any) => void;
    isVisible: boolean;
    data: any;
};

export const useConfirm = (callback?: any, isClosable?: boolean, delay?: boolean): ConfirmObject => {
    const [isVisible, toggleIsVisible] = React.useState(false);
    const [data, updateData] = React.useState<object>({});

    React.useEffect(() => {
        if (isClosable) toggleIsVisible(false);
    }, [isClosable]);

    /**

     * The function `onConfirm` calls a callback function with some data and then toggles a boolean
     * value to false.
     */

    const onConfirm = (extraOptions?: any): void => {
        callback(data, extraOptions);
    };

    /**
     * The `onCancel` function toggles the visibility of something to false.
     */

    const onCancel = (resetData?: any): void => {
        toggleIsVisible(false);
        if (resetData) updateData({});
    };

    /**

     * The onClick function updates data and toggles visibility.
     * @param {any} clickedData - The `clickedData` parameter is of type `any`, which means it can
     * accept any data type. It represents the data that is passed when the click event occurs.
     */

    const onClick = (args: any): void => {
        updateData(args);
        if (delay) setTimeout(() => toggleIsVisible(true), 500);
        else toggleIsVisible(true);
    };

    return {
        onCancel,
        onConfirm,
        onClick,
        isVisible,
        data,
    };
};
