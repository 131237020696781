import React from 'react';

import { createNumArray } from 'utils/helpers';
import { Checkbox, ListItemButton, ListItemIcon, ListItemText, Skeleton, Typography } from '@mui/material';

export const ListSkeleton = ({
    total,
    primaryVariant,
    secondaryVariant,
    customSize,
    customSx,
}: {
    total: number;
    primaryVariant?: 'circular' | 'rectangular';
    secondaryVariant?: 'circular' | 'rectangular';
    customSize?: number;
    customSx?: any;
}): JSX.Element => (
    <>
        {createNumArray(total)?.map((item: any) => (
            <ListItemButton {...(customSx && { sx: customSx })} className="padding-8 border-bottom-1" dense key={item}>
                <Skeleton
                    sx={{ marginRight: '25px' }}
                    animation="wave"
                    variant={primaryVariant ?? 'circular'}
                    height={customSize ?? 22}
                    width={customSize ?? 22}
                >
                    <ListItemIcon />
                </Skeleton>
                <Skeleton animation="wave">
                    <ListItemText
                        className="text-secondary"
                        primary={
                            <Typography variant="body2" fontWeight={'600'}>
                                Manufacture Id
                            </Typography>
                        }
                    />
                </Skeleton>
                <ListItemIcon sx={{ justifyContent: 'flex-end', marginLeft: 'auto' }}>
                    <Skeleton
                        animation="wave"
                        variant={secondaryVariant ?? 'circular'}
                        height={customSize ?? 22}
                        width={customSize ?? 22}
                    >
                        <Checkbox edge="end" />
                    </Skeleton>
                </ListItemIcon>
            </ListItemButton>
        ))}
    </>
);
