import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const detailsInitialState: any = {
    isTotalCountVisible: false,
    viewAllButton: false,
    maxCount: 6,
};

const virtualLoadsWidgetSlice = createSlice({
    name: 'virtual_loads',
    initialState: detailsInitialState,
    reducers: {
        resetVirtualLoadConfiguration: () => {
            return detailsInitialState;
        },
        setPredefinedVirtualLoads: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                state[key] = action.payload[key];
            });
        },
        setDisplayPreferences: (state: RootState, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state) => {
            if (state.primary) {
                return { ...detailsInitialState, primary: [] };
            }
            return detailsInitialState;
        });
    },
});

export const { resetVirtualLoadConfiguration, setDisplayPreferences, setPredefinedVirtualLoads } =
    virtualLoadsWidgetSlice.actions;
export default virtualLoadsWidgetSlice;
