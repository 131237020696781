import React from 'react';

import { Paper, Grid } from '@mui/material';
import { ChannelList, RearrangeChannels } from './';
import { useGetChannelValues, useWidgetConfigHandlers } from '../hooks';
import { CommonConfigurationHeader } from 'pages/WidgetManagement';

export const Configuration = (): JSX.Element => {
    const {
        details: { widgetDetails },
    } = useWidgetConfigHandlers();

    useGetChannelValues(
        widgetDetails?.widgetType?.id === 'settings' || widgetDetails?.widgetType?.id === 'properties'
            ? 'details'
            : widgetDetails?.widgetType?.id
    );

    return (
        <Paper>
            <CommonConfigurationHeader type="source" />
            <Grid container p={2} justifyContent={'space-between'}>
                <ChannelList details={widgetDetails} />
                <RearrangeChannels details={widgetDetails} />
            </Grid>
        </Paper>
    );
};
