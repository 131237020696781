import React, { ReactNode } from 'react';
import { CustomModal, ModalActions } from './CustomModal';
import { Stack, DialogContent, Typography } from '@mui/material';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { CustomTransComponent } from './CustomTransComponent';

type DashboardChildDeleteModalProps = {
    isOpen: boolean;
    header: ReactNode;
    handleModalClose: () => void;
    handleConfirm: () => void;
    modalDescription: ReactNode;
    filteredData: any;
    titleKey: string;
    titleKey1?: string;
    subtitleKey: string;
    keyToTraverse: string;
    isDashboardChildOnly?: boolean;
};

export const DashboardChildDeleteModal = (props: DashboardChildDeleteModalProps): JSX.Element => {
    const modalRef = React.useRef<any>(null);
    const modalActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                props.handleModalClose();
            },
        },
    ];

    if (!props?.isDashboardChildOnly) {
        modalActions.push({
            key: 'continue',
            label: <CustomTransComponent translationKey={'COMMON:CONTINUE'} />,
            variant: 'contained',
            disabled: Boolean(props?.isDashboardChildOnly),
            handleClick: (): void => {
                props.handleModalClose();
                props.handleConfirm();
            },
        });
    }

    return (
        <>
            <CustomModal
                ref={modalRef}
                actions={modalActions}
                type="primary"
                header={props?.header}
                isOpen={props?.isOpen}
            >
                <DialogContent className="padding-0">
                    <Typography variant="body1">{props?.modalDescription}</Typography>
                    <Stack my={2} className="max-height-225 overflow-y-auto">
                        {props?.filteredData?.map((item: any) => (
                            <InfoListItem
                                sx={{ pl: 0 }}
                                key={item.id}
                                title={
                                    <Stack>
                                        <Typography variant="body1" fontWeight={'600'}>
                                            {item?.[props.titleKey]}
                                        </Typography>
                                        {props.titleKey1 && (
                                            <Typography variant="body2" fontSize={'14px'}>
                                                Serial# {item?.[props.titleKey1]}
                                            </Typography>
                                        )}
                                    </Stack>
                                }
                                subtitle={item?.[props?.subtitleKey]}
                                subtitleSeparator={'/'}
                                ripple
                                hidePadding={true}
                            />
                        ))}
                    </Stack>
                </DialogContent>
            </CustomModal>
        </>
    );
};
