import { api } from '../../app/api/baseApi';

const actionsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getFilterActions: builder.query<object, object>({
            query: ({ body, params }: { body: any; params?: any }) => ({
                url: `ontology/v1/channels/actions`,
                method: 'POST',
                body,
                ...(params && { params }),
            }),
        }),

        getAllActions: builder.query<object, void>({
            query: () => ({
                url: `/ns/v1/actions/list`,
            }),
        }),
    }),
});

export const { useGetAllActionsQuery, useGetFilterActionsQuery } = actionsApi;
