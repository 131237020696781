import { RootState } from '../../app/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const realmSlice = createSlice({
    name: 'realm',
    initialState: {
        addRealmModal: {
            isOpen: false,
            parentId: '',
        },
        realmPath: [],
        refetchRealmDetails: undefined,
        moveRealmAndOrgsModal: {
            isOpen: false,
        },
        isCurrentRealmSuspended: false,
        isRealmEditable: false,
    },
    reducers: {
        setAddRealmModal: (state: RootState, action: PayloadAction<any>) => {
            state.addRealmModal = action.payload;
        },
        setRefetchRealmDetails: (state: RootState, action: PayloadAction<any>) => {
            state.refetchRealmDetails = action.payload;
        },
        setMoveRealmAndOrgsModal: (state: RootState, action: PayloadAction<any>) => {
            state.moveRealmAndOrgsModal = action.payload;
        },
        setIsCurrentRealmSuspended: (state: RootState, action: PayloadAction<any>) => {
            state.isCurrentRealmSuspended = action.payload;
        },
        setIsRealmEditable: (state, action: PayloadAction<boolean>) => {
            state.isRealmEditable = action.payload;
        },
        setRealmPath: (state, action: PayloadAction<any>) => {
            state.realmPath = action.payload;
        },
    },
});

export const {
    setAddRealmModal,
    setRefetchRealmDetails,
    setMoveRealmAndOrgsModal,
    setIsCurrentRealmSuspended,
    setIsRealmEditable,
    setRealmPath,
} = realmSlice.actions;

export const refetchRealmDetails = (state: RootState): any => state?.realm?.refetchRealmDetails;

export default realmSlice;
