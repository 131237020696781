import { HierarchyFilter } from '../types';

export const getFilterExpression = (treeItem: any, filterResults?: HierarchyFilter): boolean => {
    switch (filterResults?.operator) {
        case '==': {
            // eslint-disable-next-line eqeqeq
            return treeItem?.[filterResults?.key] == filterResults?.value;
        }
        case '===': {
            return treeItem?.[filterResults?.key] === filterResults?.value;
        }
        case '!=': {
            // eslint-disable-next-line eqeqeq
            return treeItem?.[filterResults?.key] != filterResults?.value;
        }
        case '!==': {
            return treeItem?.[filterResults?.key] !== filterResults?.value;
        }
        case '>': {
            return treeItem?.[filterResults?.key] > filterResults?.value;
        }
        case '<': {
            return treeItem?.[filterResults?.key] < filterResults?.value;
        }
        case '>=': {
            return treeItem?.[filterResults?.key] >= filterResults?.value;
        }
        case '<=': {
            return treeItem?.[filterResults?.key] <= filterResults?.value;
        }
        default: {
            return true;
        }
    }
};
