import React from 'react';
import { CustomPlaceholder } from './CustomPlaceHolder';
import { InputBase, Stack } from '@mui/material';

export const InputWithCustomPlaceholder = ({ placeholder, ...props }: any): JSX.Element => {
    const [hasValue, setHasValue] = React.useState(false);

    return (
        <Stack className="w-100">
            <CustomPlaceholder hasValue={hasValue} placeholder={placeholder} />
            <InputBase
                {...props}
                onBlur={(e) => setHasValue(e.target.value.length > 0)}
                onFocus={() => setHasValue(true)}
                className={`f-16 fw-600 w-100 padding-x-8 ${hasValue ? 'padding-y-12' : ''}`}
            />
        </Stack>
    );
};
