import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { channelsHandler, handleChannelRemove, handleGroupDevicesFilter, moveWithinList } from './helpers';

const componentInitialState = {
    filters: {},
    selectedNodes: [],
    secondary: [],
    maxCount: 6,
    viewAllButton: false,
    isTotalCountVisible: false,
};
const componentWidgetSlice = createSlice({
    name: 'components',
    initialState: componentInitialState,
    reducers: {
        resetComponentConfiguration: () => {
            return componentInitialState;
        },
        setDevice: (state: RootState, action: PayloadAction<any>) => {
            channelsHandler(state, action);
        },
        removeComponentNode: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
        },
        setComponentFilters: (state: RootState, action: PayloadAction<any>) => {
            state['filters'] = action.payload;
        },
        setComponentSelectedNodes: (state: RootState, action: PayloadAction<any>) => {
            handleGroupDevicesFilter(state, action);
        },
        setComponentData: (state: RootState, action: PayloadAction<any>) => {
            state.secondary = action.payload;
        },
        setComponentPreferences: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
        moveBetweencomponent: (state: RootState, action: PayloadAction<any>) => {
            moveWithinList(state, action);
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state: RootState) => {
            state.secondary = [];
            state.selectedNodes = [];
            state.filters = {};
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state: RootState, action: any) => {
            if (!action.payload.reset) {
                state.secondary = [];
            }
            state['deviceId'] = action.payload?.data?.id;
        });
    },
});

export const {
    setDevice,
    moveBetweencomponent,
    removeComponentNode,
    resetComponentConfiguration,
    setComponentData,
    setComponentPreferences,
    setComponentSelectedNodes,
    setComponentFilters,
} = componentWidgetSlice.actions;

export default componentWidgetSlice;
