import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';

type GroupPathProps = {
    pathName: any[];
    width?: string;
    deviceName?: string;
    tooltip?: boolean;
    fontWeight?: any;
    separator?: any;
    color?: string;
};

export const GroupPath = ({
    pathName,
    width,
    deviceName,
    tooltip,
    fontWeight = 300,
    separator,
    color,
}: GroupPathProps): JSX.Element =>
    Array.isArray(pathName) ? (
        <Tooltip title={`${deviceName}/${pathName?.join('/')}`} disableHoverListener={!tooltip} arrow>
            <Typography
                variant={'body2'}
                sx={{
                    textOverflow: 'ellipsis',
                    width,
                    fontWeight,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    color,
                }}
            >
                {deviceName && <span style={{ fontWeight: 400 }}>{deviceName ?? ''}</span>}
                {pathName?.length > 0 && deviceName && (
                    <ChevronLeft fontSize="small" sx={{ position: 'relative', top: '5px' }} />
                )}
                {pathName?.map(
                    (groupName: string, index: number): JSX.Element => (
                        <span key={groupName}>
                            <span style={{ fontWeight }}>{groupName}</span>
                            {index !== pathName.length - 1 ? (
                                !separator ? (
                                    <ChevronLeft fontSize="small" sx={{ position: 'relative', top: '5px' }} />
                                ) : (
                                    ` ${separator}`
                                )
                            ) : (
                                ''
                            )}
                        </span>
                    )
                )}
            </Typography>
        </Tooltip>
    ) : (
        <></>
    );
