import { RootState } from '../../app/store';
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';

// const initialState: { mappingKeys: any } = { mappingKeys: {} };
const entitlementSlice = createSlice({
    name: 'entitlement',
    initialState: {
        planFeatures: [],
        deviceTypeIds: [] as any,
        entitlementInput: {
            name: '',
            description: '',
            activeServiceContract: false,
            basePlan: false,
            reveneraSupported: false,
        },
    },
    reducers: {
        setEntitlementInput: (state, action: PayloadAction<any>) => {
            state.entitlementInput = action.payload;
        },
        setPlanfeatures: (state, action: PayloadAction<any>) => {
            state.planFeatures = action.payload;
        },
        setDeviceTypeIds: (state, action: PayloadAction<any>) => {
            if (Object?.keys(action?.payload ?? {})?.length) {
                const deviceTypeIndex: any = current(state).deviceTypeIds?.findIndex((deviceTypeId: any) => {
                    return action?.payload?.id === deviceTypeId?.id;
                });
                if (deviceTypeIndex === -1) {
                    state.deviceTypeIds.push(action?.payload);
                } else {
                    state.deviceTypeIds.splice(deviceTypeIndex, 1);
                }
            } else {
                state.deviceTypeIds = action.payload;
            }
        },
        setDeviceTypeIdsDefault: (state, action: PayloadAction<any>) => {
            state.deviceTypeIds = action.payload?.filter(
                (value: any, index: number, self: any) => index === self.findIndex((t: any) => t.id === value.id)
            );
        },
    },
});

export const { setEntitlementInput, setPlanfeatures, setDeviceTypeIds, setDeviceTypeIdsDefault } =
    entitlementSlice.actions;
export const selectEntitlementInput = (state: RootState): any => state.entitlement.entitlementInput;
export const selectPlanFeatures = (state: RootState): any => state.entitlement.planFeatures;
export const selectDeviceTypeIds = (state: RootState): any => state.entitlement.deviceTypeIds;

export default entitlementSlice;
