import { useState } from 'react';
import { NodeModel, TreeProps, DropOptions } from '../types';

export const useDropHandler = <T>(
    args: TreeProps<T>
): [Array<NodeModel<T>>, (newTree: Array<NodeModel<T>>, options: DropOptions<T>) => void, any] => {
    const [tree, setTree] = useState(args.tree);
    const handleDrop = (newTree: Array<NodeModel<T>>, options: DropOptions<T>): void => {
        args.onDrop(newTree, options);
        setTree(newTree);
    };
    return [tree, handleDrop, setTree];
};
