import React, { useState } from 'react';
import { useLazyGetTranslationsByCategoryQuery } from '@fiji/common/src/features/localization/localizationApi';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { ApiEndpointType, ApiResponseType } from '@fiji/common/src/features/common/commonTypes';
import i18next from '../utils/i18n';
import { Profile } from '@fiji/common/src/types';
import englishTranslations from '@fiji/common/src/translations/english';

export const useNewCustomTranslations = (category: string, skip?: boolean): any => {
    const [isLoading, setIsLoading] = useState(false);
    const { data: userProfileData } = useGetUserProfileQuery({}, { skip }) as ApiEndpointType<ApiResponseType<Profile>>;
    const [getTranslation] = useLazyGetTranslationsByCategoryQuery<any>();
    const getCategoryTranslations = async (): Promise<void> => {
        const { data, error }: any = await getTranslation({
            body: category,
            locale: userProfileData?.data?.language ?? 'en',
        });
        if (!error) i18next.addResourceBundle('en', category, data?.data ?? {}, true, true);
        else
            i18next.addResourceBundle(
                'en',
                category,
                englishTranslations[category] ?? englishTranslations.translation[category] ?? {},
                true,
                true
            );
        setIsLoading(false);
    };

    React.useEffect(() => {
        if (!skip) {
            if (!i18next.hasResourceBundle('en', category)) {
                setIsLoading(true);
                void getCategoryTranslations();
            }
        }
    }, [skip, category]);

    return {
        isLoading: isLoading,
    };
};
