/* eslint-disable @typescript-eslint/no-this-alias */
import React, { useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSortedData } from '../../../../hooks/useSortedData';
import { Box, Button, Divider, FormControl, MenuItem, Paper, Radio, Select, Stack } from '@mui/material';

import { WidgetFooter } from 'pages/WidgetManagement/common/WidgetFooter';
import { WidgetHeader } from 'pages/WidgetManagement/common/WidgetHeader';
import { MultiColumnList } from 'pages/Trends/MultiColumnList';
import { LegendData } from 'pages/Trends/Trends';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Download from '@mui/icons-material/Download';
import { useIsMount } from 'hooks';
import { StyledMenu } from 'pages/Chart/StyledMenu';
import { unitList } from 'pages/Chart/Chart';
import { TREND_INITIAL_CONFIG } from 'pages/WidgetManagement/common';
import { getTimeInFromTo } from '@fiji/common/src/utils/helpers';
import { useTransformData } from 'hooks/useTransformData';

export type TelemetryChartProps = {
    type?: 'area' | 'line';
    widgetTypeId?: string;
    route?: string;
    state?: string;
    maxCount?: number;
    secondary: any[];
    widgetName?: string;
    [key: string]: any;
};

const Component = React.forwardRef(
    (
        {
            widgetData,
            mode,
            channelData,
            payloadChangeHandler,
            downloadHandler,
        }: {
            widgetData: TelemetryChartProps;
            mode?: string;
            channelData?: any;
            payloadChangeHandler: any;
            downloadHandler: any;
        },
        ref
    ): any => {
        const { ids } = useSortedData(widgetData ?? [], ['secondary']);
        const { pointConversion, timezoneOffset } = useTransformData();

        const isMount = useIsMount();
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

        const chartRef: any = useRef(null);
        const [legendData, setLegendData] = useState<LegendData[]>(
            channelData?.data?.map((item: any) => ({
                channelId: item?.channelId,
                color: item?.color,
                visible: true,
                name: item?.displayName,
            })) ?? []
        );
        const [channelIds, setChannelIds] = useState([]);
        const [activeDuration, setActiveDuration] = useState<number>(widgetData?.duration ?? 24);
        const [selectedUnits, setSelectedUnits] = React.useState<any>(widgetData?.selectedUnit ?? '');
        const open = Boolean(anchorEl);

        const [options, setOptions] = useState<any>(TREND_INITIAL_CONFIG);

        const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = (): void => {
            setAnchorEl(null);
        };

        React.useEffect(() => {
            if (!isMount && widgetData) {
                payloadChangeHandler?.({
                    channelIds,
                    activeDuration: getTimeInFromTo(activeDuration, 'h'),
                });
            }
        }, [activeDuration, channelIds]);

        const getSeriesData = (data: any): any[] =>
            data?.map((trend: any) => ({
                id: `${trend?.deviceId}-${trend?.channelId}`,
                name: trend?.displayName,
                data: trend?.data?.map((point: any): any => [point?.x, point?.y]),
                color: trend?.color,
                type: widgetData?.type ?? 'line',
                tooltip: {
                    valueSuffix: trend?.unit ? ` ${trend?.unit}` : '',
                },
            }));

        const getUnit = (axis: any): string => {
            let unit = '';
            const current: any = chartRef?.current;
            current?.chart?.series.forEach((series: any): void => {
                if (series?.dataMax === axis?.axis?.dataMax) {
                    unit = series?.tooltipOptions?.valueSuffix;
                    return;
                }
            });
            return unit;
        };

        const getYAxis = (): any => ({
            title: {
                text: '',
            },
            labels: {
                formatter: function (): any {
                    const yAxis: any = this;
                    return `${pointConversion(yAxis?.value)}${getUnit(this)}`;
                },
            },
        });

        const addPoints = (message: any): void => {
            const current: any = chartRef?.current;
            const seriesIndex = current?.chart?.series.findIndex(
                (s: any) => s?.userOptions?.id === `${message?.deviceId}-${message?.channelId}`
            );

            if (seriesIndex !== -1) {
                current?.chart?.series?.[seriesIndex]?.addPoint([message.timestamp, +message.value], true, false);
                if (
                    message?.unit &&
                    current?.chart?.series?.[seriesIndex]?.tooltipOptions?.valueSuffix !== ` ${message?.unit}`
                ) {
                    current?.chart?.series?.[seriesIndex]?.update({
                        tooltip: { valueSuffix: ` ${message?.unit}` },
                    });
                    current?.chart?.yAxis[seriesIndex].update({
                        title: {
                            text: '',
                        },
                        labels: {
                            formatter: function (): any {
                                const yAxis: any = this;

                                return `${pointConversion(yAxis?.value)}${` ${message?.unit}`}`;
                            },
                        },
                    });
                }
                current?.chart?.hideLoading();
            }
        };

        React.useImperativeHandle(
            ref,
            () => ({
                addPoints: (message: any): void => addPoints(message),
            }),
            [widgetData]
        );

        const getFilter = (): JSX.Element => (
            <Box display={'flex'} alignItems={'center'}>
                <FormControl sx={{ minWidth: 80 }}>
                    <Select
                        disabled={mode !== 'view'}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Age"
                        variant="standard"
                        placeholder="15 Days"
                        disableUnderline
                        defaultValue={widgetData?.duration ?? 24}
                        onChange={(e: any): void => setActiveDuration(e?.target?.value)}
                    >
                        <MenuItem value={24}>24 Hours</MenuItem>
                        <MenuItem value={360}>15 Days</MenuItem>
                        <MenuItem value={744}>31 Days</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    disabled={mode !== 'view'}
                    startIcon={
                        <>
                            {/* {isUrlLoading && <Loader size={14} />} */}
                            <Download
                                sx={{
                                    color: mode !== 'view' ? '#b2b2b8' : '#727e84',
                                }}
                            />
                        </>
                    }
                    onClick={(): void => downloadHandler?.()}
                    className="download-icon"
                />
            </Box>
        );

        React.useEffect(() => {
            const chart = chartRef?.current?.chart;
            let data: any = JSON.parse(JSON.stringify(widgetData?.secondary ?? []));

            if (selectedUnits) {
                data = data?.filter((trend: any) => trend?.unit === selectedUnits);
            }
            const newOption = JSON.parse(JSON.stringify(options));
            if (chart && widgetData?.secondary?.length && data?.some((item: any) => Boolean(item?.data?.length))) {
                newOption['yAxis'] = getYAxis();
                newOption['series'] = getSeriesData(data);
                newOption['chart'] = {
                    type: widgetData?.type ?? 'line',
                    zoomType: 'x',
                    zooming: {
                        mouseWheel: {
                            enabled: true,
                            sensitivity: 1.1,
                            type: 'x',
                        },
                    },
                };
                // newOption['tooltip'] = {
                //     formatter: function (): any {
                //         const yAxis: any = this;
                //         return yAxis?.y;
                //     },
                // };
                setOptions(newOption);
                chart?.hideLoading();
            } else if (chart) {
                newOption['series'] = [];
                setOptions(newOption);
                chart?.showLoading('No Trends Data Available');
            }
        }, [widgetData, selectedUnits]);

        React.useEffect(() => {
            if (!isNaN(timezoneOffset)) {
                Highcharts.setOptions({
                    time: {
                        timezoneOffset: timezoneOffset,
                    },
                    lang: {
                        thousandsSep: '',
                    },
                });
            }
        }, [timezoneOffset]);

        const changeHandler = (e: any, id: number): void => {
            const newLegendData = JSON.parse(JSON.stringify(legendData));
            const index = newLegendData?.findIndex((legend: any): any => legend.id === id);
            if (index > -1) {
                newLegendData[index].visible = e.target.checked;
            }
            const newChannelIds: any = [];
            newLegendData.map((legend: any) => {
                if (legend.visible) {
                    newChannelIds.push(legend);
                }
            });
            setChannelIds(newChannelIds);
            setLegendData(newLegendData);
            const current: any = chartRef?.current;
            if (current?.chart?.series?.length > 0) current?.chart.series.forEach((series: any) => series.remove());
        };

        return (
            <Paper sx={{ height: '100%' }}>
                <WidgetHeader mode={mode} widgetData={widgetData} headerAction={getFilter()} />
                {mode !== 'edit' && <Divider />}
                <Stack
                    sx={{
                        height: `calc(100% - 97px)`,
                        overflow: 'auto',
                    }}
                >
                    <Stack
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems={'flex-end'}
                        p={2}
                        pr={5}
                    >
                        <Box mr={2}>
                            <Button
                                disabled={mode !== 'view'}
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: '#424E54',
                                }}
                                onClick={handleClick}
                                endIcon={!open ? <ArrowDropDown /> : <ArrowDropUp />}
                            >
                                Units
                            </Button>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                {unitList?.map((item: any): any => (
                                    <MenuItem key={item?.value} value={item?.value} className="w-100">
                                        <Radio
                                            className="margin-right-16"
                                            checked={selectedUnits === item.value}
                                            onChange={(): void => setSelectedUnits(item.value)}
                                        />
                                        {`(${item?.value}) ${item.name}`}
                                    </MenuItem>
                                ))}
                            </StyledMenu>
                        </Box>
                    </Stack>
                    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
                    <MultiColumnList
                        isLoading={false}
                        legendData={legendData}
                        changeHandler={changeHandler}
                        maxItemsPerColumn={5}
                    />
                </Stack>
                <WidgetFooter
                    mode={mode ?? ''}
                    ids={ids}
                    widgetData={{ ...widgetData, viewAllButton: true }}
                    disabled={!widgetData?.secondary?.length}
                    actionBtnName="View Full Trendviewer"
                />
            </Paper>
        );
    }
);
export default Component;
