import { Slide } from '@mui/material';
import { TransitionProps } from 'notistack';
import React from 'react';

/* The code is exporting a functional component called `Transition`. This component is created using
the `React.forwardRef` function, which allows the component to receive a `ref` prop. */
export const Transition = React.forwardRef(
    (
        props: TransitionProps & {
            children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>
    ) => <Slide direction="up" ref={ref} {...props} />
);
