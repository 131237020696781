import React from 'react';

import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import {
    moveBetweenLists,
    reAllocateChannels,
    removeChannel,
    setChannels,
} from '@fiji/common/src/features/widgetManagement/detailsWidgetSlice';
import { setGaugeChannels } from '@fiji/common/src/features/widgetManagement/gaugeWidgetSlice';
import { DropResult } from 'react-beautiful-dnd';
import { useGetChannelThresholdsQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import {
    moveBetweenChartLists,
    reAllocateChartChannels,
    removeChartChannel,
    setChartChannels,
} from '@fiji/common/src/features/widgetManagement/chartWidgetSlice';
import {
    reAllocateTrendsChannels,
    removeTrendsChannel,
    setTrendsChannels,
} from '@fiji/common/src/features/widgetManagement/trendsWidgetSlice';
import { reAllocateLoads, removeLoad, setLoads } from '@fiji/common/src/features/widgetManagement/loadsWidgetSlice';
import { reallocateDeviceList, removeDevice } from '@fiji/common/src/features/widgetManagement/deviceListWidgetSlice';
import {
    reallocateTimelineFilters,
    removeTimelineFilters,
} from '@fiji/common/src/features/widgetManagement/timelineWidgetSlice';
import { reallocateGroupList, removeGroup } from '@fiji/common/src/features/widgetManagement/groupListWidgetSlice';
import {
    removeGroupLinkedToMap,
    reallocateMapSelectedNodes,
} from '@fiji/common/src/features/widgetManagement/mapWidgetSlice';
import { removeUpcomingNode } from '@fiji/common/src/features/widgetManagement/upcomingWidgetSlice';
import {
    reAllocateCommandChannels,
    removeDeviceCommand,
    setCommandChannels,
    setWidgetKey,
} from '@fiji/common/src/features/widgetManagement/commandBarWidgetSlice';

type ReturnProps = {
    onDragEnd: (args: DropResult) => void;
    handleRemoveChannel: (args: any) => void;
    handleChecked: (args: any) => boolean;
    isRadioSelected: (args: any) => boolean;
    handleGaugeChannelSelection: (args: any) => void;
    handleChannelSelection: (args0: any, args1?: any) => void;
    handleChannelTypeSelection: (args: any) => string;
    isCommandPaletteChecked: (arg: any, currentType: string, currentColor: string) => boolean;
};

export const useDefaultConfig = (widgetTypeId: string, data?: any): ReturnProps => {
    const dispatch = useAppDispatch();
    const channelPayload = useTypedSelector((state) => state[widgetTypeId]);
    const configurationPayload = useTypedSelector((deviceData) => deviceData.widgetConfiguration);
    const commandPaletteEl = useTypedSelector((state) => state.command_palette.commandPaletteEl);

    useGetChannelThresholdsQuery(
        {
            deviceId: configurationPayload?.device?.id,
            channelId: configurationPayload?.data?.channel?.channelId,
        },
        { skip: !configurationPayload?.device?.id || !configurationPayload?.data?.channel?.channelId }
    );

    React.useEffect(() => {
        if (data && widgetTypeId === 'gauge' && configurationPayload?.device?.id && !channelPayload?.data) {
            dispatch(setGaugeChannels({ ...data.data[0], deviceId: configurationPayload.device.id }));
        }
    }, [widgetTypeId, data, configurationPayload]);

    function channelSelectionHandlers(payload: any, palettePayload?: any): any {
        switch (widgetTypeId) {
            case 'charts':
                dispatch(setChartChannels(payload));
                break;
            case 'trends':
                dispatch(setTrendsChannels(payload));
                break;
            case 'loads':
                dispatch(setLoads(payload));
                break;
            case 'command_bar':
                if (palettePayload) dispatch(setWidgetKey(palettePayload));
                if (payload) dispatch(setCommandChannels(payload));
                break;
            default:
                dispatch(setChannels(payload));
                break;
        }
    }

    function isCommandPaletteChecked(payload: any, currentType: string, currentColor: string): boolean {
        const selectedChannel = payload?.secondary?.find(
            (item: any) =>
                item?.channelId === commandPaletteEl?.channelId && item?.deviceId === commandPaletteEl?.deviceId
        );
        return (
            selectedChannel?.paletteConfig?.color === currentColor &&
            selectedChannel?.paletteConfig?.type === currentType
        );
    }

    function dragEndHandlers(payload: any): any {
        switch (widgetTypeId) {
            case 'charts':
                dispatch(reAllocateChartChannels(payload));
                break;
            case 'trends':
                dispatch(reAllocateTrendsChannels(payload));
                break;
            case 'loads':
                dispatch(reAllocateLoads(payload));
                break;
            case 'device_list':
                dispatch(reallocateDeviceList(payload));
                break;
            case 'timeline':
                dispatch(reallocateTimelineFilters(payload));
                break;
            case 'group_list':
                dispatch(reallocateGroupList(payload));
                break;
            case 'map':
                dispatch(reallocateMapSelectedNodes(payload));
                break;
            case 'command_bar':
                dispatch(reAllocateCommandChannels(payload));
                break;
            default:
                dispatch(reAllocateChannels(payload));
                break;
        }
    }

    function dragBetweenListHandlers(payload: any): any {
        switch (widgetTypeId) {
            case 'charts':
                dispatch(moveBetweenChartLists(payload));
                break;
            case 'trends':
                dispatch(moveBetweenChartLists(payload));
                break;
            default:
                dispatch(moveBetweenLists(payload));
                break;
        }
    }

    function removeChannelHandlers(payload: any): any {
        switch (widgetTypeId) {
            case 'charts':
                dispatch(removeChartChannel(payload));
                break;
            case 'trends':
                dispatch(removeTrendsChannel(payload));
                break;
            case 'loads':
                dispatch(removeLoad(payload));
                break;
            case 'device_list':
                dispatch(removeDevice(payload));
                break;
            case 'timeline':
                dispatch(removeTimelineFilters(payload));
                break;
            case 'group_list':
                dispatch(removeGroup(payload));
                break;
            case 'map':
                dispatch(removeGroupLinkedToMap(payload));
                break;
            case 'upcoming':
                dispatch(removeUpcomingNode(payload));
                break;
            case 'command_bar':
                dispatch(removeDeviceCommand(payload));
                break;
            default:
                dispatch(removeChannel(payload));
                break;
        }
    }

    const onDragEnd = (result: DropResult): void => {
        if (result.destination) {
            const { source, destination } = result;
            if (source.droppableId === destination.droppableId) {
                dragEndHandlers({
                    type: source?.droppableId,
                    sourceIndex: source?.index,
                    destinationIndex: destination?.index,
                });
            } else {
                // Move between lists
                dragBetweenListHandlers({
                    draggableId: source.droppableId,
                    droppableId: destination.droppableId,
                    sourceIndex: source.index,
                    destinationIndex: destination.index,
                });
            }
        }
    };

    const handleRemoveChannel = (payload: any): void => {
        removeChannelHandlers(payload);
    };

    const handleChecked = (channel: any, customTraverseKey?: string): boolean => {
        if (widgetTypeId === 'details') {
            switch (true) {
                case Boolean(channelPayload?.primary?.length) && Boolean(channelPayload?.secondary?.length):
                    return channelPayload?.primary
                        ?.concat(channelPayload?.secondary)
                        ?.some((item: any) => channel?.channelId === item?.channelId);
                case Boolean(channelPayload?.primary?.length):
                    return channelPayload?.primary?.some((item: any) => channel?.channelId === item?.channelId);
                case Boolean(channelPayload?.secondary?.length):
                    return channelPayload?.secondary?.some((item: any) => channel?.channelId === item?.channelId);
                default:
                    return false;
            }
        }
        return channelPayload?.[customTraverseKey ?? 'secondary']?.some(
            (item: any) =>
                channel?.[widgetTypeId === 'loads' ? 'loadId' : 'channelId'] ===
                item?.[widgetTypeId === 'loads' ? 'loadId' : 'channelId']
        );
    };

    const handleChannelTypeSelection = (channel: any): string => {
        if (channelPayload?.secondary?.some((item: any) => channel?.channelId === item?.channelId)) {
            return 'secondary';
        }
        return 'primary';
    };

    const handleChannelSelection = (payload: any, palettePayload?: any): void => {
        channelSelectionHandlers(payload, palettePayload);
    };

    const handleGaugeChannelSelection = (payload: any): void => {
        dispatch(setGaugeChannels(payload));
    };

    const isRadioSelected = (channelId: any): boolean => channelPayload?.data?.channelId === channelId;

    return {
        onDragEnd,
        handleGaugeChannelSelection,
        isRadioSelected,
        handleRemoveChannel,
        handleChecked,
        handleChannelTypeSelection,
        handleChannelSelection,
        isCommandPaletteChecked,
    };
};
