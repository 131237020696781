import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { handleChannelRemove, handleGroupDevicesFilter, handleReallocateChannels } from './helpers';

const mapInitialState = { markers: [], clustering: true, filters: {}, selectedNodes: [], viewAllButton: true };
const mapWidgetSlice = createSlice({
    name: 'map',
    initialState: mapInitialState,
    reducers: {
        resetMapConfiguration: () => {
            return mapInitialState;
        },
        setMapData: (state: RootState, action: PayloadAction<any>) => {
            state.markers = action.payload
                ?.filter?.((item: any) => item.type === 'GROUP' && item.coordinates)
                ?.map((group: any) => ({
                    id: group?.id,
                    name: group?.name,
                    coordinates: group?.coordinates,
                }));
        },
        removeGroupLinkedToMap: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
        },
        setMapfilters: (state: RootState, action: PayloadAction<any>) => {
            state['filters'] = action.payload;
        },
        setMapSelectedNodes: (state: RootState, action: PayloadAction<any>) => {
            handleGroupDevicesFilter(state, action);
        },
        setMapPreferences: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
        reallocateMapSelectedNodes: (state: RootState, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state: RootState) => {
            state.secondary = [];
            state.selectedNodes = [];
            state.filters = {};
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state: any, action: any) => {
            if (action.payload.reset) state.data = { ...(state.data ?? {}), deviceId: action.payload.data.id };
        });
    },
});

export const {
    resetMapConfiguration,
    setMapPreferences,
    setMapData,
    removeGroupLinkedToMap,
    reallocateMapSelectedNodes,
    setMapSelectedNodes,
    setMapfilters,
} = mapWidgetSlice.actions;

export default mapWidgetSlice;
