import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { channelsHandler, handleChannelRemove, handleGroupDevicesFilter, handleReallocateChannels } from './helpers';

const deviceListInitialState = {
    filters: {},
    selectedNodes: [],
    sortPayload: {
        key: 'deviceName',
        sortType: 'DESC',
    },
    maxCount: 6,
    secondary: [],
    viewAllButton: false,
    isTotalCountVisible: false,
};
const deviceListWidgetSlice = createSlice({
    name: 'device_list',
    initialState: deviceListInitialState,
    reducers: {
        setDevice: (state: RootState, action: PayloadAction<any>) => {
            channelsHandler(state, action);
        },
        removeDevice: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
            if (!state['selectedNodes']?.length) {
                state['secondary'] = [];
            }
        },
        setDeviceData: (state: RootState, action: PayloadAction<any>) => {
            state.secondary = action.payload;
        },
        setDeviceListSortPayloadOrder: (state: RootState, action: PayloadAction<any>) => {
            state['sortPayload']['sortType'] = action.payload;
        },
        setDeviceListSortPayloadKey: (state: RootState, action: PayloadAction<any>) => {
            state['sortPayload']['key'] = action.payload;
        },
        setDeviceListFilters: (state: RootState, action: PayloadAction<any>) => {
            state['filters'] = action.payload;
        },
        setDeviceListSelectedNodes: (state: RootState, action: PayloadAction<any>) => {
            handleGroupDevicesFilter(state, action);
            if (!state['selectedNodes']?.length) {
                state['secondary'] = [];
            }
        },

        setDeviceListPreferences: (state: RootState, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },

        reallocateDeviceList: (state: RootState, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },
        resetDeviceListConfiguration: () => {
            return deviceListInitialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state: RootState) => {
            state.secondary = [];
            state.selectedNodes = [];
            state.filters = {};
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state: RootState, action: any) => {
            if (!action.payload.reset) {
                state.secondary = [];
            }
            state['deviceId'] = action.payload?.data?.id;
        });
    },
});

export const {
    removeDevice,
    setDeviceData,
    setDeviceListSelectedNodes,
    setDeviceListFilters,
    setDeviceListSortPayloadKey,
    setDeviceListSortPayloadOrder,
    reallocateDeviceList,
    setDeviceListPreferences,
    resetDeviceListConfiguration,
} = deviceListWidgetSlice.actions;

export default deviceListWidgetSlice;
