import { RootState } from '../../app/store';
import { createSlice } from '@reduxjs/toolkit';

const initialTagState = false;

const tagSlice = createSlice({
    name: 'tag',
    initialState: {
        addTag: initialTagState,
        editTag: initialTagState,
    },

    reducers: {
        addTagModal: (state, { payload }) => {
            state.addTag = payload;
        },
        editTagModal: (state, { payload }) => {
            state.editTag = payload;
        },
    },
});

export const { addTagModal, editTagModal } = tagSlice.actions;
export const selectAddTagModal = (state: RootState): boolean => state.tag.addTag;
export const selectEditTagModal = (state: RootState): boolean => state.tag.editTag;

export default tagSlice;
