import { RootState } from '../../app/store';
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';

const initialState = {
    selectedNodes: [],
};

const deviceTypesSlice = createSlice({
    name: 'deviceTypes',
    initialState: initialState,
    reducers: {
        resetDeviceTypePayload: () => {
            return initialState;
        },
        setDeviceTypesSelectedNodes: (state: RootState, action: PayloadAction<any>) => {
            const currentClone = current(state);
            if (Array.isArray(action.payload)) {
                state.selectedNodes = action.payload;
            } else {
                const selectedIndex = currentClone?.selectedNodes?.findIndex(
                    (item: any) => item?.id === action?.payload?.id
                );
                if (selectedIndex !== -1) {
                    state?.selectedNodes?.splice(selectedIndex, 1);
                } else {
                    state?.selectedNodes?.push(action?.payload);
                }
            }
        },
    },
});

export const { resetDeviceTypePayload, setDeviceTypesSelectedNodes } = deviceTypesSlice.actions;

export default deviceTypesSlice;
