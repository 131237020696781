import { Button, CardActions, CircularProgress, Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import React from 'react';

import { useSortedData } from 'hooks/useSortedData';
import { WidgetHeader } from 'pages/WidgetManagement/common/WidgetHeader';
import CustomIcon from 'components/CustomIcon';
import { createNumArray } from 'utils/helpers';
import { HelpCenter, InfoOutlined } from '@mui/icons-material';
import { GroupPath } from 'components';
const Component = ({
    widgetData,
    mode,
    commandHandler,
}: {
    widgetData: any;
    mode: string;
    commandHandler: any;
}): JSX.Element => {
    const { sortedData } = useSortedData(widgetData ?? [], ['secondary'], 'ASC');

    return (
        <Paper sx={{ height: '100%', overflow: 'scroll' }}>
            {/* {(!widgetData?.isPinned || mode !== 'view') && ( */}
            <WidgetHeader mode={mode} widgetData={widgetData} sortedData={sortedData} />
            {/* )} */}
            {mode !== 'edit' && <Divider />}
            <Stack
                sx={{
                    height: `calc(100% - ${mode === 'edit' || widgetData?.viewAllButton ? '97px' : '49px'})`,
                    overflow: 'auto',
                }}
            >
                <Stack direction={'row'} gap={2} flexWrap={'wrap'} py={4} px={2}>
                    {sortedData?.secondary?.length
                        ? sortedData?.secondary
                              ?.slice(0, widgetData?.maxCount ?? sortedData?.secondary?.length)
                              .map((item: any) => (
                                  <Button
                                      key={`${item.deviceId}-${item.channelId}`}
                                      variant={item?.paletteConfig?.type ?? 'outlined'}
                                      onClick={(): void => commandHandler?.(item)}
                                      disabled={mode !== 'view'}
                                      sx={{
                                          ...(item?.paletteConfig?.type === 'contained'
                                              ? { backgroundColor: item?.paletteConfig?.color }
                                              : {
                                                    border: `2px solid ${item?.paletteConfig?.color}`,
                                                    '&:hover': {
                                                        border: `2px solid ${item?.paletteConfig?.color}`,
                                                    },
                                                }),
                                          borderRadius: '50px',
                                          minHeight: '36px',
                                          justifyContent: 'flex-start',
                                          ...(widgetData?.multiDevice && { minWidth: '193px' }),
                                      }}
                                      startIcon={
                                          item?.isLoading ? (
                                              <CircularProgress color="inherit" size={20} />
                                          ) : (
                                              <CustomIcon
                                                  iconName={item?.icon?.web?.name ?? ''}
                                                  family={item?.icon?.web?.family ?? ''}
                                                  iconProps={{
                                                      sx: {
                                                          color:
                                                              item?.paletteConfig?.type === 'outlined'
                                                                  ? item?.paletteConfig?.color
                                                                  : '#fff',
                                                      },
                                                  }}
                                              />
                                          )
                                      }
                                  >
                                      <Stack>
                                          <Typography
                                              fontSize={'14px'}
                                              fontWeight={'600'}
                                              sx={{
                                                  color:
                                                      item?.paletteConfig?.type === 'outlined'
                                                          ? item?.paletteConfig?.color
                                                          : '#fff',
                                              }}
                                          >
                                              {item?.displayName}
                                          </Typography>
                                          {widgetData?.multiDevice && (
                                              <GroupPath
                                                  key={item?.id}
                                                  pathName={item?.groupPath ? [...item.groupPath, item?.groupName] : []}
                                                  deviceName={item?.deviceName}
                                                  color={
                                                      item?.paletteConfig?.type === 'outlined'
                                                          ? item?.paletteConfig?.color
                                                          : '#fff'
                                                  }
                                              />
                                          )}
                                      </Stack>
                                  </Button>
                              ))
                        : createNumArray(4)?.map((item: any) => (
                              <IconButton key={item?.id} disabled={mode !== 'view'}>
                                  <Stack
                                      direction={'row'}
                                      spacing={2}
                                      alignItems={'center'}
                                      borderRadius={'50px'}
                                      py={1}
                                      px={2}
                                      sx={{ backgroundColor: item?.backgroundColor, color: item?.color }}
                                      className="border-2"
                                  >
                                      <HelpCenter />
                                      <Stack>
                                          <Typography fontSize={'14px'} fontWeight={'600'}>
                                              Command Name
                                          </Typography>
                                      </Stack>
                                  </Stack>
                              </IconButton>
                          ))}
                </Stack>
            </Stack>
            {widgetData?.isPinned && mode === 'preview' && (
                <CardActions className="padding-0 border-top-1">
                    <Stack alignItems={'center'} p={2} direction={'row'} spacing={1} className="color-content">
                        <InfoOutlined />
                        <Typography variant="body2" fontSize={'14px'}>
                            This set of controls will appear pinned at the top of the dashboard.
                        </Typography>
                    </Stack>
                </CardActions>
            )}
        </Paper>
    );
};

export default Component;
