export const COMMAND_PALETTE = [
    {
        type: 'contained',
        color: '#1D2529',
    },
    {
        type: 'outlined',
        color: '#1D2529',
    },
    {
        type: 'contained',
        color: '#B9BFC2',
    },
    {
        type: 'outlined',
        color: '#B9BFC2',
    },
    {
        type: 'contained',
        color: '#BD2E2F',
    },
    {
        type: 'outlined',
        color: '#BD2E2F',
    },
    {
        type: 'contained',
        color: '#E1B7C2',
    },
    {
        type: 'outlined',
        color: '#E1B7C2',
    },
    {
        type: 'contained',
        color: '#983FEF',
    },
    {
        type: 'outlined',
        color: '#983FEF',
    },
    {
        type: 'contained',
        color: '#6A1BE4',
    },
    {
        type: 'outlined',
        color: '#6A1BE4',
    },
    {
        type: 'contained',
        color: '#004B9E',
    },
    {
        type: 'outlined',
        color: '#004B9E',
    },
    {
        type: 'contained',
        color: '#007BC1',
    },
    {
        type: 'outlined',
        color: '#007BC1',
    },
    {
        type: 'contained',
        color: '#4DACF6',
    },
    {
        type: 'outlined',
        color: '#4DACF6',
    },
    {
        type: 'contained',
        color: '#4DC9C3',
    },
    {
        type: 'outlined',
        color: '#4DC9C3',
    },
    {
        type: 'contained',
        color: '#178E0B',
    },
    {
        type: 'outlined',
        color: '#178E0B',
    },
    {
        type: 'contained',
        color: '#2CA618',
    },
    {
        type: 'outlined',
        color: '#2CA618',
    },
    {
        type: 'contained',
        color: '#ADBC22',
    },
    {
        type: 'outlined',
        color: '#ADBC22',
    },
    {
        type: 'contained',
        color: '#74CC63',
    },
    {
        type: 'outlined',
        color: '#74CC63',
    },
    {
        type: 'contained',
        color: '#EEC62A',
    },
    {
        type: 'outlined',
        color: '#EEC62A',
    },
    {
        type: 'contained',
        color: '#F7A064',
    },
    {
        type: 'outlined',
        color: '#F7A064',
    },
    {
        type: 'contained',
        color: '#F36F1D',
    },
    {
        type: 'outlined',
        color: '#F36F1D',
    },
    {
        type: 'contained',
        color: '#EC470B',
    },
    {
        type: 'outlined',
        color: '#EC470B',
    },
    {
        type: 'contained',
        color: '#774E08',
    },
    {
        type: 'outlined',
        color: '#774E08',
    },
    {
        type: 'contained',
        color: '#727E84',
    },
    {
        type: 'outlined',
        color: '#727E84',
    },
];
