import React from 'react';

import { Stack, Select, MenuItem, InputBase, InputLabel, IconButton, FormControl } from '@mui/material';

import { useConfirm } from '@fiji/common/src/hooks';
import { CustomModal, CustomTransComponent } from '../../../components';
import { ModalActions } from '../../../components/CustomModal';

import Search from '@mui/icons-material/Search';
import { TypesSkeleton } from './Skeletons';
import { DataSourceList } from './DataSourceList';
import { useHandleAddWidgets } from './hooks';
import { resetWidget, setCategory, setPayload } from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useGetAllDevicesQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { EmptyState } from '@brightlayer-ui/react-components';
import { Info } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const DataSourceModal = React.forwardRef(
    ({ handleConfirm, type }: { handleConfirm: any; type: string }, ref): JSX.Element => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();
        const { payload, dropdownOptions, category, deviceTypes } = useTypedSelector((state) => state.widget);

        const { fetchDeviceTypes, deviceTypesLoader: isLoading } = useHandleAddWidgets();

        const handleDataSourceConfirm = (): void => {
            if (type === 'edit') {
                handleConfirm({ id: payload?.sourceId, name: payload?.sourceName, categoryId: category?.id });
                onCancel();
                resetWidget();
                return;
            }
            handleConfirm();
            onCancel();
        };

        const { data: sourceData, isVisible, onClick, onCancel, onConfirm } = useConfirm(handleDataSourceConfirm);

        const {
            currentData: deviceList,
            isLoading: deviceListLoader,
            isFetching: deviceListFetching,
            isError,
        } = useGetAllDevicesQuery(
            {
                page: 0,
                size: 10,
            },
            { skip: !isVisible, refetchOnMountOrArgChange: true }
        );

        const handleDisable = (): boolean => {
            if (category && !deviceTypes?.length) {
                return false;
            } else if (payload?.sourceId) {
                return false;
            } else if (payload?.config?.multiDevice) {
                return false;
            }
            return true;
        };

        const dataSourceSelectionActions: ModalActions[] = [
            {
                key: '#selectioncancel',
                label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
                close: true,
                variant: 'outlined',
                disabled: !payload?.sourceId && type === 'edit',
                handleClick: (): void => {
                    onCancel();
                    dispatch(resetWidget());
                },
            },
            {
                key: '#selectionNext',
                label:
                    type === 'edit' ? (
                        <CustomTransComponent translationKey={'COMMON:SAVE_LABEL'} />
                    ) : (
                        <CustomTransComponent translationKey={'COMMON:NEXT'} />
                    ),
                variant: 'contained',
                disabled: handleDisable() || isError,
                handleClick: onConfirm,
            },
        ];
        const handleModalAction = (action: boolean, data?: any): void => {
            if (action) {
                onClick(data);
                return;
            }
            onCancel();
        };

        const handleCategoryChange = (e: any): void => {
            if (e.target.value?.profileId) {
                dispatch(
                    setPayload({ key: 'sourceId', value: type === 'edit' ? e.target.value?.profileId : undefined })
                );
                dispatch(setPayload({ key: 'sourceName', value: e.target.value.name }));
            }
            dispatch(setCategory(e.target.value));
        };

        React.useImperativeHandle(
            ref,
            () => ({
                handleModalAction: (action: boolean, data: any): void => handleModalAction(action, data),
            }),
            []
        );

        React.useEffect(() => {
            if (isVisible) void fetchDeviceTypes('');
        }, [isVisible]);

        React.useEffect(() => {
            if (category?.id) void fetchDeviceTypes(category?.id, 'list');
        }, [category]);

        React.useEffect(() => {
            if (dropdownOptions?.length && sourceData) {
                const selectedCategory = dropdownOptions?.find((cat: any) => cat.id === sourceData?.categoryId);
                dispatch(setCategory(selectedCategory));
                dispatch(setPayload({ key: 'sourceId', value: sourceData?.id }));
            }
        }, [dropdownOptions, sourceData]);

        return (
            <CustomModal
                key="#dataSouceSelectionModal"
                type="primary"
                actionsDivider
                actions={dataSourceSelectionActions}
                subHeader={
                    <CustomTransComponent
                        translationKey={'WIDGETS:ADD_WIDGET_SUBTITLE'}
                        replace={{ widgetType: payload?.widgetType?.label }}
                    />
                }
                header={<CustomTransComponent translationKey={'WIDGETS:ADD_WIDGET'} />}
                isOpen={isVisible}
                hidePadding
                onClose={onCancel}
            >
                <Stack>
                    <Stack p={3} spacing={2}>
                        <FormControl
                            fullWidth
                            variant="filled"
                            disabled={isLoading || !deviceList?.data?.records?.length}
                        >
                            <InputLabel id="demo-simple-select-label">{t('COMMON:CATEGORY')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                disabled={isLoading || payload?.config?.multiDevice}
                                value={category}
                                label="Category"
                                onChange={handleCategoryChange}
                            >
                                {dropdownOptions?.map((option: any) => (
                                    <MenuItem key={option?.id} value={option}>
                                        {option?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Stack className="border-1 border-radius-4" direction={'row'}>
                            <IconButton
                                type="button"
                                className="padding-10"
                                aria-label="search"
                                disabled={!deviceList || !deviceList?.data?.records?.length}
                            >
                                <Search />
                            </IconButton>
                            <InputBase
                                className="margin-left-10"
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                                disabled={!deviceList || !deviceList?.data?.records?.length}
                            />
                        </Stack>
                    </Stack>

                    {isLoading || deviceListLoader || deviceListFetching ? (
                        <TypesSkeleton items={5} primaryVariant="circular" text="RelaydC4deTPXewd3qd3q" />
                    ) : deviceList && deviceList?.data?.records?.length ? (
                        <DataSourceList />
                    ) : (
                        <EmptyState
                            title={` A device must first be provisioned before device types will be available.`}
                            icon={<Info color="primary" sx={{ fontSize: '60px' }} />}
                        />
                    )}
                </Stack>
            </CustomModal>
        );
    }
);
