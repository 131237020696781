import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

/**
 * The above type defines the props for a component that handles table pagination actions in a
 * TypeScript React application.
 * @property {number} page - The `page` property represents the current page number.
 * @property goToNextPage - A function that is called when the user wants to navigate to the next page
 * of the table.
 * @property goToPrevPage - A function that is called when the user wants to navigate to the previous
 * page of the table.
 * @property goToFirstPage - A function that will navigate to the first page of the table.
 * @property goToLastPage - This property is a function that is called when the user wants to navigate
 * to the last page of the table. It does not take any arguments and does not return anything.
 * @property {number} totalPages - The `totalPages` property represents the total number of pages in
 * the table. It is used to determine the maximum page number that can be navigated to.
 */
type TablePaginationActionsProps = {
    page: number;
    goToNextPage: () => void;
    goToPrevPage: () => void;
    goToFirstPage: () => void;
    goToLastPage: () => void;
    totalPages: number;
};

/* The code is defining a functional component called `TablePaginationActions` that handles table
pagination actions in a TypeScript React application. */
export const TablePaginationActions = (props: TablePaginationActionsProps): JSX.Element => {
    const theme = useTheme();
    const { page, totalPages, ...remainingProps } = props;

    return (
        <Box flexShrink={0} ml={2.5}>
            <IconButton onClick={remainingProps?.goToFirstPage} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={remainingProps?.goToPrevPage} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={remainingProps?.goToNextPage} disabled={page + 1 >= totalPages} aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={remainingProps?.goToLastPage} disabled={page + 1 >= totalPages} aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
};
