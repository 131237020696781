import React, { ReactNode, useRef } from 'react';

import {
    setRoute,
    setWidgetName,
    setWidgetSource,
    toggleDeviceLinkMode,
} from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';
import Link from '@mui/icons-material/Link';
import useTheme from '@mui/material/styles/useTheme';
import { DataSourceModal } from 'pages/DashboardAndWidgets';
import { useWidgetConfigHandlers } from '../common';
import { useTypedSelector, useAppDispatch } from '@fiji/common';
import { Box, Icon, Stack, Switch, Typography } from '@mui/material';
import { CustomTransComponent } from 'components';

export const CommonConfigurationHeader = ({ type, heading }: { type?: string; heading?: ReactNode }): JSX.Element => {
    const dispatch = useAppDispatch();
    const theme: any = useTheme();
    const dataSourceModalRef = useRef<any>(null);
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const source = useTypedSelector((state) => state['widgetConfiguraton']?.['source']);
    const selectedNode = useTypedSelector((state) => state['widgetConfiguraton']?.['device']);

    const {
        details: { widgetDetails },
    } = useWidgetConfigHandlers();

    const isMultiDevice = useTypedSelector((state) => state['widgetConfiguration']['data']['multiDevice']);

    React.useEffect(() => {
        if (widgetDetails) {
            dispatch(
                setRoute({
                    url: `/${currentRealmName}/device/${selectedNode?.id}`,
                    ...(widgetDetails?.widgetType?.id !== 'charts' && { state: widgetDetails?.widgetType?.id }),
                })
            );
            dispatch(setWidgetName(widgetDetails?.name));
        }
    }, [widgetDetails]);

    React.useEffect(() => {
        if (!widgetDetails?.sourceId && source && isMultiDevice) {
            dispatch(toggleDeviceLinkMode());
        }
    }, [source]);

    return (
        <>
            <Box px={3} py={'14px'} className="border-bottom-1 flex-center-between bg-header">
                <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
                    <Box className="align-items-center cursor-pointer">
                        <Icon>
                            <Link color="primary" />
                        </Icon>
                        <Typography variant="body2" color="primary" fontWeight={'600'} fontSize={'14px'} mx={1}>
                            {heading ?? <CustomTransComponent translationKey={'COMMON:LINK_DEVICES'} />}
                        </Typography>
                    </Box>
                    <Switch
                        sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                                color: theme?.palette?.primary?.main,
                                '& + .MuiSwitch-track': {
                                    backgroundColor: theme?.palette?.primary?.main,
                                },
                            },
                            '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
                                color: theme?.palette?.primary?.[50],
                                '& + .MuiSwitch-track': {
                                    backgroundColor: theme?.palette?.primary?.[200],
                                },
                            },
                        }}
                        checked={isMultiDevice ?? false}
                        onChange={(): void => {
                            if (!widgetDetails?.source && isMultiDevice && type === 'source') {
                                dataSourceModalRef?.current?.handleModalAction(true, widgetDetails?.source);
                            }
                            dispatch(toggleDeviceLinkMode());
                        }}
                    />
                </Stack>
            </Box>
            {type === 'source' && (
                <DataSourceModal
                    ref={dataSourceModalRef}
                    type="edit"
                    handleConfirm={(args: any): void => dispatch(setWidgetSource(args))}
                />
            )}
        </>
    );
};
