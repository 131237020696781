import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const intitialState = {
    realmLibraryData: [],
    realmOrOrgDashboardLibrary: [],
    triggerType: undefined,
    payloadNode: undefined,
};

const realmDashboardSlice = createSlice({
    name: 'realmDashboard',
    initialState: intitialState,
    reducers: {
        setRealmLibraryData: (state: RootState, action: PayloadAction<any>) => {
            state.realmLibraryData = action.payload;
        },
        setRealmOrOrgDashboardLibrary: (state: RootState, action: PayloadAction<any>) => {
            state.realmOrOrgDashboardLibrary = action.payload;
        },
        setTriggerType: (state: RootState, action: PayloadAction<any>) => {
            state.triggerType = action.payload;
        },
        setPayloadNode: (state: RootState, action: PayloadAction<any>) => {
            state.payloadNode = action.payload;
        },
        resetRealmDashboards: () => {
            return intitialState;
        },
    },
});

export const {
    setRealmLibraryData,
    setRealmOrOrgDashboardLibrary,
    resetRealmDashboards,
    setTriggerType,
    setPayloadNode,
} = realmDashboardSlice.actions;
export default realmDashboardSlice;
