import React from 'react';

import { ListSkeleton } from './Skeletons';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { Box, Grid, List, Stack, Typography } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { handlePrimaryHeading, handleSubHeading } from './utils/helpers';
import { useDefaultConfig, useWidgetConfigHandlers, DraggableListItem } from './';

export const GroupAndDevicesList = (props: any): JSX.Element => {
    const payload = useTypedSelector((state) => state[props?.details?.widgetType?.id]);

    const { isLoading: widgetLoader } = useWidgetConfigHandlers();

    const { onDragEnd } = useDefaultConfig(props?.details?.widgetType?.id);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Grid item py={3} px={2} className="bg-content-wrapper height-800 w-49">
                <Stack className="border-bottom-1">
                    <Typography variant="body1" fontWeight={'600'}>
                        {handlePrimaryHeading(props?.details?.widgetType?.id)}
                    </Typography>
                    <Typography mt={3} variant="body1" pb={3} fontWeight={'400'}>
                        {handleSubHeading(props?.details?.widgetType?.id, payload)}
                    </Typography>
                </Stack>
                <Stack mt={2} className="max-height-630 overflow-y-scroll">
                    {widgetLoader && <ListSkeleton total={5} primaryVariant="rectangular" />}
                    {!widgetLoader && (
                        <>
                            <Stack sx={{ marginTop: '10px' }}>
                                <Typography ml={2} variant="body2" className="text-secondary">
                                    {props?.details?.widgetType?.id === 'device_list' && 'SELECTED DEVICES'}
                                </Typography>

                                {props?.details?.widgetType?.id === 'group_list' ||
                                props?.details?.widgetType?.id === 'device_list' ? (
                                    <Box my={2} className="bg-white">
                                        <List disablePadding>
                                            {payload?.selectedNodes?.map(
                                                (item: any, index: number, prev: any) =>
                                                    prev[index - 1]?.['id'] !== item?.['id'] && (
                                                        <DraggableListItem
                                                            item={item}
                                                            widgetTypeId={props?.details?.widgetType?.id}
                                                            key={`${item?.[props?.keyToTraverse]}${item?.id}`}
                                                        />
                                                    )
                                            )}
                                        </List>
                                    </Box>
                                ) : (
                                    <Droppable droppableId="selectedNodes">
                                        {(provided): any => (
                                            <Box
                                                my={2}
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                className="bg-white"
                                            >
                                                <List disablePadding>
                                                    {payload?.selectedNodes?.map(
                                                        (item: any, index: number, prev: any) =>
                                                            prev[index - 1]?.['id'] !== item?.['id'] && (
                                                                <Draggable
                                                                    index={index}
                                                                    key={`${item?.[props?.keyToTraverse]}${item?.id}`}
                                                                    draggableId={`${
                                                                        item?.[props?.keyToTraverse ?? 'id']
                                                                    }`}
                                                                >
                                                                    {(providedItem): any => (
                                                                        <DraggableListItem
                                                                            item={item}
                                                                            providedItem={providedItem}
                                                                            widgetTypeId={
                                                                                props?.details?.widgetType?.id
                                                                            }
                                                                        />
                                                                    )}
                                                                </Draggable>
                                                            )
                                                    )}
                                                </List>
                                                {provided.placeholder}
                                            </Box>
                                        )}
                                    </Droppable>
                                )}
                            </Stack>
                        </>
                    )}
                </Stack>
            </Grid>
        </DragDropContext>
    );
};
