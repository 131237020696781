/**
 * The `usePayloadActions` function returns an array containing two functions: `cleanPayload` and
 * `removeDuplicateValues`, which can be used to manipulate payload objects.
 * @returns An array containing two functions, `cleanPayload` and `removeDuplicateValues`, is being
 * returned.
 */

export const usePayloadActions = (): any => {
    const cleanPayload = (payload: any): any => {
        for (const key in payload) {
            if (
                payload[key] === '' ||
                payload[key] === null ||
                (Array.isArray(payload[key]) && !payload[key]?.length) ||
                key === 'deviceId' ||
                key === 'guid'
            ) {
                delete payload[key];
            }
        }

        return payload;
    };

    const removeDuplicateValues = (currentPayload: any, previousPayload: any): any => {
        const currentPayloadClone = JSON.parse(JSON.stringify(currentPayload));
        for (const key in currentPayloadClone) {
            if (JSON.stringify(currentPayloadClone[key]) === JSON.stringify(previousPayload[key])) {
                delete currentPayloadClone[key];
            }
        }

        return currentPayloadClone;
    };

    return [cleanPayload, removeDuplicateValues];
};
