export const BRANDING_COLOR_PALLETE: {
    [key: string]: {
        50: string;
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
        800: string;
        900: string;
    };
} = {
    eatonBlue: {
        50: '#E0ECF5',
        100: '#B3CFE6',
        200: '#80AFD5',
        300: '#4D8EC4',
        400: '#2676B8',
        500: '#005EAB',
        600: '#0056A4',
        700: '#004C9A',
        800: '#004291',
        900: '#003180',
    },
    teal: {
        50: '#E0F6F5',
        100: '#B3E8E5',
        200: '#80D9D4',
        300: '#4DC9C3',
        400: '#26BEB6',
        500: '#00B2A9',
        600: '#00ABA2',
        700: '#00A298',
        800: '#00998F',
        900: '#008A7E',
    },
    citron: {
        50: '#F7F9E6',
        100: '#EBEFC0',
        200: '#DEE496',
        300: '#D0D96C',
        400: '#C6D14D',
        500: '#BCC92D',
        600: '#B6C328',
        700: '#ADBC22',
        800: '#A5B51C',
        900: '#97A911',
    },
    pine: {
        50: '#E6EDEA',
        100: '#C0D1CB',
        200: '#97B3A8',
        300: '#6D9485',
        400: '#4D7E6A',
        500: '#2E6750',
        600: '#295F49',
        700: '#235440',
        800: '#1D4A37',
        900: '#123927',
    },
    emerald: {
        50: '#E8F1E7',
        100: '#C6DCC2',
        200: '#A0C59A',
        300: '#79AE72',
        400: '#5D9C53',
        500: '#408B35',
        600: '#3A8330',
        700: '#327828',
        800: '#2A6E22',
        900: '#1C5B16',
    },
    wine: {
        50: '#F3E2E7',
        100: '#E1B7C2',
        200: '#CE8799',
        300: '#BA5670',
        400: '#AB3252',
        500: '#9C0E33',
        600: '#940C2E',
        700: '#8A0A27',
        800: '#800820',
        900: '#6E0414',
    },
    crimson: {
        50: '#FBE6E5',
        100: '#F4C2B3',
        200: '#ED9993',
        300: '#E57068',
        400: '#E05147',
        500: '#DA3227',
        600: '#D62D23',
        700: '#D0261D',
        800: '#CB1F17',
        900: '#C2130E',
    },
    rust: {
        50: '#F8EDE5',
        100: '#F0d5bd',
        200: '#e8b892',
        300: '#e09c66',
        400: '#d78645',
        500: '#d17126',
        600: '#cc6a21',
        700: '#c55f1e',
        800: '#c05516',
        900: '#b5430f',
    },
    navy: {
        50: '#E0E9EE',
        100: '#B3C8D5',
        200: '#80A3B9',
        300: '#4D7E9C',
        400: '#266287',
        500: '#004672',
        600: '#003F6A',
        700: '#00375F',
        800: '#002F55',
        900: '#002042',
    },
    sage: {
        50: '#F7FAF3',
        100: '#ECF2E2',
        200: '#E0EACF',
        300: '#D3E1BC',
        400: '#C9DAAD',
        500: '#C0D49F',
        600: '#BACF97',
        700: '#B2C98D',
        800: '#AAC383',
        900: '#9CB972',
    },
    toad: {
        50: '#F4F0E3',
        100: '#E3D8BA',
        200: '#D1BF8C',
        300: '#BFA55E',
        400: '#B1913C',
        500: '#A37E19',
        600: '#9B7616',
        700: '#916B12',
        800: '#88610E',
        900: '#774E08',
    },
    butter: {
        50: '#FFFCF2',
        100: '#FFF8DF',
        200: '#FFF3C9',
        300: '#FEEEB3',
        400: '#FEEBA3',
        500: '#FEE793',
        600: '#FEE48B',
        700: '#FEE080',
        800: '#FEDD76',
        900: '#FDD764',
    },
    goldenrod: {
        50: '#FEF7E7',
        100: '#FCECC2',
        200: '#FADF99',
        300: '#F8D270',
        400: '#F7C952',
        500: '#F5BF33',
        600: '#F4B92E',
        700: '#F2B127',
        800: '#F0A920',
        900: '#EE9B14',
    },
};
