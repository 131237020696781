import { useContext } from 'react';
import { useDrop, DragElementWrapper } from 'react-dnd';
import { ItemTypes } from '../ItemTypes';
import { PlaceholderContext } from '../providers';
import { NodeModel } from '../types';
import { getDropTarget } from '../utils';
import { useTreeContext } from '../hooks';
import { useConfirmModalContext } from '../providers/ConfirmModalProvider';

export const useDropRoot = <T>(
    ref: React.RefObject<HTMLElement>,
    parentNode: any,
    disabled: boolean
): [boolean, NodeModel<T>, DragElementWrapper<HTMLElement>] => {
    const treeContext = useTreeContext<T>();
    const confirmModalContext = useConfirmModalContext();
    const placeholderContext = useContext(PlaceholderContext);
    const [{ isOver, dragSource }, drop]: any = useDrop({
        accept: [ItemTypes.TREE_ITEM, ...treeContext.extraAcceptTypes],
        drop: (dragItem: any, monitor: any) => {
            const { parentNode: rootNode } = treeContext;
            const { dropTargetId, index, dropTargetName } = placeholderContext;

            if (!disabled && monitor.isOver({ shallow: true }) && dropTargetId !== undefined && index !== undefined) {
                // If the drag source is outside the react-dnd,
                // a different object is passed than the NodeModel.
                confirmModalContext?.openConfirmModal({
                    dragSource: dragItem,
                    dropTargetId: rootNode?.id,
                    dropTargetName,
                    placeholderIndex: index,
                });
            }

            placeholderContext.hidePlaceholder();
        },
        canDrop: (dragItem: any, monitor: any) => {
            if (monitor.isOver({ shallow: true })) {
                if (dragItem === undefined) {
                    return false;
                }

                return true;
            }

            return false;
        },
        hover: (dragItem: any, monitor: any) => {
            if (monitor.isOver({ shallow: true })) {
                const { dropTargetId, index, showPlaceholder, hidePlaceholder } = placeholderContext;

                const dropTarget = getDropTarget<T>(null, parentNode, ref.current, monitor, treeContext);

                if (dropTarget === null) {
                    hidePlaceholder();
                    return;
                }

                if (dropTarget.id !== dropTargetId || dropTarget.index !== index) {
                    showPlaceholder(dropTarget.id, dropTarget.index, dropTarget?.name);
                }
            }
        },
        collect: (monitor: any) => {
            const collectDragSource: NodeModel<T> = monitor.getItem();

            return {
                isOver: monitor.isOver({ shallow: true }) && monitor.canDrop(),
                collectDragSource,
            };
        },
    });

    return [isOver, dragSource, drop];
};
