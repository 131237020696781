import { useConfirm } from '@fiji/common/src/hooks';
import React from 'react';
import { ConfirmModal, CustomModal, CustomTransComponent } from '../../../components';
import { ModalActions } from '../../../components/CustomModal';
import {
    useLazyGetAllDashboardsQuery,
    useUpdateDashboardMutation,
} from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import { Icon, List, Radio, Stack, Typography } from '@mui/material';
import { InfoListItem } from '@brightlayer-ui/react-components';
import Link from '@mui/icons-material/Link';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { useTranslation } from 'react-i18next';

export const SelectDashboardModal = React.forwardRef((_props, ref): JSX.Element => {
    const { t } = useTranslation();
    const [associateDashboard, { isLoading: associatingDashboard }] = useUpdateDashboardMutation();

    const submitHandler = async (item: any): Promise<void> => {
        const payload: any = {
            status: 'ACTIVE',
        };
        if (item.deviceId) {
            payload['deviceId'] = item.deviceId;
        } else {
            payload['groupId'] = item.groupId;
        }
        const { error }: any = await associateDashboard({
            body: payload,
            params: { dashboardId: selectedDashboard?.id },
        });
        if (!error) {
            setMessageContent({ isOpen: true, message: t('DASHBOARDS:DASHBOARD_UPDATE_SUCCESS') });
            onCancel();
        }
    };

    const { isVisible, onClick, onCancel, onConfirm } = useConfirm(submitHandler);
    const {
        isVisible: noDashboardModal,
        onClick: openNoDashboardModal,
        onCancel: closeNoDashboardModal,
    } = useConfirm();
    const [getDashboards, { currentData: dashboards, isLoading, isFetching }]: any = useLazyGetAllDashboardsQuery();

    const [selectedDashboard, setSelectedDashboard] = React.useState<any>('');

    const selectDashboardActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: onCancel,
        },
        {
            key: 'apply',
            label: <CustomTransComponent translationKey={'COMMON:APPLY'} />,
            disabled: associatingDashboard,
            variant: 'contained',
            handleClick: onConfirm,
        },
    ];

    const handleModalOpen = async (action: any, data?: any): Promise<void> => {
        if (action) {
            const filtersPayload: any = {
                page: 0,
                size: 100,
                filters: {
                    status: ['INACTIVE'],
                },
            };
            if (data?.deviceId) {
                filtersPayload['filters']['deviceId'] = data.deviceId;
            } else if (data?.groupId) {
                filtersPayload['filters']['groupId'] = data.groupId;
            }
            const { data: dashboardList }: any = await getDashboards({ body: filtersPayload });
            if (dashboardList?.data?.records?.length) {
                onClick(data);
                return;
            }
            openNoDashboardModal();
            return;
        }
        onCancel();
    };

    React.useImperativeHandle(
        ref,
        () => ({
            handleModalAction: (action: any, data: any) => handleModalOpen(action, data),
        }),
        []
    );

    return (
        <>
            <CustomModal
                key="#selectModal"
                hidePadding
                isOpen={isVisible}
                actions={selectDashboardActions}
                isLoading={isLoading || isFetching || !dashboards || associatingDashboard}
                type="primary"
                header={<CustomTransComponent translationKey={'DASHBOARDS:SELECT_DASHBOARD'} />}
                subHeader={<CustomTransComponent translationKey={'DASHBOARDS:SELECT_DASHBOARD'} />}
            >
                {dashboards?.data?.records?.length && (
                    <List className="height-200 overflow-y-scroll">
                        {dashboards?.data?.records?.map((item: any) => (
                            <InfoListItem
                                key={item?.id}
                                divider="full"
                                hidePadding
                                title={item?.name}
                                leftComponent={
                                    <Stack mr={3} minWidth={'170px'}>
                                        <Typography variant="body2" fontSize={'14px'} fontWeight={'600'}>
                                            {item?.sourceName}
                                        </Typography>
                                        <Typography variant="body2" fontSize={'14px'} fontWeight={'400'}>
                                            {item?.isSystemDashboard && 'System '}
                                        </Typography>
                                    </Stack>
                                }
                                // subtitle={getAssignment(data, assignment)}
                                rightComponent={
                                    <>
                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography variant="body2" fontWeight={'400'} fontSize={'14px'} mr={1}>
                                                {item?.associatedCount}
                                            </Typography>
                                            <Icon>
                                                <Link></Link>
                                            </Icon>
                                        </Stack>
                                        <Stack ml={2}>
                                            <Radio
                                                checked={item?.id === selectedDashboard?.id}
                                                onChange={(): void => setSelectedDashboard(item)}
                                                value={item?.id}
                                                id={item?.name}
                                                name="radio-buttons"
                                            />
                                        </Stack>
                                    </>
                                }
                                classes={{ title: 'minWidth' }}
                            />
                        ))}
                    </List>
                )}
            </CustomModal>
            <ConfirmModal
                key="#confirm"
                type="popup"
                isVisible={noDashboardModal}
                actionButtonText={<CustomTransComponent translationKey={'COMMON:CLOSE'} />}
                confirmClick={closeNoDashboardModal}
                onCancel={closeNoDashboardModal}
                unTrimHeader={true}
                header={<CustomTransComponent translationKey={'DASHBOARDS:SELECT_DASHBOARD'} />}
                description={<CustomTransComponent translationKey={'DASHBOARDS:NO_ALTERNATE_DASHBOARDS'} />}
                buttonColor={'success'}
                leftButtonVariant="text"
                noDividers={true}
                rightButtonVariant="outlined"
            />
        </>
    );
});
