import { useRef } from 'react';
import { NodeModel, DragOverProps } from '../types';

export const useDragOver = (
    node: any,
    isOpen: boolean,
    dragOverHandler: (id: NodeModel['id']) => void,
    isDragging: boolean
): DragOverProps => {
    const stack = useRef<number>(0);
    const timer = useRef<number>(0);

    const onDragEnter = (): void => {
        stack.current += 1;

        if (stack.current === 1 && !isOpen && !isDragging) {
            timer.current = window.setTimeout(() => dragOverHandler(node), 5000);
        }
    };

    const onDragLeave = (): void => {
        stack.current -= 1;

        if (stack.current === 0) {
            window.clearTimeout(timer.current);
        }
    };

    const onDrop = (): void => {
        if (timer.current > 0) {
            window.clearTimeout(timer.current);
        }

        stack.current = 0;
        timer.current = 0;
    };

    return {
        onDragEnter,
        onDragLeave,
        onDrop,
    };
};
