import React, { ReactElement, createContext } from 'react';
import { useDragDropManager } from 'react-dnd';
import { DropOptions, TreeState } from '../types';

export const TreeContext = createContext({});

export const TreeProvider = <T,>(props: any): ReactElement => {
    const monitor = useDragDropManager().getMonitor();
    const canDropCallback = props.canDrop;
    const canDragCallback = props.canDrag;

    const value: TreeState<T> = {
        extraAcceptTypes: [],
        dropTargetOffset: 0,
        ...props,
        onDrop: ({ dragSource, dropTargetId, placeholderIndex }) => {
            if (dragSource) {
                const tree = props.tree;

                const options: DropOptions<T> = {
                    dragSourceId: dragSource.id,
                    dropTargetId,
                    dragSource: dragSource,
                    monitor,
                };

                const dragNodeIndex = tree.findIndex((item: any) => item.id === dragSource.id);

                const removeNestedNode = (): void => {
                    Object.entries(props.hierarchyMappingData).forEach(([parentId, children]: any) => {
                        const dragNodeIndexOfChild = children?.findIndex?.((item: any) => item.id === dragSource.id);
                        if (dragNodeIndexOfChild !== -1) {
                            props?.setHierarchyMappingData?.((prevState: any) => {
                                const prevStateClone = JSON.parse(JSON.stringify(prevState));
                                prevStateClone[parentId]?.splice(
                                    parentId !== dropTargetId
                                        ? dragNodeIndexOfChild
                                        : dragNodeIndexOfChild > placeholderIndex
                                        ? dragNodeIndexOfChild + 1
                                        : dragNodeIndexOfChild,
                                    1
                                );
                                return prevStateClone;
                            });
                        }
                    });
                };
                if (dragNodeIndex !== -1 && dropTargetId === props?.parentNode?.id) {
                    //drag and drop from same root node, chnaging order of root tree
                    delete dragSource.ref;
                    delete dragSource.depth;
                    tree.splice(placeholderIndex, 0, dragSource);
                    tree.splice(dragNodeIndex > placeholderIndex ? dragNodeIndex + 1 : dragNodeIndex, 1); // remove dragged id
                } else if (
                    dragNodeIndex !== placeholderIndex &&
                    dragNodeIndex === -1 &&
                    dropTargetId === props?.parentNode?.id
                ) {
                    //taking out a node from mapping data to root node
                    tree.splice(placeholderIndex, 0, dragSource);
                    removeNestedNode(); // remove dragged id
                } else {
                    // mapping data exists
                    props.setHierarchyMappingData?.((prevState: any) => {
                        delete dragSource.ref;
                        delete dragSource.depth;
                        const prevStateClone = JSON.parse(JSON.stringify(prevState));
                        if (prevStateClone[dropTargetId]) {
                            prevStateClone[dropTargetId].splice(placeholderIndex, 0, dragSource);
                        } else {
                            prevStateClone[dropTargetId] = [dragSource];
                        }
                        return prevStateClone;
                    });
                    if (dragNodeIndex !== -1) {
                        // drag node is from the root tree
                        tree.splice(dragNodeIndex, 1); // remove dragged id
                    } else if (dropTargetId) {
                        removeNestedNode(); // remove dragged id from mapping data
                    }
                }
                props.onDrop(tree, options);
            }
        },
        canDrop: canDropCallback
            ? (dragSourceId, dropTargetId): boolean | void =>
                  canDropCallback(props.tree, {
                      dragSourceId: dragSourceId ?? undefined,
                      dropTargetId,
                      dragSource: monitor.getItem(),
                      monitor,
                  })
            : undefined,
        canDrag: canDragCallback ? (id): boolean => canDragCallback(id) : undefined,
    };

    return <TreeContext.Provider value={value}>{props.children}</TreeContext.Provider>;
};
