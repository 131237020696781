import React from 'react';

import { HeroBanner } from '@brightlayer-ui/react-components';
import { ListSkeleton } from './ListSkeleton';
import { createNumArray } from 'utils/helpers';
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import { Card, CardContent, Skeleton, Stack, Typography } from '@mui/material';

export const DetailsCardSkeleton = ({
    secondarylength,
    primarylength,
}: {
    primarylength?: any;
    secondarylength: number;
}): JSX.Element => (
    <>
        <Card className="margin-left-0">
            <CardContent className="padding-0 custom-height">
                {primarylength && (
                    <HeroBanner
                        sx={{ justifyContent: 'space-evenly', paddingY: '10px' }}
                        className="border-bottom-1 w-100"
                    >
                        {createNumArray(primarylength)?.map((item: any) => (
                            <Stack key={`detail${item}`} justifyContent={'center'} alignItems={'center'}>
                                <Skeleton animation="wave" variant="circular" height={28} width={28}>
                                    <CircleOutlined />
                                </Skeleton>
                                <Skeleton animation="wave">
                                    <Typography variant="body1" fontWeight={'600'}>
                                        Serial Numbe
                                    </Typography>
                                </Skeleton>
                                <Skeleton animation="wave">
                                    <Typography variant="body2" fontWeight={'400'} fontSize={'14px'}>
                                        34214r4444
                                    </Typography>
                                </Skeleton>
                            </Stack>
                        ))}
                    </HeroBanner>
                )}
                <ListSkeleton total={secondarylength} customSize={25} />
            </CardContent>
        </Card>
    </>
);
