import React from 'react';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useDefaultConfig, useWidgetConfigHandlers } from '../hooks';
import { HierachyChannels } from '../../WidgetConfiguration';
import { useGetAllChannelsQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import Mode from '@mui/icons-material/Mode';
import Search from '@mui/icons-material/Search';
import {
    Box,
    Checkbox,
    Grid,
    IconButton,
    InputBase,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Radio,
    Stack,
    Typography,
} from '@mui/material';

import { ListSkeleton } from '../Skeletons';
import {
    setChannelSearchPayload,
    setWidgetLoader,
    setWidgetSource,
} from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';
import { DataSourceModal } from 'pages/DashboardAndWidgets';
import { useDebounce } from 'hooks';
import { retrieveChannelTypefromWidgetType } from '@fiji/common/src/utils/helpers';
import { COMMAND_PALETTE } from './commandPalette';

export const ChannelList = (props: any): JSX.Element => {
    const dispatch = useAppDispatch();
    const dataSourceModalRef = React.useRef<any>(null);
    const widgetDetails = useTypedSelector((state) => state.widgetConfiguration);

    const { isLoading: widgetLoader } = useWidgetConfigHandlers();
    const [, debouncedValue] = useDebounce(undefined, 2000, widgetDetails?.searchPayload);

    const {
        data: channels,
        isLoading,
        isFetching,
        isError,
    }: any = useGetAllChannelsQuery(
        {
            sourceId: widgetDetails?.source?.id ?? '',
            params: {
                channelType: retrieveChannelTypefromWidgetType(props?.details?.widgetType?.id),
                ...(debouncedValue?.length && {
                    searchKey: 'Name',
                    searchValue: debouncedValue,
                }),
            },
            number:
                props?.details?.widgetType?.id === 'gauge' || props?.details?.widgetType?.id === 'charts'
                    ? true
                    : false,
        },
        { skip: !widgetDetails?.source?.id, refetchOnMountOrArgChange: true }
    );

    React.useEffect(() => {
        if (isError) {
            dispatch(setWidgetLoader(false));
        }
    }, [isError]);

    const {
        handleChecked,
        handleChannelSelection,
        handleGaugeChannelSelection,
        isRadioSelected,
        handleChannelTypeSelection,
    } = useDefaultConfig(
        props?.details?.widgetType?.id === 'settings' || props?.details?.widgetType?.id === 'properties'
            ? 'details'
            : props?.details?.widgetType?.id,
        channels
    );

    return (
        <>
            <Grid item px={2} py={3} className="bg-content-wrapper height-800 w-49">
                {widgetDetails?.source?.id && (
                    <Stack direction={'row'} className="flex-center-between">
                        <Typography variant="body1" fontWeight={'600'}>
                            Data Source
                        </Typography>
                        <Stack direction={'row'} alignItems={'center'}>
                            <Typography variant="body2" fontWeight={'400'}>
                                {widgetDetails?.source?.name ?? '--'}
                            </Typography>
                            <IconButton
                                onClick={(): void =>
                                    dataSourceModalRef?.current?.handleModalAction(true, widgetDetails?.source)
                                }
                            >
                                <Mode />
                            </IconButton>
                        </Stack>
                    </Stack>
                )}

                <Stack my={3}>
                    <Typography variant="body1" fontWeight={'600'}>
                        Select Channels
                    </Typography>
                    <Stack mt={2} className="border-1 border-radius-4" direction={'row'}>
                        <IconButton type="button" aria-label="search" className="padding-10">
                            <Search />
                        </IconButton>
                        <InputBase
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                            className="w-100"
                            value={widgetDetails?.searchPayload ?? ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                dispatch(setChannelSearchPayload(e.target.value));
                            }}
                        />
                    </Stack>
                </Stack>

                <Box className="max-height-570  bg-white overflow-y-scroll">
                    {widgetDetails?.data?.multiDevice ? (
                        <HierachyChannels details={props?.details} />
                    ) : (
                        <List disablePadding>
                            {isLoading || isFetching || widgetLoader ? (
                                <ListSkeleton total={9} primaryVariant="rectangular" secondaryVariant="rectangular" />
                            ) : (
                                channels?.data?.map((item: any) => (
                                    <ListItemButton key={item?.channelId} className="border-bottom-1 list-item-hover">
                                        <ListItemText
                                            className={handleChecked(item) ? 'disabled' : ''}
                                            primary={
                                                <Typography variant="body2" fontWeight={'600'}>
                                                    {item?.displayName}
                                                </Typography>
                                            }
                                        />
                                        <ListItemIcon>
                                            {props?.selectionType === 'radio' ? (
                                                <Radio
                                                    edge="end"
                                                    onChange={(): void => {
                                                        handleGaugeChannelSelection({
                                                            ...item,
                                                            deviceId: widgetDetails?.device?.id,
                                                        });
                                                    }}
                                                    checked={isRadioSelected(item.channelId)}
                                                />
                                            ) : (
                                                <Checkbox
                                                    edge="end"
                                                    onChange={(): void => {
                                                        handleChannelSelection({
                                                            key:
                                                                props?.details?.widgetType?.id === 'details'
                                                                    ? handleChannelTypeSelection(item)
                                                                    : 'secondary',
                                                            channelPayload: {
                                                                ...item,
                                                                deviceId: widgetDetails?.device?.id,
                                                                deviceName: widgetDetails?.device?.name,
                                                                ...(props?.details?.widgetType?.id ===
                                                                    'command_bar' && {
                                                                    paletteConfig: COMMAND_PALETTE[0],
                                                                }),
                                                            },
                                                            multiDevice: widgetDetails?.data?.multiDevice,
                                                        });
                                                    }}
                                                    checked={handleChecked(item)}
                                                />
                                            )}
                                        </ListItemIcon>
                                    </ListItemButton>
                                ))
                            )}
                        </List>
                    )}
                </Box>
            </Grid>
            <DataSourceModal
                ref={dataSourceModalRef}
                type="edit"
                handleConfirm={(args: any): void => dispatch(setWidgetSource(args))}
            />
        </>
    );
};
