import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Avatar, Button, Paper, Stack } from '@mui/material';
import { WidgetFooter, WidgetHeader } from 'pages/WidgetManagement/common';
import { HelpCenter } from '@mui/icons-material';
import { CustomEmptyState } from 'components/CustomEmptyState';

const Component = ({
    widgetData,
    mode,
    learnMoreCallback,
}: {
    widgetData: any;
    mode: string;
    learnMoreCallback: any;
}): JSX.Element => (
    <Paper sx={{ height: '100%' }}>
        <WidgetHeader mode={mode} widgetData={widgetData} />
        {widgetData?.data ? (
            <Stack
                sx={{
                    height: `calc(100% - ${mode === 'edit' || widgetData?.viewAllButton ? '97px' : '49px'})`,
                    overflow: 'auto',
                }}
            >
                <Stack
                    sx={{
                        background: `linear-gradient(270deg, ${widgetData?.data?.scoreColor}30 0%, rgba(231, 246, 228, 0) 100%)`,
                    }}
                    direction={'row'}
                    py={4}
                    px={2}
                    spacing={3}
                    className="border-bottom-1"
                >
                    <Stack justifyContent={'center'} alignItems={'center'}>
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress
                                variant="determinate"
                                sx={{
                                    color: `${widgetData?.data?.scoreColor}70`,
                                }}
                                size={124}
                                thickness={8}
                                value={100}
                            />
                            <CircularProgress
                                variant="determinate"
                                sx={{
                                    position: 'absolute',
                                    left: 0,
                                    color: `${widgetData?.data?.scoreColor}`,
                                }}
                                size={124}
                                thickness={8}
                                value={widgetData?.data?.currentScore}
                            />
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    variant="h2"
                                    color={widgetData?.data?.scoreColor}
                                    fontSize={'40px'}
                                    fontWeight={'700'}
                                >
                                    {widgetData?.data?.grade}
                                </Typography>
                            </Box>
                        </Box>
                    </Stack>
                    <Stack spacing={1}>
                        <Stack>
                            <Typography variant="h1" fontSize={'34px'}>
                                <span className="color-content fw-600 f-34">{`${Math.round(
                                    widgetData?.data?.currentScore
                                )}`}</span>{' '}
                                of {widgetData?.data?.maxScore}
                            </Typography>
                            <Typography variant="h6" fontSize={'20px'} fontWeight={'600'} mb={1}>
                                {widgetData?.data?.scoreName}
                            </Typography>
                        </Stack>
                        {widgetData?.isLearnMoreVisible ? (
                            <Button
                                disabled={mode !== 'view'}
                                variant="outlined"
                                onClick={(): void => {
                                    learnMoreCallback();
                                }}
                            >
                                Learn More
                            </Button>
                        ) : (
                            <></>
                        )}
                    </Stack>
                </Stack>
                {widgetData?.isDescriptionVisible ? (
                    <Stack p={2}>
                        <Typography variant="body2">
                            Our <b>PulseScore™</b> factors in the age and health of devices and components, total
                            runtime, number of events and alarms, service incidents, environmental data, and more to
                            create a simple letter grade.
                        </Typography>
                    </Stack>
                ) : (
                    <></>
                )}
            </Stack>
        ) : (
            <CustomEmptyState
                className="padding-y-96"
                icon={
                    <Avatar sx={{ width: '56px', height: ' 56px' }}>
                        <HelpCenter className="height-76 width-76 color-icon" />
                    </Avatar>
                }
                title="PulseScore Not Available"
                description="This Device Type does not support Scores"
                actions={
                    widgetData?.isVisible && (
                        <Button
                            disabled={mode !== 'view'}
                            variant="contained"
                            onClick={(): void => {
                                learnMoreCallback();
                            }}
                        >
                            Learn More
                        </Button>
                    )
                }
            />
        )}
        <WidgetFooter noAction widgetData={widgetData} ids={[]} mode={mode} disabled={true} />
    </Paper>
);

export default Component;
