import { RootState } from '../../app/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const localizationSlice = createSlice({
    name: 'localization',
    initialState: {
        translationLoader: false,
    } as any,
    reducers: {
        setTranslationLoader: (state: RootState, action: PayloadAction<any>) => {
            state.translationLoader = action.payload;
        },
    },
});

export const { setTranslationLoader } = localizationSlice.actions;
export const selectTranslationLoader = (state: RootState): boolean => state.localization.translationLoader;

export default localizationSlice;
