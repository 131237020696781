import { MultiBackendOptions, PointerTransition, TouchTransition } from 'dnd-multi-backend';
import { HTML5BackendOptions, HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackendOptions, TouchBackend } from 'react-dnd-touch-backend';

export const getBackendOptions = (
    options: {
        html5?: Partial<HTML5BackendOptions>;
        touch?: Partial<TouchBackendOptions>;
    } = {}
): MultiBackendOptions => ({
    backends: [
        {
            id: 'html5',
            backend: HTML5Backend,
            options: options.html5,
            transition: PointerTransition,
        },
        {
            id: 'touch',
            backend: TouchBackend,
            options: options.touch || { enableMouseEvents: true },
            preview: true,
            transition: TouchTransition,
        },
    ],
});
