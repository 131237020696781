import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useNavigate } from 'react-router-dom';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { GroupPath } from 'components';

export const WidgetMenu = (props: any): JSX.Element => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                disabled={props?.disabled}
            >
                <ArrowDropDown />
            </IconButton>
            <Menu
                className="user-menu fw-400 f-16"
                id={props?.widgetData?.widgetName}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {props?.ids?.map((item: any) => (
                    <MenuItem
                        key={item?.deviceId}
                        onClick={(): void => {
                            if (props?.widgetData?.route)
                                navigate(
                                    `${props?.widgetData?.route?.url}/${item?.deviceId}${
                                        props?.widgetData?.route?.state ? `#${props?.widgetData?.route?.state}` : ''
                                    }`,
                                    {
                                        state: { selectedTab: props?.widgetData?.route?.state },
                                    }
                                );
                            handleClose();
                        }}
                    >
                        <GroupPath pathName={item?.devicePath ?? []} deviceName={item?.deviceName} width="250px" />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
