import { RootState } from '../../app/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const realmOrgsSlice = createSlice({
    name: 'realmOrg',
    initialState: {
        orgFilter: {
            page: 0,
            size: 10,
        },
        realmFilter: {
            page: 0,
            size: 10,
        },
    } as any,
    reducers: {
        setRealmFilter: (state: RootState, action: PayloadAction<any>) => {
            if (action.payload.pullRefresh) {
                state.realmFilter = { ...action.payload, pullRefresh: action.payload.pullRefresh + 1 };
            } else {
                state.realmFilter = { ...action.payload };
            }
        },
        setOrgFilter: (state: RootState, action: PayloadAction<any>) => {
            if (action.payload.pullRefresh) {
                state.orgFilter = { ...action.payload, pullRefresh: action.payload.pullRefresh + 1 };
            } else {
                state.orgFilter = { ...action.payload };
            }
        },
    },
});

export const { setRealmFilter, setOrgFilter } = realmOrgsSlice.actions;
export const selectRealmFilter = (state: RootState): { size: number; page: number; pullRefresh?: boolean } =>
    state.realmOrg.realmFilter;
export const selectOrgFilter = (state: RootState): { size: number; page: number; pullRefresh?: boolean } =>
    state.realmOrg.orgFilter;

export default realmOrgsSlice;
