import React from 'react';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import { Dialog, DialogTitle, Typography, DialogActions, DialogContent, Button } from '@mui/material';
import CopyIcon from '@mui/icons-material/CopyAllOutlined';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { copyCodeHandler } from 'utils/helpers';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';

import { CustomTransComponent } from 'components';
/**
 * The DiscardModalProps type is used in a TypeScript React component and includes properties for
 * visibility and a modal handler function.
 * @property {boolean} isVisible - The `isVisible` property is a boolean value that determines whether
 * the discard modal is visible or not. If `isVisible` is `true`, the modal is visible. If `isVisible`
 * is `false`, the modal is hidden.
 * @property modalHandler - The `modalHandler` property is a function that takes a boolean parameter
 * `action` and does something with it. It is used to handle the action performed on the modal, such as
 * closing it or confirming a discard action.
 */
type SuccessModalProps = {
    isVisible: boolean;
    modalHandler: (action: boolean) => void;
    okHandler: () => void;
    connectionString: string;
};

/**
 * The `DiscardModal` function is a TypeScript React component that renders a dialog box with a title,
 * message, and two buttons for canceling or discarding changes.
 * @param {SuccessModalProps}  - - `isVisible`: a boolean value indicating whether the modal is visible
 * or not.
 * @returns The code is returning a JSX element, specifically a `Dialog` component with various child
 * components such as `DialogTitle`, `DialogContent`, and `DialogActions`.
 */
const SuccessModal = ({ isVisible, modalHandler, connectionString, okHandler }: SuccessModalProps): JSX.Element => {
    const theme: any = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    return (
        <Dialog
            open={isVisible}
            onClose={(): void => modalHandler(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant={'h6'}>
                    {<CustomTransComponent translationKey={'ADD_DEVICE_FORM:SUCCESS_MODAL_TITLE'} />}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div style={{ wordWrap: 'break-word' }}>
                    <Typography variant="body2" style={{ marginBottom: 20 }}>
                        {<CustomTransComponent translationKey={'ADD_DEVICE_FORM:SUCCESS_MODAL_BODY_1'} />}
                    </Typography>
                    <Typography variant="subtitle2" style={{ marginBottom: 10, wordWrap: 'break-word' }}>
                        {<CustomTransComponent translationKey={'ADD_DEVICE_FORM:SUCCESS_MODAL_BODY_2'} />}
                    </Typography>

                    <Typography variant="body2">{connectionString}</Typography>
                </div>
            </DialogContent>
            <DialogActions className={classes.MuiDialogActionsRoot}>
                <Button
                    autoFocus
                    color="secondary"
                    variant="outlined"
                    startIcon={<CopyIcon />}
                    onClick={async (): Promise<void> => {
                        await copyCodeHandler(connectionString);
                        dispatch(
                            setMessageContent({
                                isOpen: true,
                                message: <CustomTransComponent translationKey={'COMMON:COPY_SUCCESS'} />,
                                duration: 3000,
                            })
                        );
                    }}
                    data-testid="copy-action"
                >
                    {<CustomTransComponent translationKey={'COMMON:COPY_TO_CLIPBOARD'} />}
                </Button>

                <Button
                    autoFocus
                    color="secondary"
                    sx={{
                        backgroundColor: theme?.palette?.primary?.main,
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.main,
                        },
                    }}
                    variant="contained"
                    onClick={(): void => okHandler()}
                    data-testid="ok-action"
                >
                    {<CustomTransComponent translationKey={'ADD_DEVICE_FORM:OK_BTN'} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SuccessModal;
