import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { channelsHandler, handleChannelRemove, handleReallocateChannels, moveWithinList } from './helpers';

const trendsInitialState = { type: 'area', secondary: [], maxCount: 6, viewAllButton: false, duration: 24 };
const trendsWidgetSlice = createSlice({
    name: 'trends',
    initialState: trendsInitialState,
    reducers: {
        resetTrendsConfiguration: () => {
            return trendsInitialState;
        },
        setPredefinedTrends: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                state[key] = action.payload[key];
            });
        },
        setTrendsChannels: (state: RootState, action: PayloadAction<any>) => {
            channelsHandler(state, action);
        },
        reAllocateTrendsChannels: (state: RootState, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },
        moveBetweenTrendsLists: (state: RootState, action: PayloadAction<any>) => {
            moveWithinList(state, action);
        },
        removeTrendsChannel: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action);
        },
        setTrendsPreferences: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state) => {
            state.secondary = [];
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (action.payload.reset) state.secondary = [];
        });
    },
});

export const {
    moveBetweenTrendsLists,
    reAllocateTrendsChannels,
    resetTrendsConfiguration,
    setPredefinedTrends,
    setTrendsChannels,
    removeTrendsChannel,
    setTrendsPreferences,
} = trendsWidgetSlice.actions;

export default trendsWidgetSlice;
