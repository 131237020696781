import { Divider, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { createNumArray } from '../../utils/helpers';

export const GroupHierarchySkeleton = ({ row }: { row: number }): any =>
    createNumArray(row).map((rowItem: any) => (
        <Stack flexDirection={'row'} alignItems={'center'} width={'100%'} key={rowItem}>
            <Stack width={'100%'}>
                <Stack flexDirection={'row'} alignItems={'center'} className="margin-y-8" ml={5}>
                    <Skeleton animation="wave" variant="circular" width={24} height={24} className="margin-right-8" />
                    <Skeleton animation="wave">
                        <Typography variant="h6" component="h6">
                            Sub Loading
                        </Typography>
                    </Skeleton>
                </Stack>
                <Divider />
                <Stack flexDirection={'row'} alignItems={'center'} className="margin-y-8" ml={5}>
                    <Skeleton animation="wave" variant="circular" width={24} height={24} className="margin-right-8" />
                    <Skeleton animation="wave">
                        <Typography variant="h6" component="h6">
                            SubTitle Loading
                        </Typography>
                    </Skeleton>
                </Stack>
                <Divider />
                <Stack flexDirection={'row'} alignItems={'center'} className="margin-y-8" ml={5}>
                    <Skeleton animation="wave" variant="circular" width={24} height={24} className="margin-right-8" />
                    <Skeleton animation="wave">
                        <Typography variant="h6" component="h6">
                            SubTitle
                        </Typography>
                    </Skeleton>
                </Stack>
                <Divider />
                <Stack flexDirection={'row'} alignItems={'center'} className="margin-y-8" ml={5}>
                    <Skeleton animation="wave" variant="circular" width={24} height={24} className="margin-right-8" />
                    <Skeleton animation="wave">
                        <Typography variant="h6" component="h6">
                            Sub Loading
                        </Typography>
                    </Skeleton>
                </Stack>
                <Divider />
                <Stack flexDirection={'row'} alignItems={'center'} className="margin-y-8" ml={5}>
                    <Skeleton animation="wave" variant="circular" width={24} height={24} className="margin-right-8" />
                    <Skeleton animation="wave">
                        <Typography variant="h6" component="h6">
                            SubTitle Loading
                        </Typography>
                    </Skeleton>
                </Stack>
                <Divider />
                <Stack flexDirection={'row'} alignItems={'center'} className="margin-y-8" ml={5}>
                    <Skeleton animation="wave" variant="circular" width={24} height={24} className="margin-right-8" />
                    <Skeleton animation="wave">
                        <Typography variant="h6" component="h6">
                            SubTitle
                        </Typography>
                    </Skeleton>
                </Stack>
                <Divider />
            </Stack>
        </Stack>
    ));
