import React from 'react';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import { Dialog, DialogTitle, Typography, DialogActions, DialogContent, Button, Stack } from '@mui/material';

import { CustomTable } from '@fiji/web/src/components/index';
import { Header } from '@fiji/common/src/types';
import moment from 'moment';
import { CustomTransComponent } from 'components';

/**
 * The DiscardModalProps type is used in a TypeScript React component and includes properties for
 * visibility and a modal handler function.
 * @property {boolean} isVisible - The `isVisible` property is a boolean value that determines whether
 * the discard modal is visible or not. If `isVisible` is `true`, the modal is visible. If `isVisible`
 * is `false`, the modal is hidden.
 * @property modalHandler - The `modalHandler` property is a function that takes a boolean parameter
 * `action` and does something with it. It is used to handle the action performed on the modal, such as
 * closing it or confirming a discard action.
 */
type SubscriptionPlanModalProps = {
    isVisible: boolean;
    modalHandler: (action: boolean) => void;
    okHandler: (e: any) => Promise<void>;
    subscriptionPlan: any;
    total: number;
    handleSubscriptionSelect?: (arg0: string) => void;
    handlepagePayload?: (arg0: any) => void;
    isLoading: boolean;
};

/**
 * The `DiscardModal` function is a TypeScript React component that renders a dialog box with a title,
 * message, and two buttons for canceling or discarding changes.
 * @param {SubscriptionPlanModalProps}  - - `isVisible`: a boolean value indicating whether the modal is visible
 * or not.
 * @returns The code is returning a JSX element, specifically a `Dialog` component with various child
 * components such as `DialogTitle`, `DialogContent`, and `DialogActions`.
 */
export const SubscriptionPlanModal = ({
    isVisible,
    modalHandler,
    // connectionString,
    subscriptionPlan,
    okHandler,
    total,
    handleSubscriptionSelect,
    handlepagePayload,
    isLoading,
}: SubscriptionPlanModalProps): JSX.Element => {
    const theme: any = useTheme();
    const classes = useStyles(theme);

    const tableRef = React.useRef<any>();
    const [selectedId, setSelectedId] = React.useState<string>('');
    const handleRadioSelect = (data: string): void => {
        setSelectedId(data);

        if (handleSubscriptionSelect) handleSubscriptionSelect(data.toString());
    };
    const handleFilter = (filteredValues: any, sortedData: any): any => {
        if (Object.keys(sortedData)?.length) {
            if (handlepagePayload) {
                handlepagePayload((prev: any) => ({ ...prev, sort: sortedData }));
            }
        }
    };
    const getSubscriptionCell = (cell: any): JSX.Element => (
        <Typography variant="body2" fontSize={'14px'}>
            {cell?.name ?? '-'}
        </Typography>
    );
    const getStartDate = (data: any): string => moment(data?.startDate).format('LL') ?? '--';
    const getDeviceCount = (data: any): string => {
        if (data?.totalQuantity === 0) {
            return '0';
        }
        if (data?.totalQuantity !== 0) {
            return `${data?.fulfilledQuantity}/${data?.totalQuantity}`;
        }
        return 'N/A';
    };
    const getEndDate = (data: any): string => moment(data?.endDate).format('LL') ?? '--';

    const prepareHeaderColumns = (): Header[] => {
        const columns: Header[] = [
            {
                header: 'Name',
                cell: getSubscriptionCell,
                accessor: 'name',
                isSortable: true,
            },
            {
                header: 'Start Date',
                cell: getStartDate,
                accessor: 'startDate',
            },
            {
                header: 'End Date',
                cell: getEndDate,
                accessor: 'endDate',
            },
            {
                header: 'Device Count',
                cell: getDeviceCount,
                accessor: 'deviceCount',
            },
            {
                header: '',
                isRadio: true,
                width: '5%',
            },
        ];

        return columns;
    };

    const handleBackAction = (): void => {
        modalHandler(false);
    };

    return (
        <Dialog
            open={isVisible}
            onClose={(): void => modalHandler(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant={'h6'}>
                    {' '}
                    {<CustomTransComponent translationKey={'ADD_DEVICE_FORM:SUBSCRIPTION_TITLE'} />}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div>
                    <div style={{ wordWrap: 'break-word' }}>
                        <Typography variant="body2" style={{ marginBottom: 10 }}>
                            {<CustomTransComponent translationKey={'ADD_DEVICE_FORM:SUBSCRIPTION_TITLE_MSG'} />}
                        </Typography>
                    </div>
                    <Stack my={2} className="max-height-225 overflow-y-auto">
                        <CustomTable
                            key="e13aef249f024g"
                            data={subscriptionPlan ?? []}
                            ref={tableRef}
                            isLoading={isLoading}
                            keyToTraverse="id"
                            handleFilterChange={handleFilter}
                            total={total}
                            handleRadioSelect={handleRadioSelect}
                            headers={prepareHeaderColumns()}
                            noDataFoundTitle={'No Subscription Plans Found'}
                        />
                    </Stack>
                </div>
            </DialogContent>
            <DialogActions className={classes.MuiDialogActionsRoot}>
                <Button
                    autoFocus
                    color="secondary"
                    variant="outlined"
                    onClick={handleBackAction}
                    data-testid="copy-action"
                >
                    Back
                </Button>

                <Button
                    autoFocus
                    disabled={Boolean(!selectedId?.length)}
                    color="secondary"
                    sx={{
                        backgroundColor: theme?.palette?.primary?.main,
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.main,
                        },
                    }}
                    variant="contained"
                    onClick={okHandler}
                    data-testid="ok-action"
                >
                    Save Device
                </Button>
            </DialogActions>
        </Dialog>
    );
};
