import { createSlice } from '@reduxjs/toolkit';

const drawerNodeSlice = createSlice({
    name: 'drawerNode',
    initialState: {
        selectedDrawerNode: { type: '', typeId: '' },
        viewAllkey: '',
    },
    reducers: {
        setSelectedDrawerNode: (state, action) => {
            state.selectedDrawerNode = action.payload;
        },
        setViewAllkey: (state, action) => {
            state.viewAllkey = action.payload;
        },
    },
});

export const { setSelectedDrawerNode, setViewAllkey } = drawerNodeSlice.actions;

export default drawerNodeSlice;
