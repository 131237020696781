import React from 'react';
import { CustomModal, ModalActions } from './CustomModal';
import { useConfirm } from '@fiji/common/src/hooks';
import { DeleteModal } from '.';
import { Stack, Checkbox, DialogContent, Typography } from '@mui/material';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { useSelectedIds } from '../hooks';
import { CustomTransComponent } from '@fiji/web/src/components';
import { useGetDeviceListMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
type MultiItemsDeleteModalProps = {
    isOpen: boolean;
    header: string;
    isLoading: boolean;
    handleModalClose: () => void;
    handleConfirmDelete: () => void;
    deleteSuccess: boolean;
    handleIdsToBeDeleted: any;
    modalDescription: string;
    selectedIds: string[];
    filteredData: any;
    titleKey: string;
    titleKey1?: string;
    subtitleKey: string;
    deleteModalHeader: string;
    keyToTraverse: string;
    deleteModalDescription: string;
    deleteButtonText?: string;
};

export const DeleteDeviceModal = (props: MultiItemsDeleteModalProps): JSX.Element => {
    const modalRef = React.useRef<any>(null);

    const [selectedIds, setSelectedIds, selectionHandler] = useSelectedIds();
    const { isVisible, onCancel, onClick, onConfirm } = useConfirm(props.handleConfirmDelete);
    const isAllPXDChild = props.filteredData.every(
        (item: any) => item.wrapperDeviceType === 'Child' && item.vendorTypeName === 'Dashboard'
    );

    const isPXDChild = props.filteredData.some(
        (item: any) => item.wrapperDeviceType === 'Child' && item.vendorTypeName === 'Dashboard'
    );

    const isGateway = props.filteredData.some((item: any) => item.wrapperDeviceType === 'Gateway');
    const isPXDGateway = props.filteredData.some(
        (item: any) => item.wrapperDeviceType === 'Gateway' && item.vendorTypeName === 'Dashboard'
    );
    const initailState = props.filteredData.map((item: any) => item.id);
    const initailStatePXDChild = props.filteredData.filter(
        (item: any) => item.vendorTypeName === 'Dashboard' && item.wrapperDeviceType === 'Child'
    );

    const [selectedDeviceIds, setSelectedDeviceIds] = React.useState<any>(initailState);
    const [step, setStep] = React.useState(isPXDChild ? 1 : 2);
    const [getDeviceList, { isLoading: isDeviceListLoading }] = useGetDeviceListMutation();
    const [list, setList] = React.useState<any>([]);

    React.useEffect(() => {
        if (props.deleteSuccess) onCancel();
    }, [props.deleteSuccess]);

    React.useEffect(() => {
        if (props?.selectedIds) {
            setSelectedIds(props.selectedIds);
        }
    }, [props?.selectedIds]);

    React.useEffect(() => {
        const selectedIdsClone = JSON.parse(JSON.stringify(list));
        const listIds = selectedIdsClone?.map((item: any) => item.id);

        if (listIds.length) {
            setSelectedIds(listIds);
        }
    }, [list]);

    React.useEffect(() => {
        if (selectedIds) {
            props.handleIdsToBeDeleted(selectedIds);
        }
    }, [selectedIds]);

    React.useEffect(() => {
        setStep(isPXDChild ? 1 : 2);
    }, [props?.filteredData, isPXDChild]);

    const handleOnPress = (deviceId: string): any => {
        const selectedDeviceIdsClone = [...selectedDeviceIds];
        const userIdIndex = selectedDeviceIdsClone.findIndex((id: string) => id === deviceId);
        if (userIdIndex !== -1) {
            selectedDeviceIdsClone.splice(userIdIndex, 1);
        } else {
            selectedDeviceIdsClone.push(deviceId);
        }
        setSelectedDeviceIds(selectedDeviceIdsClone);
    };

    React.useEffect(() => {
        const getList = async (): Promise<void> => {
            if (props?.filteredData) {
                const parentIds = props?.filteredData
                    .filter((item: any) => item.wrapperDeviceType === 'Gateway')
                    .map((item: any) => item.id);

                if (parentIds && parentIds.length > 0) {
                    const result: any = await getDeviceList({
                        body: {
                            page: 0,
                            size: 25,
                            filters: { parentId: parentIds },
                        },
                    });

                    const value = props?.filteredData.filter(
                        (item: any) => item.wrapperDeviceType === 'Child' && item.vendorTypeName !== 'Dashboard'
                    );

                    const allValue = value.filter((item: any) => !parentIds.includes(item.parentId));

                    const filetrValue = props?.filteredData.filter(
                        (item: any) => item.wrapperDeviceType === 'Gateway' || item.wrapperDeviceType === 'Standalone'
                    );
                    const allFilterValue = [...filetrValue, ...allValue];

                    const allDevices = allFilterValue.map((item: any) => {
                        if (item.wrapperDeviceType === 'Gateway') {
                            const childDevices = result.data?.data?.records.filter(
                                (childItem: any) => childItem.parentId === item.id
                            );
                            return { ...item, data: childDevices };
                        }

                        return item;
                    });

                    setList(allDevices);
                } else {
                    const filterNonPxdbChild = props?.filteredData.filter(
                        (obj: any) => !initailStatePXDChild.some((obj2: any) => obj.id === obj2.id)
                    );
                    setList(filterNonPxdbChild);
                }
            }
        };
        void getList();
    }, [props?.filteredData]);

    const modalActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                props.handleModalClose();
                setSelectedIds(props?.selectedIds);
            },
        },
        {
            key: 'continue',
            label: <CustomTransComponent translationKey={'COMMON:CONTINUE'} />,
            variant: 'contained',
            disabled: Boolean(!selectedIds?.length) || isDeviceListLoading,
            handleClick: (): void => {
                if (step === 1) {
                    setStep(2);
                } else {
                    props.handleModalClose();
                    onClick();
                }
            },
        },
    ];

    const modalChildActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                props.handleModalClose();
                setSelectedIds(props?.selectedIds);
            },
        },
    ];

    const showWarnings = (): any => (
        <CustomModal
            ref={modalRef}
            actions={!isAllPXDChild ? modalActions : modalChildActions}
            type="primary"
            header={props?.header}
            isOpen={props?.isOpen}
            isLoading={isDeviceListLoading}
        >
            <DialogContent className="padding-0">
                <Typography variant="body1">
                    {!isAllPXDChild ? (
                        <CustomTransComponent
                            translationKey={
                                'DEVICE_MANAGEMENT:DASHBOARD_CHILD_DEVICE_DELETE_WITH_OTHER_DEVICES_DESCRIPTION'
                            }
                        />
                    ) : (
                        <CustomTransComponent
                            translationKey={'DEVICE_MANAGEMENT:DASHBOARD_CHILD_DEVICE_DELETE_DESCRIPTION'}
                        />
                    )}
                </Typography>
                <Stack my={2} className="max-height-225 overflow-y-auto">
                    {initailStatePXDChild &&
                        initailStatePXDChild.map((item: any) => (
                            <InfoListItem
                                key={item.id}
                                className="cursor-pointer padding-y-8"
                                dense
                                style={{ marginTop: 10, marginBottom: 10, marginLeft: -70 }}
                                title={
                                    <Stack>
                                        <Typography variant="body1" fontWeight={'600'}>
                                            {item?.[props.titleKey]}
                                        </Typography>
                                        {props.titleKey1 && (
                                            <Typography variant="body2" fontSize={'14px'}>
                                                Serial# {item?.[props.titleKey1]}
                                            </Typography>
                                        )}
                                    </Stack>
                                }
                                subtitle={item?.[props?.subtitleKey]}
                                subtitleSeparator={'/'}
                                ripple
                            />
                        ))}
                </Stack>
            </DialogContent>
        </CustomModal>
    );

    const showDeviceList = (): any => (
        <CustomModal
            ref={modalRef}
            actions={modalActions}
            type="primary"
            header={props?.header}
            isOpen={props?.isOpen}
            isLoading={isDeviceListLoading}
        >
            <DialogContent className="padding-0">
                <Typography variant="body1">
                    {isGateway ? (
                        <CustomTransComponent translationKey={'DEVICE_MANAGEMENT:DASHBOARD_MAIN_DESC'} />
                    ) : (
                        <CustomTransComponent translationKey={'DEVICE_MANAGEMENT:MULTI_ITEMS_MODAL_DESCRIPTION'} />
                    )}
                    {isPXDGateway && (
                        <Typography variant="body1" fontWeight={600}>
                            <CustomTransComponent translationKey={'DEVICE_MANAGEMENT:DASHBOARD_BOLD_DESC'} />
                        </Typography>
                    )}
                </Typography>

                <Stack my={2} className="max-height-225 overflow-y-auto">
                    {list &&
                        list.map((item: any) => (
                            <>
                                <InfoListItem
                                    key={item.id}
                                    className="cursor-pointer padding-y-8"
                                    dense
                                    style={{ marginBottom: 10, marginLeft: -20 }}
                                    title={
                                        <Stack>
                                            <Typography variant="body1" fontWeight={'600'}>
                                                {item.name}
                                            </Typography>
                                            {props.titleKey1 && (
                                                <Typography variant="body2" fontSize={'14px'}>
                                                    Serial# {item?.[props.titleKey1]}
                                                </Typography>
                                            )}
                                        </Stack>
                                    }
                                    subtitle={item?.[props?.subtitleKey]}
                                    subtitleSeparator={'/'}
                                    ripple
                                    icon={
                                        <Checkbox
                                            checked={selectedIds.includes(item?.id)}
                                            style={{ alignSelf: 'flex-start', marginTop: -10 }}
                                            onChange={selectionHandler(item[props.keyToTraverse])}
                                        />
                                    }
                                />
                                {item.data &&
                                    item.data.map((dataItem: any) => (
                                        <InfoListItem
                                            key={dataItem.name}
                                            title={
                                                <Stack>
                                                    <Typography variant="body1" fontWeight={'600'}>
                                                        {dataItem.name}
                                                    </Typography>
                                                    {props.titleKey1 && (
                                                        <Typography variant="body2" fontSize={'14px'}>
                                                            Serial# {dataItem?.[props.titleKey1]}
                                                        </Typography>
                                                    )}
                                                </Stack>
                                            }
                                            subtitle={`${dataItem?.[props?.subtitleKey]}/${dataItem.groupName}`}
                                            subtitleSeparator={'/'}
                                            style={{ marginTop: 8, marginLeft: 5 }}
                                            icon={
                                                <Checkbox
                                                    disabled
                                                    checked={selectedIds.includes(item?.id)}
                                                    onChange={(): void => {
                                                        handleOnPress(dataItem?.id);
                                                    }}
                                                    style={{ alignSelf: 'flex-start', marginTop: -20 }}
                                                />
                                            }
                                        />
                                    ))}
                            </>
                        ))}
                </Stack>
            </DialogContent>
        </CustomModal>
    );

    // console.log('selectedIds', selectedIds, list);

    return (
        <>
            {step === 1 ? showWarnings() : showDeviceList()}
            <DeleteModal
                isVisible={isVisible}
                confirmClick={onConfirm}
                onCancel={onCancel}
                isLoading={props?.isLoading}
                header={props?.deleteModalHeader}
                description={props?.deleteModalDescription}
                deleteButtonText={props?.deleteButtonText}
            />
        </>
    );
};
