import { RootState } from '../../app/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const tableSlice = createSlice({
    name: 'table',
    initialState: {
        pagePayload: { page: 0, size: 10 },
        selectedIds: [],
    },
    reducers: {
        setPagePayload: (state: RootState, action: PayloadAction<any>) => {
            state.pagePayload = action.payload;
        },
        setSelectedIds: (state: RootState, action: PayloadAction<any>) => {
            state.selectedIds = action.payload;
        },
    },
});

export const { setPagePayload, setSelectedIds } = tableSlice.actions;

export const selectPagePayload = (state: RootState): any => state.table.pagePayload;
export const selectSelectionIds = (state: RootState): any => state.table.selectedIds;

export default tableSlice;
