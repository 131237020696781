import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import {
    useAddWidgetsToDashboardMutation,
    useGetDashboardByIdQuery,
    useUpdateDashboardMutation,
} from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useNavigationBack } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

type ReturnProps = {
    handleSubmit: () => Promise<void>;
    redirectionHandler: () => void;
    isLoading: boolean;
    updateLoader: boolean;
    dashboard: any;
    handleSaveAs: (args0: any) => void;
};

export const useSetDashboard = (): ReturnProps => {
    // useNewCustomTranslations('DASHBOARDS');
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { dashboardId } = useParams();
    const navigationBack = useNavigationBack();
    const handleNavigateBack = (): void => {
        navigationBack();
    };
    const currentOrg = useTypedSelector(selectedOrg);

    const layout = useTypedSelector((rootState) => rootState.dashboard.layout);
    const widgets = useTypedSelector((rootState) => rootState.dashboard.widgetList);

    const {
        currentData: dashboard,
        isLoading: dashboardLoader,
        isFetching,
    }: any = useGetDashboardByIdQuery(dashboardId, {
        skip: !dashboardId || !currentOrg.id,
        refetchOnMountOrArgChange: true,
    });

    const [updateDashboard, { isLoading: updateDashboardLoader }] = useUpdateDashboardMutation();
    const [addWidgetsToDashboard, { isLoading }] = useAddWidgetsToDashboardMutation();

    function redirectionHandler(): void {
        handleNavigateBack();
    }

    async function handleSubmit(): Promise<void> {
        const { error }: any = await updateDashboard({
            body: {
                widgetLayout: layout,
                assignType: dashboard?.data?.assignType,
                assignTypeIds: dashboard?.data?.assignTypeIds,
            },
            params: { dashboardId: dashboardId },
        });
        if (!error) {
            const initialWidgets = dashboard?.data?.widgetIdList ?? [];
            const updatedWidgets = widgets.map((widget: any) => widget?.id) ?? [];
            const removedWidgets = initialWidgets.filter((widget: string) => !updatedWidgets.includes(widget));
            const addedWidgets = updatedWidgets.filter((widget: string) => !initialWidgets.includes(widget));
            if (addedWidgets?.length) {
                await addWidgetsToDashboard({ action: 'ADD', dashboardId: dashboardId, widgetIdList: addedWidgets });
            }
            if (removedWidgets?.length) {
                await addWidgetsToDashboard({
                    action: 'REMOVE',
                    dashboardId: dashboardId,
                    widgetIdList: removedWidgets,
                });
            }
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: t('DASHBOARDS:UPDATE_DASHBOARD_MSG', {
                        replace: {
                            name: dashboard?.data?.name,
                        },
                    }),
                })
            );
            redirectionHandler();
        }
    }

    function handleSaveAs(ref: any): void {
        ref?.current?.handleModalAction(true, dashboard?.data);
    }

    return {
        dashboard,
        handleSubmit,
        redirectionHandler,
        isLoading: dashboardLoader || isFetching || isLoading,
        updateLoader: updateDashboardLoader,
        handleSaveAs,
    };
};
