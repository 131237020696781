import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState = {
    filters: {},
    sortData: {},

    paginationPayload: {
        page: 0,
        pageSize: 10,
    },
    configurablePayload: {
        pinnedColumns: { left: ['__check__'], right: ['custom', 'actions'] },
        visibleColumns: {},
    },
};

const dataGridSlice = createSlice({
    name: 'dataGrid',
    initialState: initialState,
    reducers: {
        setVisibleColumns: (state: RootState, action: PayloadAction<any>) => {
            state['configurablePayload'].visibleColumns[action.payload.key] = action.payload.value;
        },
        setAllPinnedColumns: (state: RootState, action: PayloadAction<number>) => {
            state['configurablePayload'].pinnedColumns = action.payload;
        },
        setAllVisibleColumns: (state: RootState, action: PayloadAction<any>) => {
            state['configurablePayload'].visibleColumns = action.payload;
        },
        setPagination: (state: RootState, action: PayloadAction<any>) => {
            for (const key in action.payload) {
                state['paginationPayload'][key] = action.payload[key];
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase('device/setSortPayload', (state) => {
            state['paginationPayload']['page'] = 0;
        });
        builder.addCase('trends/setDeviceFilters', (state) => {
            state['paginationPayload']['page'] = 0;
        });
    },
});

export const { setVisibleColumns, setAllVisibleColumns, setAllPinnedColumns, setPagination } = dataGridSlice.actions;

export default dataGridSlice;
