import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { channelsHandler, handleChannelRemove, handleReallocateChannels, moveWithinList } from './helpers';

const commandBarInitialState: any = {
    secondary: [],
    isTotalCountVisible: false,
    viewAllButton: false,
    secondaryChannelCount: 6,
};

const commandBarWidgetSlice = createSlice({
    name: 'command_bar',
    initialState: commandBarInitialState,
    reducers: {
        resetConfiguration: () => {
            return commandBarInitialState;
        },
        setPredefinedConfiguration: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                if (key === 'primary' || key === 'secondary') {
                    state[key] = action.payload[key]?.map((item: any) => item.channels)?.flat(Infinity);
                    return;
                }
                state[key] = action.payload[key];
            });
        },
        setCommandChannels: (state: RootState, action: PayloadAction<any>) => {
            channelsHandler(state, action);
        },

        reAllocateCommandChannels: (state: RootState, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },
        moveBetweenLists: (state: RootState, action: PayloadAction<any>) => {
            moveWithinList(state, action);
        },
        removeDeviceCommand: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action);
        },
        setDisplayPreferences: (state: RootState, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
        setWidgetKey: (state: RootState, action: PayloadAction<any>) => {
            const stateClone = current(state);
            stateClone?.secondary?.map((item: any, index: number) => {
                if (item?.deviceId === action.payload.deviceId && item?.channelId === action.payload.channelId) {
                    state.secondary[index].paletteConfig = action.payload.data;
                }
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state) => {
            if (state.primary) {
                return { ...commandBarInitialState, primary: [] };
            }
            return commandBarInitialState;
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (action.payload.reset) {
                state.secondary = [];
                state.primary = [];
            }
        });
    },
});

const commandPaletteSlice = createSlice({
    name: 'command_palette',
    initialState: {
        commandPaletteEl: null,
    },
    reducers: {
        setCommandPaletteEl: (state: RootState, action: PayloadAction<any>) => {
            state.commandPaletteEl = action.payload;
        },
    },
});

export const {
    resetConfiguration,
    reAllocateCommandChannels,
    setCommandChannels,
    setPredefinedConfiguration,
    moveBetweenLists,
    removeDeviceCommand,
    setDisplayPreferences,
    setWidgetKey,
} = commandBarWidgetSlice.actions;

export const { setCommandPaletteEl } = commandPaletteSlice.actions;

export { commandPaletteSlice };
export default commandBarWidgetSlice;
