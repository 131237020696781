import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Chip, FormControl, useTheme } from '@mui/material';
import { InputWithCustomPlaceholder } from 'components/InputWithCustomPlaceholder';
const useStyles: any = makeStyles(() => ({
    formControlRoot: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        flexWrap: 'wrap',
        flexDirection: 'row',
        border: '2px solid lightgray',
        padding: 4,
        borderRadius: '4px',
        '&> div.container': {
            gap: '6px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        '& > div.container > span': {
            backgroundColor: 'gray',
            padding: '1px 3px',
            borderRadius: '4px',
        },
    },
}));

const MultiInput = (props: any): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    return (
        <FormControl classes={{ root: classes.formControlRoot }}>
            <div>
                {props?.value?.map((item: any, index: number) => (
                    <Chip key={item} size="small" onDelete={(): void => props?.handleTagDelete?.(index)} label={item} />
                ))}
            </div>
            <InputWithCustomPlaceholder
                id="input"
                sx={{ ml: 1, flex: 1 }}
                placeholder={props?.placeholder}
                inputProps={{ 'aria-label': props?.placeholder }}
                disabled={props?.isDisabled}
                value={props?.inputValue ?? ''}
                onChange={(e: any): void => props?.setInputValue?.(e?.target?.value)}
                onKeyDown={props?.handleKeyDown}
            />
            {/* <InputBase /> */}
        </FormControl>
    );
};

export default MultiInput;
