import { api } from '../../app/api/baseApi';

const ontologyApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllDeviceCategories: builder.mutation({
            query: (params) => ({
                params,
                method: 'GET',
                url: `/ontology/v1/models/category`,
            }),
            transformResponse: (response) => {
                const responseClone = JSON.parse(JSON.stringify(response));
                responseClone.data = responseClone?.data?.map((item: any) => ({
                    id: item,
                    name: item,
                }));

                return responseClone;
            },
        }),
        getDeviceModelsByCategory: builder.mutation({
            query: (params) => ({
                url: `/ontology/v1/models`,
                method: 'GET',
                params,
            }),
        }),
        configurechannels: builder.mutation({
            query: ({ payload, deviceTypeId, realmPrefix }: any) => ({
                url: `ontology/v1/channels/${deviceTypeId}/configure`,
                method: 'PATCH',
                body: payload,
                ...(realmPrefix && { headers: { 'x-realm': realmPrefix } }),
            }),
            invalidatesTags: (result, error): any => (!error ? ['DEVICEMODELS'] : []),
        }),

        getAllDeviceModels: builder.query({
            query: () => ({
                params: {
                    category: 'MODEL',
                },
                url: `/ontology/v1/models/category`,
            }),
        }),
        getDeviceModels: builder.query({
            query: (body) => ({
                url: 'ontology/v1/models/filter',
                method: 'POST',
                body,
            }),
            providesTags: ['DEVICEMODELS'],
        }),
    }),
});

export const {
    useGetDeviceModelsQuery,
    useGetAllDeviceModelsQuery,
    useConfigurechannelsMutation,
    useGetAllDeviceCategoriesMutation,
    useGetDeviceModelsByCategoryMutation,
} = ontologyApi;
