import React from 'react';
import { useDebounce } from './useDebounce';

type ReturnProps = [
    tableFilters: {
        [key: string]: string | string[];
    }
];

type AcceptedProps = {
    allFilters: Filter[];
    triggeredFilters: {
        [key: string]: string | string[];
    };
    timer?: number;
};

type Filter = {
    [key: string]: any;
};

/**
 * The `useFilteredValues` function is a custom React hook that manages and updates table filters based
 * on triggered filters.
 * @param {AcceptedProps}  - - `allFilters`: An array of all available filters.
 * @returns The function `useFilteredValues` returns an array containing the `tableFilters` state.
 */
export const useFilteredValues = ({ allFilters, triggeredFilters, timer }: AcceptedProps): ReturnProps => {
    const [tableFilters, setTableFilters] = React.useState<ReturnProps[0]>(triggeredFilters);

    const [debouncedFilterChange] = useDebounce((key: string) => {
        setTableFilters((prev: AcceptedProps['triggeredFilters']) => ({
            ...prev,
            [key]: triggeredFilters[key],
        }));
    }, timer ?? 600);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this case,
it is used to trigger the `handleFilterChange` function whenever the `triggeredFilters` prop
changes. */
    React.useEffect(() => {
        handleFilterChange();
    }, [triggeredFilters]);

    /**
     * The function `handleFilterChange` iterates through all filters and updates the `tableFilters`
     * state based on the `triggeredFilters` state.
     */
    const handleFilterChange = (): void => {
        allFilters?.forEach((filter: Filter) => {
            if (triggeredFilters && Object.keys(triggeredFilters)?.length) {
                if (filter?.accessor || filter?.filterKey) {
                    for (const key in triggeredFilters) {
                        if (
                            (filter?.accessor === key || filter?.filterKey === key) &&
                            JSON.stringify(triggeredFilters) !== JSON.stringify(tableFilters)
                        ) {
                            if (filter?.isDebounce) {
                                debouncedFilterChange(undefined, key);
                                return;
                            }

                            setTableFilters((prev: AcceptedProps['triggeredFilters']) => ({
                                ...prev,
                                [key]: triggeredFilters[key],
                            }));
                        } else if (
                            !Object.prototype.hasOwnProperty.call(
                                triggeredFilters,
                                filter?.filterKey ?? filter?.accessor
                            ) &&
                            Object.prototype.hasOwnProperty.call(tableFilters, filter?.filterKey ?? filter?.accessor)
                        ) {
                            debouncedFilterChange(setTableFilters, triggeredFilters);
                        }
                    }
                }
                return;
            }

            debouncedFilterChange(setTableFilters, {});
        });
    };

    return [tableFilters];
};
