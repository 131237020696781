export const PAGINATION_PAYLOAD: any = {
    keepUnusedDataFor: 0.00001,
    serializeQueryArgs: ({ endpointName, queryArgs }: any) => {
        const newQueryArgs = { ...queryArgs };
        if (newQueryArgs.page !== null && newQueryArgs?.page !== undefined) {
            delete newQueryArgs.page;
            delete newQueryArgs.pullRefresh;
        }

        return [`${endpointName}`, { ...newQueryArgs }];
    },
    forceRefetch: ({ current, incoming }: any): any => JSON.stringify(current) !== JSON.stringify(incoming),
    merge: (currentCache: any, newItems: any, { arg }: any): any => {
        const newData = JSON.parse(JSON.stringify(currentCache));
        if (newItems?.data?.records?.length) {
            newData?.data?.records?.push(...newItems.data.records);
        }
        if (arg?.pullRefresh || arg?.page === 0) {
            return newItems;
        }
        return newData;
    },
};
