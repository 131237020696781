// eslint-disable-next-line @typescript-eslint/naming-convention
import React from 'react';
import { useIsMount } from './useIsMount';
import { useAppDispatch, useTypedSelector } from '../app/store';
import { useDebounce } from './useDebounce';
import { selectPagePayload, setPagePayload, setSelectedIds } from '../features/table/tableSlice';

export const useTableHandler = (): any => {
    const dispatch = useAppDispatch();
    const [searchKey, setSearchKey] = React.useState<any>();
    const pagePayload = useTypedSelector(selectPagePayload);
    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);
    const isMount = useIsMount();
    const handlePageChange = (page: any, size: any): void => {
        dispatch(setPagePayload({ ...pagePayload, page: page, size: size }));
    };
    const handleFilterChange = (filters: any, sortData?: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(pagePayload.filters ?? {})) {
            dispatch(
                setPagePayload({
                    ...pagePayload,
                    page: 0,
                    filters: { ...filters, ...(isMount ? pagePayload.filters : {}) },
                })
            );
        }
        if (sortData && Object.keys(sortData)?.length) {
            dispatch(
                setPagePayload({
                    ...pagePayload,
                    page: 0,
                    sort: sortData,
                })
            );
        }
    };

    const handleCheckboxSelect = (data: any): void => {
        dispatch(setSelectedIds(data));
    };

    React.useEffect(() => {
        if (!isMount) {
            dispatch(
                setPagePayload({
                    ...pagePayload,
                    page: 0,
                    searchKey: debouncedValue,
                })
            );
        }
    }, [debouncedValue]);

    React.useEffect(() => {
        return () => {
            dispatch(setPagePayload({ page: 0, size: 10 }));
        };
    }, []);

    return {
        handlePageChange,
        handleFilterChange,
        handleCheckboxSelect,
        searchKey,
        setSearchKey,
    };
};
