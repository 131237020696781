import React from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';

/* The code is defining a functional component called `AutoCompleteTags` that returns a JSX element. */
export const AutoCompleteTags = (props: any): JSX.Element => (
    <Autocomplete
        multiple
        id="tagIds"
        value={props?.value}
        disabled={props?.disabled}
        {...(props?.options?.every((item: any) => typeof item === 'object') && {
            getOptionLabel: (option: any) => option.name,
        })}
        options={props?.options}
        onChange={props?.onChange}
        renderTags={(value: any[], getTagProps): any =>
            value.map((option: any, index: number) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                    {...getTagProps({ index })}
                    label={typeof option === 'object' ? option?.name : option}
                    onDelete={(): void =>
                        props?.handleTagDelete(typeof option === 'object' ? option?.id : option, index)
                    }
                />
            ))
        }
        renderInput={(params): JSX.Element => (
            <TextField
                className={props?.className}
                {...params}
                variant="filled"
                hiddenLabel={true}
                id="name"
                {...(props?.value?.length !== (props?.maxValue ?? 6) && { placeholder: props.placeholder })}
                onKeyDown={props?.onKeyDown}
                inputProps={{
                    ...params.inputProps,
                    maxLength: 16,
                }}
            />
        )}
        {...(props?.value?.length === (props?.maxValue ?? 6) && { readOnly: true })}
    />
);
