/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type AutomationModal = {
    isOpen: boolean;
    automationId?: string;
};

const initialAutomationModalState: AutomationModal = {
    isOpen: false,
};

const automationSlice = createSlice({
    name: 'automation',
    initialState: {
        automationModal: initialAutomationModalState,
    },
    reducers: {
        addAutomationModal: (state, { payload }: PayloadAction<AutomationModal>) => {
            state.automationModal = payload;
        },
    },
});

export const { addAutomationModal } = automationSlice.actions;

export const selectAutomationModal = (state: RootState): AutomationModal => state.automation.automationModal;
export default automationSlice;
