import React from 'react';

import { createNumArray } from 'utils/helpers';
import { Radio, Skeleton, Stack, Typography } from '@mui/material';

export const WidgetsColumnSkeleton = ({ items, hideSource }: { items: number; hideSource?: boolean }): JSX.Element => (
    <>
        {createNumArray(items)?.map((item: any) => (
            <Stack
                key={item}
                py={'12px'}
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                className="border-bottom-1"
            >
                <Stack pl={2} direction={'row'} alignItems={'center'} spacing={2} className="flex-50">
                    <Skeleton height={30} width={30} animation="wave" variant="circular">
                        <Radio />
                    </Skeleton>

                    <Skeleton animation="wave">
                        <Typography variant="body1">Loading Title</Typography>
                    </Skeleton>
                </Stack>
                {!hideSource && (
                    <Stack className="flex-40">
                        <Skeleton animation="wave">
                            <Typography variant="body1">Loading Title</Typography>
                        </Skeleton>
                    </Stack>
                )}
            </Stack>
        ))}
    </>
);
