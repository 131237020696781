import React, { useMemo } from 'react';

import {
    setWidgetDevice,
    setWidgetLoader,
    setWidgetSource,
} from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';

import {
    setMessageContent,
    setPageHeaderData,
    setAppHeaderSubTitle,
} from '@fiji/common/src/features/common/commonSlice';

import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useNavigationBack } from 'hooks';
import { setPredefinedGauge } from '@fiji/common/src/features/widgetManagement/gaugeWidgetSlice';
import { setPredefinedChart } from '@fiji/common/src/features/widgetManagement/chartWidgetSlice';
import { setPredefinedTrends } from '@fiji/common/src/features/widgetManagement/trendsWidgetSlice';
import { setDashboardWidgetIds } from '@fiji/common/src/features/dashboardManagement/dashboardSlice';
import { useLocation, useParams } from 'react-router-dom';
import { setPredefinedVirtualLoads } from '@fiji/common/src/features/widgetManagement/virtualLoadsWidgetSlice';
import { setPredefinedConfiguration } from '@fiji/common/src/features/widgetManagement/detailsWidgetSlice';
import { useGetDeviceByProfileQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useGetWidgetByIdQuery, useUpdateWidgetMutation } from '@fiji/common/src/features/widgetManagement/widgetApi';
import { useTranslation } from 'react-i18next';
import { getWidgetTypeValidationConfig } from '../utils/helpers';

type ReturnProps = {
    handleSaveButton: () => Promise<void>;
    updateLoader: boolean;
    isLoading: boolean;
    deviceError: boolean;
    renderSx: any;
    details: { widgetDetails: any; deviceDetails: any };
    handleCloseButton: () => void;
    predefinedHandlers: (args: any) => any;
};

export const useWidgetConfigHandlers = (customSuccessMessage?: string): ReturnProps => {
    const { t } = useTranslation();
    const { widgetId } = useParams();
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);
    const navigationBack = useNavigationBack();
    const widgetData = useTypedSelector((data: any) => data.widgetConfiguration.data);
    const source = useTypedSelector((data: any) => data.widgetConfiguration.source);
    const pageHeader = useTypedSelector((data: any) => data.common.pageHeader?.title);
    const handleNavigateBack = (): void => {
        navigationBack();
    };
    const {
        data: widgetDetails,
        isLoading,
        isFetching,
        isError,
    }: any = useGetWidgetByIdQuery(widgetId, { skip: !widgetId || !currentOrg?.id });

    const {
        data: deviceDetails,
        isLoading: deviceDetailsLoading,
        isFetching: deviceDetailsFetching,
        isError: deviceError,
    } = useGetDeviceByProfileQuery(source?.id, {
        skip: !source?.id,
    });

    const [updateWidget, { isSuccess, isLoading: updateLoader }] = useUpdateWidgetMutation();

    React.useEffect(() => {
        if (widgetDetails?.data?.sourceId && !widgetDetails?.data?.multiDevice && !pageHeader) {
            dispatch(
                setWidgetSource({
                    id: widgetDetails?.data?.sourceId,
                    name: widgetDetails?.data?.sourceName,
                    categoryId: widgetDetails?.data?.categoryId,
                })
            );
        }
    }, [widgetDetails]);

    React.useEffect(() => {
        if (widgetDetails) {
            dispatch(setPageHeaderData({ title: widgetDetails?.data?.name }));
            dispatch(setAppHeaderSubTitle(t('WIDGETS:WIDGETS_BUILDER')));
        }
    }, [widgetDetails]);

    React.useEffect(() => {
        if (deviceDetails && deviceDetails?.data) {
            dispatch(setWidgetDevice({ data: deviceDetails?.data, reset: false }));
        }
    }, [deviceDetails]);

    React.useEffect(() => {
        if (isError) {
            dispatch(setWidgetLoader(false));
        }
    }, [isError]);

    React.useEffect(() => {
        if (isSuccess) redirectionHandler();
    }, [isSuccess]);

    async function handleSaveButton(): Promise<void> {
        const configClone: any = JSON.parse(JSON.stringify(widgetData));
        switch (widgetDetails?.data?.widgetType?.id) {
            case 'timeline':
            case 'device_list':
            case 'group_list':
                if (!configClone['multiDevice']) {
                    delete configClone['filters'];
                }
                if (Array.isArray(configClone?.preferences) && !configClone?.preferences?.length) {
                    delete configClone['preferences'];
                }
                delete configClone['selectedNodes'];
                delete configClone['secondary'];
                delete configClone['statusCount'];
                break;
            case 'map':
                if (!configClone['multiDevice']) {
                    delete configClone['filters'];
                }
                delete configClone['selectedNodes'];
                delete configClone['markers'];
                break;

            case 'gauge':
                delete configClone['data']['value'];
                break;
            case 'properties':
            case 'settings':
            case 'details':
                if (configClone['primary']) {
                    configClone['primary']?.forEach((item: any) => {
                        item?.channels?.forEach((subItem: any) => {
                            delete subItem?.value;
                        });
                    });
                }
                if (configClone['secondary']) {
                    configClone['secondary']?.forEach((item: any) => {
                        item?.channels?.forEach((subItem: any) => {
                            delete subItem?.value;
                        });
                    });
                }
                break;
            case 'charts':
                configClone['secondary']?.forEach((item: any) => {
                    delete item.value;
                });
                break;
            case 'trends':
                configClone['secondary']?.forEach((item: any) => {
                    delete item?.data;
                });
                break;
            case 'loads':
                if (!configClone['multiDevice']) {
                    delete configClone['selectedNodes'];
                    configClone['secondary'] = configClone['secondary']?.map((item: any) => item.loadId);
                } else {
                    configClone['selectedNodes'] = configClone['selectedNodes']?.map((item: any) => ({
                        loadId: item.loadId,
                        deviceId: item.deviceId,
                    }));
                    delete configClone['secondary'];
                }
                break;
            default:
                break;
        }

        if (
            widgetDetails?.data?.widgetType?.id === 'timeline' ||
            widgetDetails?.data?.widgetType?.id === 'device_list'
        ) {
            delete configClone['secondary'];
            delete configClone['statusCount'];
        } else if (widgetDetails?.data?.widgetType?.id === 'gauge') {
            delete configClone['data']['value'];
        }

        const { error }: any = await updateWidget({
            body: {
                config: configClone,
                sourceId: source ? source?.id : null,
                sourceName: source ? source?.name : null,
            },
            widgetId: widgetId,
        });
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message:
                        customSuccessMessage ??
                        t('WIDGETS:WIDGET_UPDATE_SUCCESS', {
                            replace: {
                                widgetName: configClone?.name,
                            },
                        }),
                })
            );
        }
    }

    function predefinedHandlers(data: any): void {
        switch (widgetDetails?.data?.widgetType?.id) {
            case 'details':
            case 'settings':
            case 'properties':
                dispatch(setPredefinedConfiguration(data));
                break;
            case 'gauge':
                dispatch(setPredefinedGauge(data));
                break;
            case 'charts':
                dispatch(setPredefinedChart(data));
                break;
            case 'trends':
                dispatch(setPredefinedTrends(data));
                break;
            case 'virtual_loads':
                dispatch(setPredefinedVirtualLoads(data));
                break;
            default:
                break;
        }
    }

    function redirectionHandler(): void {
        if (state?.assignType) {
            dispatch(setDashboardWidgetIds(widgetDetails?.data?.id));
        }
        handleNavigateBack();
    }

    const renderSx = (): any => {
        if (getWidgetTypeValidationConfig(state?.typeId)['loaderType'] === 'progress') {
            return {
                position: 'relative',
                minHeight: '400px',
            };
        }
        return { position: 'relative' };
    };

    return {
        handleSaveButton,
        updateLoader,
        renderSx,
        deviceError,
        isLoading: useMemo(
            () => isLoading || isFetching || deviceDetailsFetching || deviceDetailsLoading,
            [isLoading || isFetching || deviceDetailsFetching || deviceDetailsLoading]
        ),
        details: useMemo(
            (): any => ({ widgetDetails: widgetDetails?.data, deviceDetails: deviceDetails?.data }),
            [widgetDetails?.data, deviceDetails?.data]
        ),
        handleCloseButton: redirectionHandler,
        predefinedHandlers,
    };
};
