import React from 'react';

import CustomIcon from '../../../../components/CustomIcon';

import { WidgetHeader } from 'pages/WidgetManagement/common/WidgetHeader';
import { InfoListItem } from '@brightlayer-ui/react-components';
import HelpCenter from '@mui/icons-material/HelpCenter';
import Workspaces from '@mui/icons-material/Workspaces';
import { Avatar, Divider, List, Paper, Stack, Typography } from '@mui/material';
import { WidgetFooter } from 'pages/WidgetManagement/common';
import { CustomTransComponent, GroupPath } from 'components';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '@fiji/common';
import { CustomEmptyState } from 'components/CustomEmptyState';

const Component = ({ widgetData: detailWidget, mode }: { widgetData: any; mode: string }): JSX.Element => {
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const navigate = useNavigate();
    return (
        // const getIcon = (count: number, status: string): JSX.Element => {
        //     switch (status) {
        //         case 'alarms':
        //             return <NotificationsActive {...(count && { color: 'error' })} fontSize={'inherit'} />;
        //         case 'offline':
        //             return <CloudOffFilled className={count ? 'sky-800' : ''} fontSize={'inherit'} />;
        //         case 'warnings':
        //             return <Warning {...(count && { color: 'warning' })} fontSize={'inherit'} />;
        //         case 'information':
        //             return <Info className={count ? theme?.palette?.primary?.main : ''} fontSize={'inherit'} />;
        //         default:
        //             return <></>;
        //     }
        // };

        <Paper sx={{ height: '100%' }}>
            <WidgetHeader mode={mode} widgetData={detailWidget} count={detailWidget?.secondary?.length} />
            {mode !== 'edit' && <Divider />}
            <Stack
                sx={{
                    height: `calc(100% - ${mode === 'edit' || detailWidget?.viewAllButton ? '97px' : '49px'})`,
                    overflow: 'auto',
                }}
            >
                {detailWidget?.secondary?.length ? (
                    detailWidget?.secondary
                        ?.slice(0, detailWidget?.maxCount ?? detailWidget?.secondary?.length)
                        .map((item: any) => (
                            <List key={item?.id} disablePadding>
                                <InfoListItem
                                    title={''}
                                    divider={'full'}
                                    avatar
                                    iconAlign="center"
                                    statusColor={item?.indicator}
                                    leftComponent={
                                        <>
                                            <Stack className="white-space-pre">
                                                <Typography
                                                    sx={{ fontSize: '16px', fontWeight: '600', color: '#424E54' }}
                                                >
                                                    {item?.name}
                                                </Typography>
                                                <GroupPath pathName={item?.groupPath} width={'220px'} />
                                            </Stack>
                                        </>
                                    }
                                    icon={
                                        <Avatar sx={{ bgcolor: item?.icon?.web?.style?.backgroundColor }}>
                                            <CustomIcon
                                                iconName={item.icon?.web?.name ?? ''}
                                                family={item.icon?.web?.family ?? ''}
                                                iconProps={{ sx: { color: item?.icon?.web?.style?.color } }}
                                                defaultIcon={<HelpCenter color="primary" />}
                                            />
                                        </Avatar>
                                    }
                                    rightComponent={
                                        <>
                                            {Object.keys(item?.statusCount ?? {}).map((status) =>
                                                item?.statusCount[status]?.count ? (
                                                    <Stack
                                                        key={status}
                                                        flexDirection={'row'}
                                                        alignItems={'center'}
                                                        sx={{ mr: 2 }}
                                                    >
                                                        <CustomIcon
                                                            iconName={item?.statusCount[status]?.icon?.web?.name ?? ''}
                                                            family={item?.statusCount[status]?.icon?.web?.family ?? ''}
                                                            iconProps={{ sx: item?.statusCount[status]?.style }}
                                                            defaultIcon={<HelpCenter color="primary" />}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                color: item?.statusCount[status]?.style?.color,
                                                                fontSize: '14px',
                                                                fontWeight: '600',
                                                            }}
                                                        >
                                                            {item?.statusCount[status]?.count}
                                                        </Typography>
                                                    </Stack>
                                                ) : (
                                                    <></>
                                                )
                                            )}
                                        </>
                                    }
                                    chevron
                                    ripple
                                    onClick={(): void => {
                                        if (mode === 'view') {
                                            navigate(`/${currentRealmName}/groupDetails/${item.id}`);
                                        }
                                    }}
                                />
                            </List>
                        ))
                ) : (
                    <CustomEmptyState
                        className="padding-y-96"
                        icon={<Workspaces fontSize={'inherit'} />}
                        title={<CustomTransComponent translationKey={'GROUP_MANAGEMENT:LABELS.NO_GROUPS_FOUND'} />}
                    />
                )}
            </Stack>
            <WidgetFooter mode={mode} widgetData={detailWidget} disabled={!detailWidget?.secondary?.length} />
        </Paper>
    );
};
export default Component;
