import { createContext, useContext } from 'react';

type ConfirmModalContextType = {
    openConfirmModal: any;
    data: any;
};

export const ConfirmModalContext = createContext<ConfirmModalContextType | null>(null);

export const useConfirmModalContext = (): ConfirmModalContextType => {
    const context = useContext(ConfirmModalContext);
    if (context === null) {
        throw new Error('useConfirmModalContext must be used within a ConfirmModalContext');
    }
    return context;
};
