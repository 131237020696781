import React, { PropsWithChildren, ReactElement } from 'react';
import { TreeProvider, TreeContext } from './TreeProvider';
import { DragControlProvider, DragControlContext } from './DragControlProvider';
import { PlaceholderProvider, PlaceholderContext } from './PlaceholderProvider';
import { TreeProps } from '../types';

export const Providers = <T,>(props: PropsWithChildren<TreeProps<T>>): ReactElement => (
    <TreeProvider {...props} {...(!props?.dnd && { canDrag: () => false })}>
        <DragControlProvider>
            <PlaceholderProvider>{props.children}</PlaceholderProvider>
        </DragControlProvider>
    </TreeProvider>
);

export { TreeContext, DragControlContext, PlaceholderContext };
export { ConfirmModalContext } from './ConfirmModalProvider';
