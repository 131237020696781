function handlePrimaryHeading(widgetType: any): string {
    switch (widgetType) {
        case 'device_list':
            return 'Selected Devices';
        case 'group_list':
            return 'Selected Groups';
        case 'loads':
            return 'Selected Loads';
        default:
            return 'Selected Groups and Devices';
    }
}

function handleSubHeading(widgetType: any, payload: any): string {
    switch (widgetType) {
        case 'device_list':
            return `Drag the Selected Devices in the order you would like to view them.Total ${payload?.selectedNodes?.length} devices disaplayed in the widget can be modified in Display Preferences.`;
        case 'group_list':
            return `Drag the Selected Groups in the order you would like to view them. Total ${payload?.selectedNodes?.length} groups displayed in the widget can be modified in Display Preferences`;
        case 'loads':
            return `Drag the Selected Loads in the order you would like to view them. Total ${payload?.selectedNodes?.length} loads displayed in the widget can be modified in Display Preferences.`;
        case 'timeline':
        default:
            return `Drag the Selected Groups and Devices in the order you would like to view them.Total ${payload?.selectedNodes?.length} devices and groups displayed in the widget can be modified in Display Preferences`;
    }
}

const getStringGroupPath = (pathName: any[], deviceName: any): string => {
    let path = '';
    if (Array.isArray(pathName)) {
        if (deviceName) path = `${path}${deviceName}`;
        if (pathName?.length > 0 && deviceName) path = `${path} <`;
        let groupPath: any = [...pathName];
        if (pathName?.length > 2) {
            groupPath = pathName?.splice(0, 2);
        }

        groupPath.forEach((groupName: string, index: number): void => {
            path = `${path} ${groupName}`;
            if (index !== groupPath?.splice(0, 2)?.length - 1) path = `${path} <`;
        });
    }
    return pathName?.length > 2 ? `${path}....` : path;
};

function getWidgetTypeValidationConfig(type: string): { [key: string]: string | boolean | number } {
    const requiredConfig: { [x: string]: string | boolean | number } = {
        isSourceRequired: false,
        loaderType: 'progress',
    };

    switch (type) {
        case 'details':
            requiredConfig['primarySkeletonCount'] = 3;
            requiredConfig['loaderType'] = 'skeleton';
            requiredConfig['isSourceRequired'] = true;
            break;
        case 'settings':
        case 'properties':
        case 'loads':
        case 'upcoming':
        case 'components':
            requiredConfig['loaderType'] = 'skeleton';
            requiredConfig['isSourceRequired'] = true;
            break;
        case 'timeline':
            requiredConfig['loaderType'] = 'skeleton';
            requiredConfig['primarySkeletonCount'] = 4;
            break;
        case 'group_list':
        case 'device_list':
        case 'virtual_loads':
            requiredConfig['loaderType'] = 'skeleton';
            break;
        case 'gauge':
        case 'charts':
        case 'trends':
        case 'command_bar':
            requiredConfig['isSourceRequired'] = true;
            break;
        default:
            break;
    }

    return requiredConfig;
}

export { handlePrimaryHeading, handleSubHeading, getWidgetTypeValidationConfig, getStringGroupPath };
