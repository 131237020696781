import { createSlice } from '@reduxjs/toolkit';

const themePaletteSlice = createSlice({
    name: 'themePalette',
    initialState: {
        selectedThemePalette: {},
    },
    reducers: {
        setSelectedThemePalette: (state, action) => {
            state.selectedThemePalette = action.payload;
        },
    },
});

export const { setSelectedThemePalette } = themePaletteSlice.actions;

export default themePaletteSlice;
