import { RootState } from '../../app/store';
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { DeviceModal } from '../../types';
import { isNullOrUndefined } from '../../utils/helpers';
import { DEVICE_CHIPS } from '../../constants';

const initialDeviceModalState: DeviceModal = {
    isOpen: false,
};

const initialState = {
    propertiesPayload: {},
    filterPayload: {},
    sortPayload: {},
    deleteDeviceModal: initialDeviceModalState,
    chips: [],
};

const deviceSlice = createSlice({
    name: 'device',
    initialState: initialState,
    reducers: {
        setUpdateProperties: (state: RootState, action: PayloadAction<any>) => {
            state.propertiesPayload[action.payload['key']] = action.payload['value'];
        },
        resetDeviceProperties: (state: RootState) => {
            state.propertiesPayload = initialState.propertiesPayload;
        },
        deleteDeviceModal: (state, { payload }: PayloadAction<DeviceModal>) => {
            state.deleteDeviceModal = payload;
        },
        setDeviceFilters: (state: RootState, action: PayloadAction<any>) => {
            const currentClone = current(state);
            const selectedChipIndex = currentClone?.chips?.findIndex(
                (chip: any) => chip === DEVICE_CHIPS[action.payload.key]
            );
            const key = action.payload.key;
            const value = action.payload.value;
            if (key === 'deviceStatus' || key === 'connectionStatus' || key === 'modelCategory') {
                const selectedIndex = currentClone['filterPayload']?.[key]?.indexOf(value);

                if (currentClone['filterPayload'][key]) {
                    if (selectedIndex !== -1) {
                        state['filterPayload'][key]?.splice(selectedIndex, 1);
                    } else {
                        state['filterPayload'][key]?.push(value);
                    }
                } else {
                    state['filterPayload'][key] = [value];
                }
            } else {
                state['filterPayload'][key] = value;
            }

            if (
                (Array.isArray(state['filterPayload'][key]) && state['filterPayload'][key]?.length === 0) ||
                isNullOrUndefined(state['filterPayload'][key]) ||
                (key === 'deviceName' && state['filterPayload'][key]?.length === 0)
            ) {
                //Remove key is array is empty
                state?.chips?.splice(selectedChipIndex, 1);
                delete state['filterPayload'][key];
            }
        },
        setSortPayload: (state: RootState, action: PayloadAction<any>) => {
            state['sortPayload']['key'] = action.payload.key;
            state['sortPayload']['sortType'] = action.payload.value;
        },
        setDeviceChips: (state: RootState, action: PayloadAction<any>) => {
            const stateClone = current(state);
            const selectedChipIndex = stateClone?.chips?.findIndex((item: any) => item === action.payload);
            if (selectedChipIndex !== -1) {
                state['chips'].splice(selectedChipIndex, 1);

                const selectedFilterKey = Object?.keys(DEVICE_CHIPS)?.find(
                    (item: any) => DEVICE_CHIPS[item] === action.payload
                );
                if (selectedFilterKey === 'deviceStatus' || selectedFilterKey === 'connnectionStatus') {
                    delete state['filterPayload']['deviceStatus'];
                    delete state['filterPayload']['connectionStatus'];
                }
                delete state['filterPayload'][selectedFilterKey as any];
            } else {
                state['chips'].push(action.payload);
            }
        },
        resetSortPayload: (state: RootState) => {
            state['sortPayload'] = {};
        },
        resetDeviceFilters: (state: RootState) => {
            state['filterPayload'] = {};
        },
        resetDeviceChips: (state: RootState) => {
            state['chips'] = [];
        },
    },
});

export const {
    resetDeviceProperties,
    setUpdateProperties,
    deleteDeviceModal,
    setDeviceFilters,
    resetSortPayload,
    setDeviceChips,
    resetDeviceChips,
    resetDeviceFilters,
    setSortPayload,
} = deviceSlice.actions;
export const selectDeleteDeviceModal = (state: RootState): DeviceModal => state.device.deleteDeviceModal;
export const deviceFilters = (state: RootState): any => state.device.filterPayload;

export default deviceSlice;
