/**
 * The `useRBAC` function is a custom React hook that manages user permissions and provides a way to
 * check if a user has a specific permission.
 * @param {any} permissions - The `permissions` parameter is an array of objects representing the
 * permissions of a user. Each object in the array should have a `name` property that represents the
 * name of the permission.
 * @returns The function `useRBAC` returns an object with a single property `hasPermission`, which is a
 * function.
 */
export const useRBAC = (permissions: any): any => {
    /**
     * The function `hasPermission` checks if a given permission name is included in the `userPermissions`
     * array and returns a boolean value.
     * @param {string} permissionName - A string representing the name of the permission that needs to be
     * checked.
     */
    const hasPermission = (permissionName: string): boolean => permissions?.includes(permissionName);

    return {
        hasPermission,
    };
};
