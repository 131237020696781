import { Stack, Skeleton, Typography } from '@mui/material';
import React from 'react';

export const DateAndYearSkelton = (): JSX.Element => (
    <Stack>
        <Skeleton>
            <Typography>11:59:59</Typography>
        </Skeleton>
        <Skeleton>
            <Typography>01/01/1970</Typography>
        </Skeleton>
    </Stack>
);
