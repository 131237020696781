import React, { memo } from 'react';
import Dialog from '@mui/material/Dialog';
import {
    Button,
    DialogActions,
    DialogTitle,
    DialogContent,
    MobileStepper,
    Stack,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Divider,
    Avatar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomTransComponent, Loader } from './';
import clsx from 'clsx';
import { Edit, FiberManualRecord } from '@mui/icons-material';

/**
 * The `StepperDialogActions` component is a TypeScript React component that renders the actions (back
 * and next buttons) for a stepper dialog, based on the current step index and the content of each
 * step.
 * @returns The code is returning a JSX element.
 */
const StepperDialogActions = ({
    modalContent,
    currentStepIndex,
    isLoading,
    isDisabled,
    extraButton,
    isStepperModal,
    gotoNextStep,
    gotoBackStep,
}: {
    modalContent: any[];
    currentStepIndex: number;
    isLoading: boolean;
    isDisabled: boolean;
    extraButton: any;
    gotoNextStep: () => void;
    gotoBackStep: () => void;
    isStepperModal?: boolean;
}): JSX.Element => {
    const getBackActionButtonLabel = (): string => {
        if (currentStepIndex === 0) {
            return (
                modalContent[currentStepIndex].backButtonLabel ?? (
                    <CustomTransComponent size={{ width: '60px' }} translationKey={'COMMON:CANCEL'} />
                )
            );
        }
        return (
            modalContent[currentStepIndex].backButtonLabel ?? (
                <CustomTransComponent size={{ width: '60px' }} translationKey={'COMMON:BACK'} />
            )
        );
    };

    const getNextActionButtonLabel = (): string => {
        if (modalContent.length - 1 === currentStepIndex) {
            return (
                modalContent[currentStepIndex].nextButtonLabel ?? (
                    <CustomTransComponent size={{ width: '60px' }} translationKey={'COMMON:FINISH'} />
                )
            );
        }
        return (
            modalContent[currentStepIndex].nextButtonLabel ?? (
                <CustomTransComponent size={{ width: '60px' }} translationKey={'COMMON:NEXT'} />
            )
        );
    };

    const theme: any = useTheme();

    return (
        <DialogActions className="fv-actions" sx={isStepperModal ? { justifyContent: 'flex-end !important' } : {}}>
            {!isStepperModal ? (
                <MobileStepper
                    style={{ height: '100%', background: 'transparent', boxShadow: 'none', width: '100%' }}
                    steps={modalContent?.length}
                    activeStep={currentStepIndex}
                    variant="dots"
                    position="static"
                    backButton={
                        <Button
                            sx={{
                                border: `1px solid ${theme?.palette?.primary?.main}`,
                                '&:hover': {
                                    backgroundColor: theme?.palette?.primary?.[50],
                                    color: theme?.palette?.primary?.main,
                                },
                                '&.Mui-disabled': {
                                    border: `1px solid ${theme?.palette?.primary?.[200]}`,
                                    color: theme?.palette?.primary?.[200],
                                },
                            }}
                            variant="outlined"
                            onClick={gotoBackStep}
                            disabled={isLoading}
                        >
                            {getBackActionButtonLabel()}
                        </Button>
                    }
                    nextButton={
                        <Stack direction={'row'} spacing={2}>
                            {extraButton}
                            <Button
                                sx={{
                                    backgroundColor: theme?.palette?.primary?.main,
                                    '&:hover': {
                                        backgroundColor: theme?.palette?.primary?.main,
                                    },
                                    '&.Mui-disabled': {
                                        color: theme?.palette?.primary?.[200],
                                        backgroundColor: theme?.palette?.primary?.[50],
                                    },
                                }}
                                variant="contained"
                                onClick={gotoNextStep}
                                disabled={isDisabled || isLoading}
                            >
                                {getNextActionButtonLabel()}
                            </Button>
                        </Stack>
                    }
                />
            ) : (
                <Stack direction={'row'} justifyContent={'flex-end'}>
                    <Button
                        sx={{
                            border: `1px solid ${theme?.palette?.primary?.main}`,
                            '&:hover': {
                                backgroundColor: theme?.palette?.primary?.[50],
                                color: theme?.palette?.primary?.main,
                            },
                        }}
                        variant="outlined"
                        onClick={gotoBackStep}
                        className="margin-right-8"
                        disabled={isLoading}
                    >
                        {getBackActionButtonLabel()}
                    </Button>
                    <Button
                        data-cy={'save'}
                        sx={{
                            backgroundColor: theme?.palette?.primary?.main,
                            '&:hover': {
                                backgroundColor: theme?.palette?.primary?.main,
                            },
                            '&.Mui-disabled': {
                                color: theme?.palette?.primary?.[200],
                                backgroundColor: theme?.palette?.primary?.[50],
                            },
                        }}
                        variant="contained"
                        onClick={gotoNextStep}
                        className="margin-right-8"
                        disabled={isDisabled || isLoading}
                    >
                        {getNextActionButtonLabel()}
                    </Button>
                </Stack>
            )}
        </DialogActions>
    );
};

/* The code is defining a TypeScript React component called `StepperModal`. It is a modal component
that displays a series of steps using a stepper. The component takes in several props, including
`modalContent` (an array of objects representing each step), `isOpen` (a boolean indicating whether
the modal is open), `submitHandler` (a function to be called when the last step is reached), and
`closeModal` (a function to close the modal). */
export const StepperModal = memo((props: any): JSX.Element => {
    const theme: any = useTheme();
    const [currentStep, setCurrentStep] = React.useState(props?.modalContent[0].key);

    /**
     * The function `getCurrentStepIndex` returns the index of the current step in the modal content array.
     */
    const getCurrentStepIndex = (): number => props?.modalContent.findIndex((step: any) => step.key === currentStep);

    /* The `React.useEffect` hook in the code is used to perform side effects in a functional
    component. In this case, it is used to update the current step whenever the `currentStep` state
    variable changes. */
    React.useEffect(() => {
        if (props?.getCurrentStep) {
            props?.getCurrentStep(currentStep);
        }
    }, [currentStep]);

    /* The `React.useEffect` hook in the code is used to update the current step whenever the `isOpen`
    prop changes. */
    React.useEffect(() => {
        if (props?.isOpen) setCurrentStep(props?.modalContent[0].key);
    }, [props?.isOpen]);

    /**
     * The function `gotoNextStep` is used to navigate to the next step in a modal, and if there are no
     * more steps, it submits the form and closes the modal.
     */
    const gotoNextStep = (): void => {
        const currentStepIndex = getCurrentStepIndex();
        if (props?.modalContent?.length - 1 > currentStepIndex) {
            setCurrentStep(props?.modalContent[currentStepIndex + 1].key);
        } else {
            props.submitHandler();
            if (props.closeModalOnFinish !== false) {
                props.closeModal();
                setCurrentStep(props?.modalContent[0].key);
            }
        }
    };

    /**
     * The function `gotoBackStep` is used to navigate to the previous step in a modal, and if there
     * are no previous steps, it closes the modal.
     */
    const gotoBackStep = (): void => {
        const currentStepIndex = getCurrentStepIndex();
        if (currentStepIndex > 0) {
            setCurrentStep(props?.modalContent[currentStepIndex - 1].key);
        } else {
            props.closeModal();
            setCurrentStep(props?.modalContent[0].key);
        }
    };

    /**
     * The getContent function returns the component from the modalContent array based on the current
     * step index.
     */
    const getContent = (): JSX.Element => props?.modalContent[getCurrentStepIndex()]?.component;
    const activeIcon = (): JSX.Element => (
        <Avatar sx={{ bgcolor: '#005EAB', width: '24px', height: '24px' }}>
            <Edit sx={{ color: '#fff', width: '18px', height: '18px' }} />
        </Avatar>
    );
    const inactiveIcon = (): JSX.Element => (
        <Avatar sx={{ bgcolor: '#eef0f0', width: '24px', height: '24px' }}>
            <FiberManualRecord sx={{ color: '#9ca5a9', width: '18px', height: '18px' }} />
        </Avatar>
    );

    return (
        <Dialog
            id="move-modal"
            data-cy="move-modal"
            open={props?.isOpen}
            aria-describedby="alert-dialog-slide-description"
            className={clsx({
                'modal-wrapper': true,
            })}
            classes={props.classes}
            sx={{
                '& .MuiMobileStepper-dotActive': {
                    backgroundColor: theme?.palette?.primary?.main,
                },
                '& .MuiPaper-root.MuiDialog-paper': props.sx,
            }}
        >
            <DialogTitle className="modal-header">
                <Typography
                    data-cy="title"
                    variant="body1"
                    fontWeight={'600'}
                    className="word-break modal-header-title"
                >
                    {props?.modalContent[getCurrentStepIndex()]?.title}
                </Typography>
                {props?.modalContent[getCurrentStepIndex()]?.header && (
                    <Typography data-cy="header" variant="h6">
                        {props?.modalContent[getCurrentStepIndex()]?.header}
                    </Typography>
                )}

                {props?.isStepperModal && (
                    <Stepper
                        activeStep={getCurrentStepIndex()}
                        alternativeLabel
                        sx={{ paddingTop: '32px', margin: '0px 365px' }}
                    >
                        {props?.steps?.map((label: string, index: number) => (
                            <Step key={label}>
                                <StepLabel
                                    {...(index >= getCurrentStepIndex() && {
                                        StepIconComponent: index === getCurrentStepIndex() ? activeIcon : inactiveIcon,
                                    })}
                                >
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                )}

                {props?.modalContent[getCurrentStepIndex()]?.description && (
                    <Typography data-cy="description" variant="body1" className="modal-description">
                        {props?.modalContent[getCurrentStepIndex()]?.description}
                    </Typography>
                )}
            </DialogTitle>
            <Divider />
            <DialogContent
                className={clsx({
                    'modal-body': true,
                    'min-height-200': props?.isLoading,
                    'padding-0': true,
                })}
            >
                {props?.isLoading ? <Loader /> : getContent()}
            </DialogContent>
            <Divider />
            <StepperDialogActions
                currentStepIndex={getCurrentStepIndex()}
                modalContent={props.modalContent}
                gotoBackStep={gotoBackStep}
                gotoNextStep={gotoNextStep}
                isLoading={props?.isLoading}
                isDisabled={props?.isDisabled}
                extraButton={props?.extraButton}
                isStepperModal={props?.isStepperModal}
            />
        </Dialog>
    );
});
