import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import {
    useCreateWidgetMutation,
    useDeleteWidgetMutation,
    useUpdateWidgetMutation,
} from '@fiji/common/src/features/widgetManagement/widgetApi';
import {
    resetCloneWidgetModal,
    resetRenameWidgetModal,
    setCloneWidgetPayload,
    setRenameWidgetPayload,
} from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { useNavigationBack } from 'hooks';
import { useTranslation } from 'react-i18next';

type ReturnProps = {
    payload: any;
    isLoading: boolean;
    handleCloneModalAction: (...args: any) => void;
    handleCloneSave: (...args: any) => Promise<void>;
    handleDeleteModalAction: (...args: any) => void;
    handleDeleteConfirm: (...args: any) => Promise<void>;
    handleRenameModalAction: (...args: any) => void;
    handleRenameSave: (...args: any) => Promise<void>;
};
export const useHandleWidgetListActions = (
    selectorType: string,
    onClickCallback?: any,
    deleteModalClickCallback?: any
): ReturnProps => {
    const { t } = useTranslation();
    const modalPayload = useTypedSelector((state) => state.widget[selectorType]);
    const dispatch = useAppDispatch();
    const navigationBack = useNavigationBack();
    const [createWidget, { isLoading }] = useCreateWidgetMutation();
    const [deleteWidget] = useDeleteWidgetMutation();
    const [updateWidget, { isLoading: updateWidgetLoader }] = useUpdateWidgetMutation();
    const handleNavigateBack = (): void => {
        navigationBack();
    };
    const handleCloneModalAction = (action: boolean, data: any): void => {
        if (action && data) {
            const presetPayload = {
                categoryId: data?.categoryId ?? '',
                ...(data?.config && { config: data?.config }),
                name: `${data?.name}-copy`,
                description: data?.description,
                isSystemWidget: data?.isSystemWidget,
                ...(data?.referenceWidgetId && { referenceWidgetId: data?.referenceWidgetId }),
                sourceId: data?.sourceId,
                type: 'PREDEFINED',
                widgetTypeId: data?.widgetType?.id,
            };
            onClickCallback(data);

            for (const key in presetPayload) {
                dispatch(setCloneWidgetPayload({ key: key, value: presetPayload[key] }));
            }

            return;
        }
    };

    const handleDeleteModalAction = (action: boolean, widgetData: any): any => {
        if (action) {
            onClickCallback(widgetData);
            return;
        }
        deleteModalClickCallback(widgetData);
        return;
    };

    const handleRenameModalAction = (action: boolean, data: any): void => {
        if (action && data) {
            onClickCallback(data);
            const presetPayload: any = {
                name: data?.name,
                description: data?.description,
            };
            for (const key in presetPayload) {
                dispatch(setRenameWidgetPayload({ key: key, value: presetPayload[key] }));
            }
            return;
        }
    };

    const handleCloneSave = async (modalType: any, onCancelCallback: any): Promise<void> => {
        const data: any = await createWidget(modalPayload?.payload);
        if (data?.data?.success) {
            dispatch(resetCloneWidgetModal());
            onCancelCallback();
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: `Widget ${modalType === 'save' ? 'saved' : 'cloned'} successfully.`,
                })
            );
            if (modalType === 'save') {
                handleNavigateBack();
            }
        }
    };
    const handleDeleteConfirm = async (data: any, deleteCancelCallback?: any): Promise<void> => {
        const { error }: any = await deleteWidget(data);
        if (!error) {
            dispatch(setMessageContent({ isOpen: true, message: t('WIDGETS:WIDGET_DELETE_SUCCESS') }));
            deleteCancelCallback();
        }
    };

    const handleRenameSave = async (widgetData: any, cancelCallback: any): Promise<void> => {
        const data: any = await updateWidget({ body: modalPayload?.payload, widgetId: widgetData?.id });
        if (data?.data?.success) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: t('WIDGETS:RENAME_WIDGET_MODAL.SUCCESS_MSG', {
                        replace: { widgetName: widgetData?.name },
                    }),
                })
            );
            dispatch(resetRenameWidgetModal());
            cancelCallback();
        }
    };

    return {
        payload: modalPayload?.payload,
        isLoading: isLoading || updateWidgetLoader,
        handleCloneSave,
        handleDeleteConfirm,
        handleRenameSave,
        handleRenameModalAction,
        handleCloneModalAction,
        handleDeleteModalAction,
    };
};
