import React from 'react';
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
// import Link from '@mui/material/Link';
import { ChannelData, useSortedData } from '../../../hooks/useSortedData';
import CustomIcon from '../../../components/CustomIcon';

import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import HelpCenter from '@mui/icons-material/HelpCenter';
import Refresh from '@mui/icons-material/Refresh';
import { WidgetFooter } from './WidgetFooter';
import { GroupPath } from 'components';
import { useTransformData } from 'hooks/useTransformData';
import { CustomEmptyState } from 'components/CustomEmptyState';
export const Component = (props: any): JSX.Element => {
    const { sortedData, ids } = useSortedData(props?.widgetData ?? [], ['primary', 'secondary']);
    const { convertDateTime, pointConversion } = useTransformData();

    const renderPrimaryData = (data: any): JSX.Element => (
        <Stack direction={'row'} justifyContent={'space-around'} className="border-bottom-2 padding-y-16">
            {data?.map((item: any) => (
                <Stack key={item?.channelId} justifyContent={'center'} alignItems={'center'}>
                    <CustomIcon
                        iconName={item?.icon?.web?.name}
                        family={item?.icon?.web?.family}
                        iconProps={{ sx: { fontSize: '40px' } }}
                        defaultIcon={<HelpCenter color="primary" sx={{ fontSize: '40px' }} />}
                    />
                    <Typography variant="body1" fontWeight={'600'}>
                        {`${pointConversion(item?.value) || '--'} ${item?.unit ?? '--'}`}
                    </Typography>
                    <Typography variant="body2" fontWeight={'400'} fontSize={'14px'}>
                        {item?.displayName ?? 'Channel'}
                    </Typography>
                    {item?.deviceName && (
                        <Typography variant="body2" fontWeight={'300'} fontSize={'14px'}>
                            {item?.deviceName}
                        </Typography>
                    )}
                </Stack>
            ))}
        </Stack>
    );

    const renderSecondaryData = (data: any): JSX.Element => {
        if (data?.length) {
            return data
                ?.slice(0, props?.widgetData?.secondaryChannelCount ?? sortedData?.secondary?.length)
                .map((item: ChannelData) => (
                    <ListItem
                        key={item?.channelId}
                        className="list-item-hover"
                        divider
                        secondaryAction={
                            <Typography variant="body1" fontWeight={'600'}>
                                {`${pointConversion(item?.value) || '--'} ${item?.unit ?? '--'}`}
                            </Typography>
                        }
                    >
                        <ListItemButton className="padding-8" role={undefined} dense>
                            <ListItemIcon className="width-24 height-24">
                                <CustomIcon
                                    iconName={item?.icon?.web?.name}
                                    family={item?.icon?.web?.family}
                                    defaultIcon={<HelpCenter color="primary" />}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1" fontWeight={'600'}>
                                        {item?.displayName ? item?.displayName : 'Secondary...'}
                                    </Typography>
                                }
                                secondary={
                                    props?.widgetData?.multiDevice && (
                                        <GroupPath
                                            pathName={item?.devicePath ?? []}
                                            deviceName={item?.deviceName}
                                            width="250px"
                                        />
                                    )
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                ));
        }
        return !sortedData?.primary ? (
            <CustomEmptyState
                icon={<FormatListBulleted fontSize={'inherit'} />}
                title={`No ${props?.widgetData?.widgetTypeId} Found`}
            />
        ) : (
            <></>
        );
    };

    const getTotalCount = (): number => sortedData?.primary?.length + sortedData?.secondary?.length;
    return (
        <Paper sx={{ height: '100%', overflow: 'auto' }}>
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                ml={1}
                sx={{
                    height: '48px',
                    ...(props?.mode === 'edit'
                        ? {
                              position: 'absolute',
                              top: '0px',
                              left: '50px',
                              width: 'calc(100% - 106px)',
                          }
                        : { padding: '16px', width: 'calc(100% - 8px)' }),
                }}
            >
                <Typography variant="subtitle2" color="primary" fontWeight={'600'} fontSize={'14px'}>
                    {props?.widgetData?.widgetName ?? '--'}
                    {props?.widgetData?.isTotalCountVisible && getTotalCount() ? (
                        <span>{`( ${getTotalCount()} )`}</span>
                    ) : (
                        <></>
                    )}
                </Typography>

                {Object.keys(props?.widgetData ?? {}).includes('primary') && (
                    <Stack direction={'row'} spacing={2} alignItems={'center'} ml={1}>
                        <Typography variant="subtitle2" fontWeight={'300'}>
                            Last Updated:{' '}
                            <b className="fw-600">
                                {convertDateTime({
                                    timestamp: Date.now(),
                                    customFormat: 'DD/MM/YYYY HH:mm',
                                })}
                            </b>
                        </Typography>
                        <IconButton sx={{ margin: '0px 16px !important' }} disabled={true}>
                            <Refresh />
                        </IconButton>
                    </Stack>
                )}
            </Stack>
            {props?.mode !== 'edit' && <Divider />}
            <Stack
                sx={{
                    height: `calc(100% - ${
                        props?.mode === 'edit' || props?.widgetData?.viewAllButton ? '97px' : '49px'
                    })`,
                    overflow: 'auto',
                }}
            >
                {sortedData?.primary &&
                    (sortedData?.primary?.length > 0 || sortedData?.secondary?.length > 0
                        ? renderPrimaryData(sortedData?.primary)
                        : renderPrimaryData(Array.from({ length: 3 })))}
                {sortedData?.secondary && (
                    <List disablePadding>
                        {sortedData?.secondary?.length > 0 || sortedData?.primary?.length > 0
                            ? renderSecondaryData(sortedData?.secondary)
                            : renderSecondaryData(
                                  Array.from({ length: Math.max(props?.widgetData?.secondaryChannelCount ?? 5) })
                              )}
                    </List>
                )}
            </Stack>
            <WidgetFooter
                mode={props?.mode}
                ids={ids}
                widgetData={props?.widgetData}
                disabled={!sortedData?.secondary?.length && !sortedData?.primary?.length}
            />
        </Paper>
    );
};
