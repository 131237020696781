import React from 'react';

import { createNumArray } from 'utils/helpers';
import { Divider, Skeleton, Typography } from '@mui/material';

export const PreviewNodeSkeleton = ({ total }: { total: number }): JSX.Element => (
    <>
        {createNumArray(total)?.map((item: any, index: any) => (
            <>
                <Skeleton key={item} animation="wave" sx={{ maxWidth: 'none', margin: '10px' }} height={25}>
                    <Typography variant="body2" className="border-bottom-1 bg-header" p={3}>
                        Preview Node
                    </Typography>
                </Skeleton>
                {index === 0 && <Divider />}
            </>
        ))}
    </>
);
