import React from 'react';
import LinkOff from '@mui/icons-material/LinkOff';
import Link from '@mui/icons-material/Link';
import { Box, Icon, Paper, Skeleton, Stack, Switch, Typography } from '@mui/material';
import { CustomEmptyState } from 'components/CustomEmptyState';

export const ConfigurationTabSkeleton = (): JSX.Element => (
    <Paper>
        <Box px={3} py={'14px'} className="border-bottom-1 flex-center-between bg-header">
            <Box className="align-items-center">
                <Skeleton variant="circular" sx={{ marginRight: '10px' }} height={28} width={28}>
                    <Icon>
                        <Link color="primary" />
                    </Icon>
                </Skeleton>
                <Skeleton animation="wave" variant="rounded">
                    <Typography color="primary" fontWeight={'600'} mx={1} fontSize={'14px'} variant="body2">
                        Link to specific group(s) or device(s)
                    </Typography>
                </Skeleton>
            </Box>
            <Stack direction={'row'} alignItems={'center'}>
                <Skeleton animation="wave" variant="circular" height={28} width={28}>
                    <Switch />
                </Skeleton>
            </Stack>
        </Box>

        <CustomEmptyState
            className="padding-y-96"
            icon={<LinkOff className="height-100 width-100" />}
            title={
                (
                    <Skeleton animation="wave" variant="rounded">
                        <Typography color="primary" fontWeight={'600'} mx={1} fontSize={'14px'} variant="body2">
                            Not linked to any group or device
                        </Typography>
                    </Skeleton>
                ) as any
            }
            description={
                <Skeleton animation="wave" variant="rounded" sx={{ marginTop: '15px' }}>
                    <Typography color="primary" fontWeight={'600'} mx={1} fontSize={'14px'} variant="body2">
                        This widget will display the devices according to the device or group dashboard
                    </Typography>
                </Skeleton>
            }
        />
    </Paper>
);
