import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';

type FormInputProps = {
    label: string;
};
const Label = ({ label }: FormInputProps): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Typography className={classes.label} variant={'subtitle1'}>
            {label}
        </Typography>
    );
};

export default Label;
