// import { CloudOffFilled } from '@brightlayer-ui/icons-mui';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { Box, Divider, Paper, Stack, Switch, Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSortedData } from 'hooks/useSortedData';
import { WidgetHeader } from 'pages/WidgetManagement/common/WidgetHeader';
import { WidgetFooter } from 'pages/WidgetManagement/common/WidgetFooter';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import { CustomTransComponent, GroupPath } from 'components';
import { CustomEmptyState } from 'components/CustomEmptyState';
const loadNumberStyle = {
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#727E84',
    color: '#fff',
    width: '18px',
    height: '18px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const Component = ({
    widgetData: detailWidget,
    mode,
    loadsHandler,
}: {
    widgetData: any;
    mode: string;
    loadsHandler: any;
}): JSX.Element => {
    const { sortedData, ids } = useSortedData(detailWidget ?? [], ['primary', 'secondary'], 'ASC', 'loads');
    const navigate = useNavigate();
    const theme: any = useTheme();
    return (
        <Paper sx={{ height: '100%' }}>
            <WidgetHeader mode={mode} widgetData={detailWidget} sortedData={sortedData} />
            {mode !== 'edit' && <Divider />}
            <Stack
                sx={{
                    height: `calc(100% - ${mode === 'edit' || detailWidget?.viewAllButton ? '97px' : '49px'})`,
                    overflow: 'auto',
                }}
            >
                {sortedData?.secondary?.length ? (
                    sortedData?.secondary
                        ?.slice(0, detailWidget?.maxCount ?? sortedData?.secondary?.length)
                        .map((item: any) => (
                            <InfoListItem
                                title={''}
                                key={item?.['loadNumber']}
                                chevron
                                divider="full"
                                onClick={(): void => {
                                    if (detailWidget?.detailRoute && mode === 'view')
                                        navigate(`${detailWidget?.detailRoute}/${item?.deviceId || ''}/${item.loadId}`);
                                }}
                                leftComponent={
                                    <>
                                        <Stack className="white-space-pre">
                                            <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#424E54' }}>
                                                {item?.['loadName']}
                                            </Typography>
                                            {detailWidget?.multiDevice && (
                                                <GroupPath
                                                    deviceName={item?.deviceName}
                                                    pathName={item?.groupPath}
                                                    width={'220px'}
                                                />
                                            )}
                                        </Stack>
                                    </>
                                }
                                icon={<Box sx={loadNumberStyle}>{item?.['loadNumber']}</Box>}
                                rightComponent={
                                    <Switch
                                        checked={item?.isLoadEnabled}
                                        disabled={item?.offlineDevice || mode !== 'view'}
                                        onClick={(e: any): void => {
                                            e?.stopPropagation();
                                            loadsHandler?.({
                                                isLoadEnabled: e?.target?.checked,
                                                loadId: item?.loadId,
                                                deviceId: item?.deviceId,
                                            });
                                        }}
                                        sx={{
                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                color: theme?.palette?.primary?.main,
                                                '& + .MuiSwitch-track': {
                                                    backgroundColor: theme?.palette?.primary?.main,
                                                },
                                            },
                                            '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
                                                color: theme?.palette?.primary?.[50],
                                                '& + .MuiSwitch-track': {
                                                    backgroundColor: theme?.palette?.primary?.[200],
                                                },
                                            },
                                        }}
                                    />
                                }
                            />
                        ))
                ) : (
                    <CustomEmptyState
                        className="padding-y-96"
                        icon={<FormatListBulleted className="height-76 width-76 color-icon" />}
                        title={(<CustomTransComponent translationKey={'WIDGETS:LOADS.NO_LOADS_FOUND'} />) as any}
                    />
                )}
            </Stack>
            <WidgetFooter mode={mode} widgetData={detailWidget} ids={ids} disabled={!sortedData?.secondary?.length} />
        </Paper>
    );
};

export default Component;
