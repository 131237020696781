import { useEffect, useState, useCallback, useRef } from 'react';

export const useIdleLogoutHandler = (timeOutInterval: number, isEnabled: boolean, onIdle?: any): any => {
    const [isIdle, setIsIdle] = useState(false);
    const lastInteractionTime = useRef<any>(Date.now());
    const timerRef: any = useRef(null);
    const resetTimer = useCallback(() => {
        if (isEnabled) {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                const currentTime = Date.now();
                const diff = currentTime - lastInteractionTime?.current;
                if (diff >= timeOutInterval) {
                    if (onIdle) onIdle();
                    setIsIdle(true);
                }
            }, timeOutInterval);
        }
    }, [timeOutInterval, onIdle, lastInteractionTime?.current, isEnabled]);
    const eventHandler = useCallback(() => {
        lastInteractionTime.current = Date.now();
        setIsIdle(false);
        resetTimer();
    }, []);
    useEffect(() => {
        const events = ['click', 'keypress', 'dblclick'];
        events.forEach((eventName) => window.addEventListener(eventName, eventHandler));
        resetTimer();
        return () => {
            events.forEach((eventName) => window.removeEventListener(eventName, eventHandler));
            clearTimeout(timerRef.current);
        };
    }, [isEnabled]);
    return isIdle;
};
