import React from 'react';
import Input from './FormElements/Input';
import Select from './FormElements/Select';
import Label from './FormElements/Label';

type Field = {
    type: string;
    id: string;
    label: string;
    value: string;
    options: any;
    rules: any;
    fieldLimit: number;
    defaultValue: any;
    helperText: string;
    disabled: boolean;
};
export type Props = {
    field: Field;
    control: any;
};
const Element: React.FC<Props> = ({
    field: { type, id, label, options, rules, fieldLimit, defaultValue, helperText, disabled },
    control,
}) => {
    switch (type) {
        case 'text':
            return (
                <Input
                    name={id}
                    label={label}
                    id={id}
                    control={control}
                    rules={rules}
                    fieldLimit={fieldLimit}
                    helperText={helperText}
                    defaultValue={defaultValue}
                    disabled={disabled}
                />
            );
        case 'select':
            return (
                <Select
                    id={id}
                    name={id}
                    label={label}
                    options={options}
                    control={control}
                    rules={rules}
                    defaultValue={defaultValue}
                    disabled={disabled}
                />
            );
        case 'label':
            return <Label label={label} />;

        default:
            return null;
    }
};

export default Element;
