import { CircularProgress } from '@mui/material';
import React, { memo } from 'react';

/**
 * The above type defines the props for a Loader component in a TypeScript React application.
 * @property {number} size - The `size` property is an optional number that can be passed to the
 * `Loader` component. It determines the size of the loader.
 */
type LoaderProps = {
    size?: number;
    className?: string;
};

/* The code is exporting a React component called `Loader`. The component is defined as a memoized
functional component, which means it will only re-render if its props change. */
export const Loader = memo(
    (props: LoaderProps): JSX.Element => (
        <div className={props.className ?? 'loader-container'}>
            <CircularProgress size={props?.size ?? 96} />
        </div>
    )
);
