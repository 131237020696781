import { api } from '../../app/api/baseApi';

export const dashboardApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllDashboards: builder.query<object, any>({
            query: ({ body, headers }: any) => ({
                url: 'ds/v1/dashboards/filter',
                method: 'POST',
                ...(headers && { headers }),
                body,
            }),
            providesTags: ['Dashboards'],
        }),
        deleteDashboard: builder.mutation({
            query: (id) => ({
                url: `ds/v1/dashboards/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['Dashboards'] : []),
        }),
        createDashboard: builder.mutation({
            query: (body) => ({
                url: 'ds/v1/dashboards',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Dashboards'] : []),
        }),
        updateDashboard: builder.mutation({
            query: ({ body, params }: any) => ({
                url: `ds/v1/dashboards/${params?.dashboardId}`,
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Dashboards', 'DashboardById'] : []),
        }),
        addWidgetsToDashboard: builder.mutation({
            query: (body) => ({
                url: 'ds/v1/dashboards/widgets',
                method: 'POST',
                body: body,
            }),
        }),
        getDashboardById: builder.query<object, any>({
            query: (dashboardId) => ({
                url: `ds/v1/dashboards/${dashboardId}`,
            }),
            keepUnusedDataFor: 0.000001,
            providesTags: ['DashboardById'],
        }),
        getDashboardsByWidgetId: builder.mutation({
            query: (id) => ({
                url: `ds/v1/dashboards/widget/${id}`,
            }),
        }),
        associateDashboards: builder.mutation({
            query: ({ body, headers }: any) => ({
                url: 'ds/v1/dashboards/associate',
                method: 'POST',
                ...(headers && { headers }),
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Dashboards'] : []),
        }),
    }),
});

export const {
    useCreateDashboardMutation,
    useGetDashboardByIdQuery,
    useGetAllDashboardsQuery,
    useDeleteDashboardMutation,
    useUpdateDashboardMutation,
    useAddWidgetsToDashboardMutation,
    useGetDashboardsByWidgetIdMutation,
    useLazyGetAllDashboardsQuery,
    useAssociateDashboardsMutation,
    useLazyGetDashboardByIdQuery,
} = dashboardApi;
