import { api } from '../../app/api/baseApi';

const localizationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTranslationsByCategory: builder.query<object, any>({
            query: ({ body, locale }) => ({
                url: `localization/v1/messages`,
                params: { category: body },
                method: 'GET',
                ...(locale && {
                    headers: {
                        locale: locale,
                    },
                }),
            }),
        }),
        getOperTranslations: builder.query({
            query: () => ({
                url: 'localization/v1/common/messages/login',
                method: 'GET',
                headers: {
                    locale: 'en',
                },
            }),
        }),
    }),
});

export const { useGetTranslationsByCategoryQuery, useLazyGetTranslationsByCategoryQuery, useGetOperTranslationsQuery } =
    localizationApi;
