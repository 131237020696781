import { Box, Stack, Typography } from '@mui/material';
import { SnackbarContent } from 'notistack';
import React from 'react';

const NotificationWithIcon = React.forwardRef<HTMLDivElement, any>((props, ref) => {
    const { id, message, Icon, subtitle, notificationAction, notificationWithAction, ...other } = props;
    React.useEffect(() => {
        if (other?.setId) {
            other?.setId?.(id);
        }
    }, [id]);

    return (
        <SnackbarContent ref={ref} {...other} className="notistack-with-icon">
            <Box className="notistack-text-wrapper">
                <Box className="notistack-icon">{Icon}</Box>
                <Stack direction={'column'}>
                    <Typography className="notistack-title">{message}</Typography>
                    <Typography className="notistack-subtitle">{subtitle}</Typography>
                </Stack>
                {notificationWithAction && notificationAction(id)}
            </Box>
        </SnackbarContent>
    );
});

export default NotificationWithIcon;
