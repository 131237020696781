import { useTheme } from '@mui/material';
import React from 'react';

export const DefaultPlaceholderRender: React.FC<any> = (props) => {
    const theme: any = useTheme();
    return (
        <div
            style={{
                left: props.depth * 48,
                backgroundColor: theme?.palette?.primary?.[500],
                height: '2px',
                position: 'absolute',
                right: 0,
                transform: 'translateY(-50%)',
                top: 0,
            }}
            data-testid="placeholder"
            className={'placeholderContainer'}
        ></div>
    );
};
