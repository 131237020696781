/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react';
import { CustomModal, CustomTransComponent, DeleteModal, GroupPath } from '../../../components';
import { useConfirm } from '@fiji/common/src/hooks';
import { Avatar, List, Stack, Typography } from '@mui/material';
import Dashboard from '@mui/icons-material/Dashboard';
import WarningAmber from '@mui/icons-material/WarningAmber';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { ModalActions } from '../../../components/CustomModal';
import { useGetDashboardsByWidgetIdMutation } from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import { useHandleWidgetListActions } from '../common';

export const DeleteWidgetModal = React.forwardRef((props: any, ref: any): JSX.Element => {
    const [, { data: dashboards }] = useGetDashboardsByWidgetIdMutation({
        fixedCacheKey: 'dashBoardsByWidgetId',
    });
    const { handleDeleteConfirm: handleConfirm, handleDeleteModalAction: handleModalAction } =
        useHandleWidgetListActions(
            '',
            (data: any) => onClick(data),
            (args: any) => deleteModalClick(args)
        );

    const {
        isVisible: deleteModalOpen,
        onClick: deleteModalClick,
        onConfirm: deleteSuccess,
        onCancel: deleteCancel,
    } = useConfirm((args: any): any => handleConfirm(args, deleteCancel));
    const { data, isVisible, onCancel, onClick } = useConfirm();
    const deleteModalActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                onCancel();
            },
        },
        {
            key: 'continue',
            label: <CustomTransComponent translationKey={'COMMON:CONTINUE'} />,
            variant: 'contained',
            handleClick: (): void => {
                onCancel();
                deleteModalClick(data);
            },
        },
    ];

    React.useImperativeHandle(ref, () => ({
        handleModalAction: (action: boolean, widgetData: any): void => handleModalAction(action, widgetData),
    }));

    return (
        <>
            <CustomModal
                isOpen={isVisible}
                isLoading={false}
                type="primary"
                actions={deleteModalActions}
                customHeader={
                    <>
                        <Stack>
                            <Typography variant="body1" fontSize={'20px'} fontWeight={'600'} mb={2}>
                                <CustomTransComponent translationKey={'WIDGETS:DELETE_WIDGET_MODAL.HEADER'} />
                            </Typography>
                            <Avatar className="bg-red-50 height-96 width-96 margin-auto">
                                <WarningAmber className="color-danger height-56 width-56" />
                            </Avatar>
                        </Stack>
                        <Stack mt={2}>
                            <Typography variant="body1" fontWeight={'700'}>
                                <CustomTransComponent
                                    translationKey={'WIDGETS:DELETE_WIDGET_MODAL.WARN_TEXT_1'}
                                    replace={{
                                        count: dashboards?.data?.length,
                                    }}
                                />
                            </Typography>
                            <Typography variant="body1" fontWeight={'400'} mt={2}>
                                <CustomTransComponent translationKey={'WIDGETS:DELETE_WIDGET_MODAL.WARN_TEXT_2'} />
                            </Typography>
                        </Stack>
                    </>
                }
                noDividers
                actionsDivider
            >
                <List className="height-200 overflow-y-scroll">
                    {dashboards?.data?.map((dashboard: any) => (
                        <InfoListItem
                            key={dashboard?.id}
                            title={dashboard?.name}
                            subtitle={(<GroupPath pathName={dashboard?.path} />) as any}
                            icon={<Dashboard className="height-24 width-24" />}
                        />
                    ))}
                </List>
            </CustomModal>
            <DeleteModal
                header={<CustomTransComponent translationKey={'WIDGETS:DELETE_WIDGET_MODAL.HEADER'} />}
                deleteButtonText={'WIDGETS:DELETE_WIDGET_MODAL.BTN_TITLE'}
                description={
                    <Stack py={2}>
                        <CustomTransComponent translationKey={'WIDGETS:DELETE_WIDGET_MODAL.WARN_TEXT_3'} />
                    </Stack>
                }
                isVisible={deleteModalOpen}
                onCancel={deleteCancel}
                confirmClick={deleteSuccess}
            />
        </>
    );
});
