// eslint-disable-next-line @typescript-eslint/naming-convention
import React from 'react';

/**
 * The `useSelectedIds` function is a custom hook in TypeScript that manages a list of selected IDs and
 * provides functions to update the list based on user selection.
 * @returns The function `useSelectedIds` returns an array containing three elements: `selectedIds`,
 * `setSelectedIds`, and `selectionHandler`.
 */
export const useSelectedIds = (): any[] => {
    const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
    const [selectedData, setSelectedData] = React.useState<any>([]);

    /* The `selectionHandler` function is a callback function that is created using the
  `React.useCallback` hook. It takes two parameters: `id` (a string) and `e` (a
  `React.ChangeEvent<HTMLInputElement>` event). */
    const selectionHandler = React.useCallback(
        (id: string, data?: any) =>
            (e: React.ChangeEvent<HTMLInputElement>): void => {
                const selectedIdsClone = JSON.parse(JSON.stringify({ ids: selectedIds }));
                const selectedDataClone = JSON.parse(JSON.stringify(selectedData));
                const uncheckedIndex = selectedIdsClone.ids.indexOf(id);
                const uncheckedIndexOfData = selectedIdsClone.ids.indexOf(id);
                if (e.target.checked) {
                    selectedIdsClone.ids.push(id);
                    selectedDataClone.push(data);
                }
                if (!e.target.checked) {
                    selectedIdsClone.ids.splice(uncheckedIndex, 1);
                    selectedDataClone.splice(uncheckedIndexOfData, 1);
                }
                setSelectedData(selectedDataClone);
                setSelectedIds(selectedIdsClone.ids);
            },
        [selectedIds]
    );

    return [selectedIds, setSelectedIds, selectionHandler, selectedData, setSelectedData];
};
