import React, { useState, createContext } from 'react';
import { NodeModel, PlaceholderState } from '../types';

export const PlaceholderContext = createContext<PlaceholderState>({} as PlaceholderState);

const initialState = {
    dropTargetId: undefined,
    dropTargetName: undefined,
    index: undefined,
};

export const PlaceholderProvider: React.FC<{ children: React.ReactNode }> = (props) => {
    const [dropTargetId, setDropTargetId] = useState<PlaceholderState['dropTargetId']>(initialState.dropTargetId);
    const [dropTargetName, setDropTargetName] = useState<PlaceholderState['dropTargetName']>(
        initialState.dropTargetName
    );
    const [index, setIndex] = useState<PlaceholderState['index']>(initialState.index);

    const showPlaceholder = (targetId: NodeModel['id'], itemIndex: number, targetName: string): void => {
        setDropTargetId(targetId);
        setDropTargetName(targetName);
        setIndex(itemIndex);
    };

    const hidePlaceholder = (): void => {
        setDropTargetId(initialState.dropTargetId);
        setDropTargetName(initialState.dropTargetName);
        setIndex(initialState.index);
    };

    return (
        <PlaceholderContext.Provider
            value={{
                dropTargetId,
                dropTargetName,
                index,
                showPlaceholder,
                hidePlaceholder,
            }}
        >
            {props.children}
        </PlaceholderContext.Provider>
    );
};
