import React from 'react';

import { useTheme } from '@mui/material/styles';
import { CloneWidgetModal } from '../WidgetList';
import { useWidgetConfigHandlers } from '../common';
import { AppBar, Button, CircularProgress, Stack, Toolbar } from '@mui/material';
import { CustomTransComponent } from 'components';

export const WidgetConfigurationHeader = (): JSX.Element => {
    const saveAsModalRef = React.useRef<any>(null);
    const theme: any = useTheme();
    const {
        handleSaveButton,
        handleCloseButton,
        updateLoader,
        details: { widgetDetails },
    } = useWidgetConfigHandlers();

    return (
        <>
            <AppBar position={'static'}>
                <Toolbar className="bg-white text-black padding-5">
                    <Stack className="w-100">
                        <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'space-between'}>
                            <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} width={'100%'}>
                                <Button
                                    sx={{
                                        border: `1px solid ${theme?.palette?.primary?.main}`,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.[50],
                                        },
                                    }}
                                    variant="outlined"
                                    onClick={handleCloseButton}
                                >
                                    {<CustomTransComponent translationKey={'COMMON:CLOSE'} />}
                                </Button>
                                <Button
                                    sx={{
                                        border: `1px solid ${theme?.palette?.primary?.main}`,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.[50],
                                        },
                                    }}
                                    variant="outlined"
                                    onClick={(): void => {
                                        saveAsModalRef?.current?.handleModalAction(true, widgetDetails);
                                    }}
                                >
                                    <CustomTransComponent translationKey={'WIDGETS:SAVE_AS_LABEL'} />
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor: theme?.palette?.primary?.main,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.main,
                                        },
                                    }}
                                    {...(updateLoader && {
                                        startIcon: <CircularProgress color="inherit" size={20} />,
                                    })}
                                    variant="contained"
                                    disabled={updateLoader}
                                    onClick={handleSaveButton}
                                >
                                    {updateLoader ? (
                                        'Saving...'
                                    ) : (
                                        <CustomTransComponent translationKey={'COMMON:SAVE_LABEL'} />
                                    )}
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>

            <CloneWidgetModal ref={saveAsModalRef} modalType="save" />
        </>
    );
};
