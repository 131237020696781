import { api } from '../../app/api/baseApi';
import { PAGINATION_PAYLOAD } from '../../constants/paginationQuery';
export const tagManagementApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTags: builder.query<any, any>({
            query: (body) => {
                return {
                    url: `dm/v1/tags/filter`,
                    method: 'POST',
                    body,
                };
            },
            providesTags: ['DeviceTags'],
        }),
        getMobileTags: builder.query<any, any>({
            query: (body) => {
                const payloadClone = JSON.parse(JSON.stringify(body));
                if (payloadClone.pullRefresh) {
                    delete payloadClone.pullRefresh;
                }
                return {
                    url: `dm/v1/tags/filter`,
                    method: 'POST',
                    body: payloadClone,
                };
            },
            ...PAGINATION_PAYLOAD,
        }),
        getTagInfo: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `dm/v1/tags/${id}`,
                    method: 'GET',
                };
            },
        }),
        createTag: builder.mutation({
            query: (body) => ({
                url: 'dm/v1/tags',
                method: 'POST',
                body,
            }),

            invalidatesTags: (result, error): any => (!error ? ['DeviceTags'] : []),
        }),
        editTag: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `dm/v1/tags/${id}`,
                    method: 'PATCH',
                    body,
                };
            },
            invalidatesTags: (result, error) => (!error ? ['DeviceTags'] : []),
        }),

        deleteTag: builder.mutation({
            query: ({ body }: any) => ({
                url: `/dm/v1/tags`,
                method: 'DELETE',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['DeviceTags'] : []),
        }),
        getAssociatedDeviceById: builder.query({
            query: (id) => ({
                url: `/dm/v1/tags/devices/${id}`,
                method: 'GET',
            }),
        }),
        updateMultipleTags: builder.mutation({
            query: (body) => {
                return {
                    url: 'dm/v1/tags/device-tag-mapping',
                    method: 'PATCH',
                    body,
                };
            },
            invalidatesTags: (result, error): any => (!error ? ['DeviceList'] : []),
        }),
        getTagsList: builder.mutation({
            query: (body) => {
                return {
                    url: `dm/v1/tags/filter`,
                    method: 'POST',
                    body,
                };
            },
        }),
    }),
});

export const {
    useGetTagsQuery,
    useGetMobileTagsQuery,
    useGetTagInfoQuery,
    useCreateTagMutation,
    useEditTagMutation,
    useDeleteTagMutation,
    useGetAssociatedDeviceByIdQuery,
    useUpdateMultipleTagsMutation,
    useGetTagsListMutation,
} = tagManagementApi;
