import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState: any = {
    data: undefined,
    type: 'solidGauge',
    viewScale: false,
    viewThreshold: false,
};

const scoreWidgetSlice = createSlice({
    name: 'score',
    initialState: initialState,
    reducers: {
        resetScoreConfiguration: () => {
            return initialState;
        },
        setPredefinedScore: (state: RootState, action: PayloadAction<any>) => {
            state.data = action.payload.data;
        },
        setScore: (state: RootState, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
        removeScoreParameter: (state: RootState) => {
            state.data = {};
        },
        setScorePreferences: (state: RootState, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
    },

    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (action.payload.reset) state.data = { ...(state.data ?? {}), deviceId: action.payload.data.id };
        });
    },
});

export const { removeScoreParameter, resetScoreConfiguration, setPredefinedScore, setScorePreferences, setScore } =
    scoreWidgetSlice.actions;

export default scoreWidgetSlice;
