import React from 'react';
import Download from '@mui/icons-material/Download';
import Send from '@mui/icons-material/Send';
import { Avatar, Card, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MultiInput from './MultiInput';
import { t } from 'i18next';
import { CustomTransComponent } from 'components';

export const addRemoveArrayHandler = (key: string, arr: any[]): any => {
    const newArr = [...(arr ?? [])];
    const index: any = arr?.indexOf?.(key);
    if (index !== -1) {
        newArr?.splice(index, 1);
    } else {
        newArr?.push(key);
    }
    return newArr;
};

export const ExportMethod = ({ exportPayload, setExportPayload }: any): JSX.Element => {
    const theme: any = useTheme();
    const [value, setValue] = React.useState('');

    const handleKeyDown = (e: any): void => {
        if (e.code === 'Enter' && exportPayload?.emails?.length < 6) {
            const tagValue = e.target.value;
            setExportPayload((prev: any) => ({
                ...prev,
                emails: [...(prev?.emails ?? []), tagValue],
            }));
            setValue('');
        }
    };

    const handleTagDelete = (index: number): void => {
        const exportPayloadClone = JSON.parse(JSON.stringify(exportPayload ?? {}));
        exportPayloadClone?.emails?.splice(index, 1);
        setExportPayload(exportPayloadClone);
    };

    return (
        <Stack mt={4} spacing={4}>
            <Stack>
                <Typography variant="h6" fontSize={'20px'} fontWeight={'600'}>
                    {t('TRENDS:ADVANCED_EXPORT.CHOOSE_EXPORT_METHOD')}
                </Typography>
                <Typography variant="body1">{t('TRENDS:ADVANCED_EXPORT.CHOOSE_EXPORT_METHOD_DESCRIPTION')}</Typography>
            </Stack>

            <Stack className="w-100">
                <Stack direction={'row'} alignItems={'center'} className="w-40 margin-auto" spacing={6}>
                    <Card
                        className="download-sendByEmail cursor-pointer"
                        sx={{
                            border: `1px solid ${
                                exportPayload?.types?.includes?.('DOWNLOAD')
                                    ? theme?.palette?.primary?.main
                                    : 'transparent'
                            }`,
                        }}
                        onClick={(): void =>
                            setExportPayload((prev: any) => ({
                                ...prev,
                                types: addRemoveArrayHandler('DOWNLOAD', exportPayload?.types),
                            }))
                        }
                    >
                        <Stack direction={'row'} alignItems={'center'} spacing={3}>
                            <Avatar
                                className="height-80 width-80"
                                sx={{
                                    backgroundColor: exportPayload?.types?.includes?.('DOWNLOAD')
                                        ? theme?.palette?.primary?.main
                                        : '#fff',
                                }}
                            >
                                <Download
                                    className={`height-40 width-40 ${
                                        exportPayload?.types?.includes?.('DOWNLOAD') ? 'text-white' : 'color-content'
                                    }`}
                                />
                            </Avatar>
                            <Typography variant="subtitle1" fontSize={'16px'} fontWeight={'600'}>
                                {t('COMMON:DOWNLOAD')}
                            </Typography>
                        </Stack>
                    </Card>
                    <Card
                        className="download-sendByEmail cursor-pointer"
                        sx={{
                            border: `1px solid ${
                                exportPayload?.types?.includes?.('EMAIL')
                                    ? theme?.palette?.primary?.main
                                    : 'transparent'
                            }`,
                        }}
                        onClick={(): void =>
                            setExportPayload((prev: any) => ({
                                ...prev,
                                types: addRemoveArrayHandler('EMAIL', exportPayload?.types),
                            }))
                        }
                    >
                        <Stack direction={'row'} alignItems={'center'} spacing={3}>
                            <Avatar
                                className="height-80 width-80"
                                sx={{
                                    backgroundColor: exportPayload?.types?.includes?.('EMAIL')
                                        ? theme?.palette?.primary?.main
                                        : '#fff',
                                }}
                            >
                                <Send
                                    className={`height-40 width-40 ${
                                        exportPayload?.types?.includes?.('EMAIL') ? 'text-white' : 'color-content'
                                    }`}
                                />
                            </Avatar>
                            <Typography variant="subtitle1" fontSize={'16px'} fontWeight={'600'}>
                                <CustomTransComponent translationKey={'COMMON:SEND_BY_MAIL'} />
                            </Typography>
                        </Stack>
                    </Card>
                </Stack>
            </Stack>

            {exportPayload?.types?.includes?.('EMAIL') && (
                <Stack className=" bg-content-wrapper" p={3} spacing={2}>
                    <Typography variant="subtitle1" fontWeight={'600'} fontSize={'16px'}>
                        {t('TRENDS:ADVANCED_EXPORT.RECIPIENTS_COUNT', {
                            replace: {
                                count: exportPayload?.emails?.length,
                            },
                        })}
                    </Typography>
                    <MultiInput
                        value={exportPayload?.emails ?? []}
                        handleKeyDown={handleKeyDown}
                        inputValue={value}
                        setInputValue={setValue}
                        placeholder={t('COMMON:EMAIL_ADDRESS')}
                        isDisabled={false}
                        handleTagDelete={handleTagDelete}
                    />
                </Stack>
            )}
        </Stack>
    );
};
