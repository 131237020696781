import { InfoListItem } from '@brightlayer-ui/react-components';
import { Button, Divider, Paper, Stack, Typography } from '@mui/material';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useSortedData } from 'hooks/useSortedData';
import { WidgetHeader } from 'pages/WidgetManagement/common/WidgetHeader';
import { WidgetFooter } from 'pages/WidgetManagement/common/WidgetFooter';
import Add from '@mui/icons-material/Add';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { useTheme } from '@mui/material/styles';
import { CustomEmptyState } from 'components/CustomEmptyState';
import { CustomTransComponent } from 'components';
const Component = ({
    widgetData: virtualLoadsWidget,
    mode,
    addVirtualLoad,
}: {
    widgetData: any;
    mode: string;
    addVirtualLoad: any;
}): JSX.Element => {
    const { sortedData, ids } = useSortedData(virtualLoadsWidget ?? [], ['secondary'], 'ASC', 'loads');
    const navigate = useNavigate();
    const theme: any = useTheme();

    return (
        <Paper sx={{ height: '100%', overflow: 'scroll' }}>
            <WidgetHeader mode={mode} widgetData={virtualLoadsWidget} sortedData={sortedData} />
            {mode !== 'edit' && <Divider />}
            <Stack
                sx={{
                    height: `calc(100% - ${mode === 'edit' || virtualLoadsWidget?.viewAllButton ? '97px' : '49px'})`,
                    overflow: 'auto',
                }}
            >
                {sortedData?.secondary?.length ? (
                    <>
                        <Stack>
                            {sortedData?.secondary
                                ?.slice(0, virtualLoadsWidget?.maxCount ?? sortedData?.secondary?.length)
                                .map((item: any) => (
                                    <InfoListItem
                                        title={
                                            <Stack className="white-space-pre">
                                                <Typography
                                                    sx={{ fontSize: '16px', fontWeight: '600', color: '#424E54' }}
                                                >
                                                    {item?.['name']}
                                                </Typography>
                                            </Stack>
                                        }
                                        hidePadding
                                        key={item?.id}
                                        chevron
                                        divider="full"
                                        onClick={(): void => {
                                            if (virtualLoadsWidget?.detailRoute && mode === 'view')
                                                navigate(`${virtualLoadsWidget?.detailRoute}/${item.id}`);
                                        }}
                                    />
                                ))}
                        </Stack>
                        <InfoListItem
                            key={'delete'}
                            hidePadding
                            divider="full"
                            title={
                                <Button
                                    disabled={mode === 'preview' || mode === 'edit' || !addVirtualLoad}
                                    sx={{
                                        border: `1px solid ${theme?.palette?.primary?.main}`,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.[50],
                                            color: theme?.palette?.primary?.main,
                                        },
                                        width: '100%',
                                    }}
                                    variant={'outlined'}
                                    color={'primary'}
                                    startIcon={<Add />}
                                    onClick={addVirtualLoad}
                                >
                                    <CustomTransComponent translationKey={'WIDGETS:LOADS.ADD_VIRTUAL_LOAD'} />
                                </Button>
                            }
                        />
                    </>
                ) : (
                    <CustomEmptyState
                        icon={<ErrorOutline fontSize={'inherit'} />}
                        title={<CustomTransComponent translationKey={'WIDGETS:LOADS.NO_VIRTUAL_LOADS'} />}
                        className="padding-y-96"
                        description={<CustomTransComponent translationKey={'WIDGETS:LOADS.NO_VIRTUAL_LOADS_DESC'} />}
                        actions={
                            <Button
                                disabled={!addVirtualLoad}
                                sx={{
                                    border: `1px solid ${theme?.palette?.primary?.main}`,
                                    '&:hover': {
                                        backgroundColor: theme?.palette?.primary?.[50],
                                        color: theme?.palette?.primary?.main,
                                    },
                                }}
                                variant={'outlined'}
                                color={'primary'}
                                startIcon={<Add />}
                                onClick={addVirtualLoad}
                            >
                                <CustomTransComponent translationKey={'WIDGETS:LOADS.ADD_VIRTUAL_LOAD'} />
                            </Button>
                        }
                    />
                )}
            </Stack>
            <WidgetFooter
                mode={mode}
                widgetData={virtualLoadsWidget}
                ids={ids}
                disabled={!sortedData?.secondary?.length}
            />
        </Paper>
    );
};

export default Component;
