import React from 'react';
import * as MUIIcons from '@mui/icons-material';
import * as BLUIIcons from '@brightlayer-ui/icons-mui';
import { Box } from '@mui/material';
type CustomIconProps = {
    family: string;
    iconName: string;
    iconProps?: any;
    defaultIcon?: any;
};

const CustomIcon = (props: CustomIconProps): JSX.Element => {
    const getIconFamily = (family: string): any => {
        switch (family) {
            case 'blui': {
                return BLUIIcons;
            }
            default: {
                return MUIIcons;
            }
        }
    };

    return (
        <>
            {props?.family === 'loads' ? (
                <Box sx={{ ...props.iconProps?.sx }}>{props.iconProps?.loadNumber}</Box>
            ) : getIconFamily(props.family)?.[props.iconName] ? (
                React.createElement(getIconFamily(props.family)[props.iconName], { ...props?.iconProps })
            ) : (
                props?.defaultIcon ?? <MUIIcons.HelpCenter {...props?.iconProps} />
            )}
        </>
    );
};

export default React.memo(CustomIcon);
