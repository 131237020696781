import React from 'react';
import { IconButton, Box, Toolbar, Typography, Skeleton } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { AppBar, Spacer, ThreeLiner } from '@brightlayer-ui/react-components';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { GroupPath, HeaderActions } from './';
import clsx from 'clsx';
import { Route } from '../router/routes';
import { useLocation } from 'react-router-dom';
import { RootState, useTypedSelector } from '@fiji/common/src/app/store';
import backgroundImage from '../assets/images/collapsible_app_bar.jpg';
import { useNavigationBack } from 'hooks';

export const useStyles = makeStyles((theme: Theme) => ({
    title: {},
    subtitle: {},
    info: {},
    liner: {
        top: 0,
        position: 'absolute',
        flexGrow: 1,
        marginLeft: 56,
        [theme.breakpoints.down('md')]: {
            marginLeft: 56,
        },
    },
    toolbarRightItem: {
        marginRight: theme.spacing(3),
    },
    expanded: {
        '& $liner': {
            top: 80,
        },
    },
    collapsed: {
        '& $title': {
            fontSize: '1.25rem',
            fontWeight: 600,
        },
        '& $subtitle': {
            fontSize: 0,
        },
        '& $info': {
            fontSize: '1rem',
            marginTop: '-0.25rem',
            fontWeight: 400,
        },
        '& $backgroundGradient': {
            backgroundImage: 'none !important',
        },
    },
    collapsedUpcoming: {
        '& $title': {
            fontSize: '1.25rem',
            fontWeight: 600,
        },
        '& $info': {
            display: 'none',
        },
        '& $backgroundGradient': {
            backgroundImage: 'none !important',
        },
    },
    userMenuButton: {
        // height:'100% !important',
    },
    toolbarGutters: {
        paddingLeft: 16,
        paddingRight: 16,
        minHeight: '56px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '56px',
        },
    },
    normalGradient: {
        background: `rgba(0, 123, 193)  !important`,
    },
    warningGradient: {
        background: `rgba(244,119,33) !important`,
    },
    alertGradient: {
        background: `rgba(200, 60, 61) !important`,
    },
    offlineGradient: {
        background: `rgba(105,177,195)  !important`,
    },
    snoozeGradient: {
        background: ` #983FEF !important`,
    },
    backGroundGradient: {
        opacity: '0.1!important',
    },
    backGroundGradientServicePortal: {
        backgroundColor: '#DD6010 !important',
    },
    toolbarRightContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

/* The code defines a functional component called `AppCollapsibleHeader`. It takes in a `props` object
with two properties: `page` and `nestedRoutesList`. */
export const AppCollapsibleHeader = (props: { page: JSX.Element; nestedRoutesList: Route[] }): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { page } = props;
    const location = useLocation();
    const currentRealmName = useTypedSelector((state: RootState) => state.common.selectedRealm);

    const data = useTypedSelector((state: any) => state?.common.selectedNode);
    const isLoading = useTypedSelector((state: any) => state?.common?.selectedNodeLoader);
    const snoozeIsVisible: boolean = useTypedSelector((state) => state.alarmtimeline.snoozeIsVisible);

    /**
     * The function `handleSubtitle` returns a string or JSX element that represents the status of the data.
     * If `isLoading` is true, it returns a skeleton loader with "Offline" text.
     * If `data?.isUpcomingServicePortal` is true, it returns the `data?.deviceName` or 'N/A' if `deviceName` is not available.
     * If `data?.deviceStatus` is not available, it returns "N/A".
     * If `data?.deviceStatus` is available and `data?.connectionStatus` is "Online", it returns "Online • {data.deviceStatus}".
     * Otherwise, it returns the `data?.deviceStatus`.
     *
     * @returns {string | JSX.Element} The status of the data or a skeleton loader.
     */
    const handleSubtitle = (): string | JSX.Element => {
        if (isLoading) {
            return (
                <Skeleton animation="wave">
                    <Typography variant="body2">Offline</Typography>
                </Skeleton>
            );
        }
        if (data?.isUpcomingServicePortal) {
            return data?.deviceName ?? 'N/A';
        }
        if (!data?.deviceStatus) {
            return `N/A`;
        } else if (data?.deviceStatus && data?.connectionStatus === 'Online') {
            return `Online • ${data?.deviceStatus ?? ''}`;
        }
        return data?.deviceStatus ?? '';
    };
    const navigationBack = useNavigationBack();
    const handleNavigateBack = (): void => {
        navigationBack();
    };

    return (
        <Box style={{ minHeight: '100vh' }}>
            <AppBar
                className={clsx({
                    [classes.normalGradient]: data?.deviceStatus === 'Normal',
                    [classes.offlineGradient]: data?.deviceStatus === 'Offline',
                    [classes.alertGradient]: data?.deviceStatus === 'Alarm',
                    [classes.warningGradient]: data?.deviceStatus === 'Warning',
                    [classes.snoozeGradient]: snoozeIsVisible === true || data?.deviceStatus === 'Snoozed',
                    [classes.backGroundGradientServicePortal]: data?.isUpcomingServicePortal,
                })}
                expandedHeight={260}
                collapsedHeight={56}
                scrollThreshold={136}
                animationDuration={300}
                backgroundImage={data?.image ?? backgroundImage}
                variant={'snap'}
                classes={{
                    collapsed: data?.isUpcoming ? classes.collapsedUpcoming : classes.collapsed,
                    expanded: classes.expanded,
                    background: classes.backGroundGradient,
                }}
                id="app-collapsible-header"
            >
                <Toolbar data-cy={'toolbar'} classes={{ gutters: classes.toolbarGutters }}>
                    {location.pathname !== `/${currentRealmName}` && (
                        <IconButton
                            data-cy="toolbar-menu"
                            color={'inherit'}
                            edge={'start'}
                            size="large"
                            onClick={handleNavigateBack}
                        >
                            <ArrowBack />
                        </IconButton>
                    )}
                    <Spacer />
                    <ThreeLiner
                        classes={{ title: classes.title, subtitle: classes.subtitle, info: classes.info }}
                        className={classes.liner}
                        title={
                            isLoading ? (
                                <Skeleton animation="wave">
                                    <Typography variant="h5">Devicename009218</Typography>
                                </Skeleton>
                            ) : data?.isUpcomingServicePortal ? (
                                data?.name
                            ) : (
                                data?.deviceName ?? data?.name ?? ''
                            )
                        }
                        subtitle={handleSubtitle()}
                        info={
                            isLoading ? (
                                <Skeleton animation="wave">
                                    <Typography variant="body2">{'group > path'}</Typography>
                                </Skeleton>
                            ) : (
                                <>
                                    {!data?.isUpcomingServicePortal ? (
                                        <>
                                            <GroupPath
                                                deviceName={data?.deviceName ?? data?.name}
                                                pathName={data?.groupPath ? [data.groupName, ...data.groupPath] : []}
                                            />
                                            {data?.note && (
                                                <Typography variant="body2">{`Note: ${data?.note}`}</Typography>
                                            )}
                                        </>
                                    ) : (
                                        data?.note && <Typography variant="body2">{`Note: ${data?.note}`}</Typography>
                                    )}
                                </>
                            )
                        }
                        animationDuration={300}
                    />
                    <Box className={classes.toolbarRightContent}>
                        <HeaderActions isUpcomingServicePortal={data?.isUpcomingServicePortal} />
                    </Box>
                </Toolbar>
            </AppBar>
            <Box>{page}</Box>
        </Box>
    );
};
