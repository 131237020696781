import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
// import { UserProfile } from '@fiji/common/src/types';
import moment from 'moment-timezone';

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const useTransformData = (): any => {
    const { data: profile } = useGetUserProfileQuery<{ data: any }>({});
    const convertDateTime = ({ timestamp, customFormat }: { timestamp: number; customFormat?: string }): any =>
        moment(timestamp)
            .tz(profile?.data?.timezone ?? localTimeZone)
            .format(customFormat ?? profile?.data?.dateFormat ?? 'DD/MM/YYYY');
    const pointConversion = (value: any): string => {
        let str = '';
        if (!isNaN(value)) str = value?.toString?.() ?? '';
        return str?.replace?.('.', profile?.data?.decimalSeparator?.[0] ?? ',');
    };
    return {
        convertDateTime,
        pointConversion,
        timezoneOffset: -moment.tz(profile?.data?.timezone ?? localTimeZone).utcOffset(),
    };
};
