import React from 'react';

import Mode from '@mui/icons-material/Mode';
import { GroupPath } from 'components';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { PreviewNodeSkeleton } from '../common/Skeletons';
import { useWidgetConfigHandlers } from '../common';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import { togglePreviewNodeModal } from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';
import { getWidgetTypeValidationConfig } from '../common/utils/helpers';

export const HierarchySelectionHeader = (): JSX.Element => {
    const configurationPayload = useTypedSelector((data: any) => data.widgetConfiguration);
    const dispatch = useAppDispatch();

    const {
        deviceError,
        details: { widgetDetails },
    } = useWidgetConfigHandlers();

    return !configurationPayload?.data?.multiDevice && widgetDetails?.widgetType?.id !== 'virtual_loads' ? (
        <Paper className="margin-bottom-16">
            {configurationPayload?.isLoading && (
                <span style={{ padding: '16px' }}>
                    <PreviewNodeSkeleton total={2} />
                </span>
            )}
            {!configurationPayload?.isLoading && (
                <>
                    <Typography
                        variant="body2"
                        textTransform={'uppercase'}
                        letterSpacing={2}
                        fontSize={'12px'}
                        fontWeight={'600'}
                        className="border-bottom-1 bg-header"
                        p={3}
                    >
                        {!getWidgetTypeValidationConfig(widgetDetails?.widgetType?.id)['isSourceRequired']
                            ? 'Preview Node'
                            : 'Preview Device'}
                    </Typography>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} p={2}>
                        {deviceError || (configurationPayload?.source && !configurationPayload?.device?.id) ? (
                            <Typography variant="subtitle1">No Device For The Selected Source Exists</Typography>
                        ) : (
                            <>
                                <GroupPath
                                    pathName={
                                        (Array.isArray(configurationPayload?.device?.groupPath)
                                            ? configurationPayload?.device?.groupPath
                                            : configurationPayload?.device?.groupPath?.split(',')) || []
                                    }
                                    deviceName={configurationPayload?.device?.name ?? ''}
                                />
                                <IconButton
                                    onClick={(): void => {
                                        dispatch(togglePreviewNodeModal(true));
                                    }}
                                >
                                    <Mode />
                                </IconButton>
                            </>
                        )}
                    </Stack>
                </>
            )}
        </Paper>
    ) : (
        <></>
    );
};
