import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        planFeatures: [],
        subscriptionPayload: {
            deviceLimit: false,
            endDate: '',
            planId: '',
            deviceCount: 0,
            startDate: new Date().getTime(),
        },
        reveneraId: '',
    },
    reducers: {
        setSubscriptionPayload: (state, action: PayloadAction<any>) => {
            state.subscriptionPayload = action.payload;
        },
        setReveneraId: (state, action: PayloadAction<any>) => {
            state.reveneraId = action.payload;
        },
    },
});

export const { setSubscriptionPayload, setReveneraId } = subscriptionSlice.actions;
export const selectSubscritpionPayload = (state: RootState): any => state.subscription.subscriptionPayload;
export const selectReveneraId = (state: RootState): any => state.subscription.reveneraId;

export default subscriptionSlice;
