import {
    AppBar,
    Avatar,
    Box,
    Button,
    Chip,
    Menu,
    MenuItem,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    Toolbar,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomTransComponent } from './CustomTransComponent';

type CustomTabsProps = {
    initialTab?: string;
    tabsData: TabsDataType[];
    wrapperClass?: string;
    actions?: ReactNode;
    className?: string;
    type?: 'horizontal' | 'vertical';
    parentWrapper?: string;
    contentWrapper?: string;
    hideAppbar?: boolean;
    hideToolbar?: boolean;
    isSystemAdmin?: boolean;
    isEqualTabSize?: boolean;
    variableHeader?: boolean;
    hideShadow?: boolean;
    showExtraTabsInMenu?: boolean;
    onTabChange?: (args: any) => any;
    noHashRouting?: boolean;
    translationLoader?: boolean;
};

export type TabsDataType = {
    id: string;
    icon?: string | React.ReactElement;
    className?: string;
    sx?: { [key: string]: unknown };
    renderTitle: string | React.ReactNode;
    renderContent?: React.ReactNode;
    isDisabled?: boolean;
};

/* The `TabsComponent` function is a React component that renders the tabs and their associated
content. It takes in the `CustomTabsProps` as its props. */
const TabsComponent = React.memo((props: any): JSX.Element => {
    const theme: any = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const tabsContainerRef = React.useRef<HTMLDivElement | null>(null);
    const [moreMenuEl, setMoreMenuEl] = React.useState<null | HTMLElement>(null);
    const [maxLength, setMaxLength] = React.useState<number>(props?.tabsData?.length);
    const handleClickMoreMenu = (event: any): void => setMoreMenuEl(event.currentTarget);
    const handleCloseMoreMenu = (): void => setMoreMenuEl(null);
    const getSelectedTabIndex = (): number =>
        props?.tabsData?.findIndex((tab: TabsDataType) => tab.id === props?.selectedTab);
    React.useEffect(() => {
        if (props?.showExtraTabsInMenu) {
            const tabsWidth = tabsContainerRef.current?.getBoundingClientRect().width ?? props?.tabsData?.length;
            setMaxLength(tabsWidth / 250 - ((tabsWidth / 250) % 1) - 1);
        }
    }, []);

    React.useEffect(() => {
        if (location.hash && location.hash !== `#${props.selectedTab}`) {
            props.setSelectedTab(location.hash.replace('#', ''));
        } else if (!location.hash) {
            props.setSelectedTab(props?.initialTab ?? props?.tabsData?.[0]?.id);
        }
    }, [location.hash, props?.initialTab]);
    return (
        <>
            <Tabs
                ref={tabsContainerRef}
                orientation={props?.type ?? 'horizontal'}
                className={props?.wrapperClass ?? ''}
                value={props?.selectedTab}
                onChange={(_event, tab: string): void => {
                    if (props?.noHashRouting) props.setSelectedTab(tab);
                    else navigate(`#${tab}`);
                }}
                variant={props?.isSystemAdmin && !props.isEqualTabSize ? 'fullWidth' : 'scrollable'}
                scrollButtons={false}
                indicatorColor="primary"
                textColor="inherit"
                sx={{
                    marginTop: props?.type === 'vertical' ? '24px' : '',
                    ...(props?.type !== 'vertical' && { width: '100%' }),
                    '& .MuiTabs-flexContainer': {
                        display: 'flex',
                        justifyContent: props.isEqualTabSize ? 'unset' : 'space-between',
                        alignItems: 'center',
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: props?.type === 'vertical' ? 'transparent' : theme?.palette?.primary?.main,
                    },
                    '& .Mui-selected': {
                        color: `${theme?.palette?.primary?.main} !important`,
                        fontWeight: '600',
                        fontSize: '14px',
                        background: props?.type === 'vertical' ? theme?.palette?.primary?.[50] : '',
                        '& .MuiSvgIcon-root': { color: theme?.palette?.primary?.main },
                    },
                    '& .MuiTab-root': {
                        color: '#424E54',
                        fontWeight: '400',
                        fontSize: '16px',
                        textTransform: 'none',
                    },
                    '& .MuiButtonBase-root': {
                        minHeight: '56px',
                    },
                }}
            >
                {props?.tabsData?.slice(0, props?.type === 'vertical' ? props?.tabsData?.length : maxLength)?.map(
                    (tab: TabsDataType) => {
                        if (props?.translationLoader) {
                            return (
                                <Stack key={tab?.id} spacing={2} mt={2}>
                                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                        <Skeleton animation="wave" variant="circular" width={24} height={24} />
                                        <Skeleton animation="wave" variant="text" width={150} height={34} />
                                    </Stack>
                                    <Skeleton animation="wave" variant="rectangular" width={200} height={2} />
                                </Stack>
                            );
                        }
                        return (
                            tab && (
                                <Tab
                                    className={tab?.className}
                                    key={tab?.id}
                                    icon={tab?.icon}
                                    iconPosition={props?.type === 'vertical' ? 'end' : 'start'}
                                    label={tab?.renderTitle}
                                    {...(tab?.sx && {
                                        sx: {
                                            width: '250px',
                                            padding: '12px 16px',
                                            ...tab?.sx,
                                        },
                                    })}
                                    value={tab?.id}
                                    disabled={tab?.isDisabled}
                                />
                            )
                        );
                    }
                    // tab && (
                    //     <Tab
                    //         className={tab?.className}
                    //         key={tab?.id}
                    //         icon={tab?.icon}
                    //         iconPosition={props?.type === 'vertical' ? 'end' : 'start'}
                    //         label={tab?.renderTitle}
                    //         {...(tab?.sx && {
                    //             sx: {
                    //                 width: '250px',
                    //                 padding: '12px 16px',
                    //                 ...tab?.sx,
                    //             },
                    //         })}
                    //         value={tab?.id}
                    //         disabled={tab?.isDisabled}
                    //     />
                    // )
                )}
                {props?.type === 'vertical' && props?.actions}
                {props?.tabsData?.length > maxLength && (
                    <>
                        {getSelectedTabIndex() + 1 > maxLength ? (
                            <Chip
                                sx={{
                                    backgroundColor: theme?.palette?.primary?.[50],
                                    minHeight: '36px !important',
                                    borderRadius: '18px !important',
                                }}
                                className="chip-avatar"
                                onClick={handleClickMoreMenu}
                                avatar={
                                    <Avatar
                                        sx={{ backgroundColor: `${theme?.palette?.primary?.[200]} !important` }}
                                        className="height-36 width-36 margin-right-16"
                                    >
                                        <KeyboardDoubleArrowRight color="primary" />
                                    </Avatar>
                                }
                                label={
                                    <Box
                                        display="flex"
                                        flexDirection={'row'}
                                        sx={{ color: theme?.palette?.primary?.main }}
                                    >
                                        {props?.tabsData?.[getSelectedTabIndex()]?.icon}
                                        <Typography sx={{ fontSize: '16px', fontWeight: 400, ml: 1 }}>
                                            {props?.tabsData?.[getSelectedTabIndex()]?.renderTitle}
                                        </Typography>
                                    </Box>
                                }
                            />
                        ) : (
                            <Button
                                id="more"
                                endIcon={<KeyboardDoubleArrowRight className="color-content" />}
                                className="color-content"
                                sx={{ px: 4, fontSize: '16px', fontWeight: 400 }}
                                onClick={handleClickMoreMenu}
                            >
                                <CustomTransComponent translationKey={'COMMON:MORE_LABEL'} />
                            </Button>
                        )}
                        <Menu
                            id="moreMenuItems"
                            anchorEl={moreMenuEl}
                            open={Boolean(moreMenuEl)}
                            onClose={handleCloseMoreMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            sx={{
                                '& .MuiList-root.MuiList-padding.MuiMenu-list': {
                                    padding: 0,
                                },
                            }}
                        >
                            {props?.tabsData?.slice(maxLength)?.map(
                                (tab: TabsDataType) =>
                                    tab &&
                                    props?.selectedTab !== tab?.id && (
                                        <MenuItem
                                            className={`${tab?.className ?? ''} ${
                                                tab?.id === props?.selectedTab ? '' : 'color-content'
                                            }`}
                                            key={tab?.id}
                                            {...(tab?.sx && {
                                                sx: {
                                                    width: '250px',
                                                    padding: '12px 16px',
                                                    ...tab?.sx,
                                                    ...(tab?.id === props?.selectedTab && {
                                                        color: theme?.palette?.primary?.main,
                                                    }),
                                                },
                                            })}
                                            selected={tab?.id === props?.selectedTab}
                                            disabled={tab?.isDisabled}
                                            onClick={(): void => {
                                                if (props?.noHashRouting) props.setSelectedTab(tab.id);
                                                else navigate(`#${tab.id}`);
                                                handleCloseMoreMenu();
                                            }}
                                        >
                                            {tab?.icon}
                                            <Box ml={3}>{tab?.renderTitle}</Box>
                                        </MenuItem>
                                    )
                            )}
                        </Menu>
                    </>
                )}
            </Tabs>
            {props?.isSystemAdmin && (
                <div className="rotate-90 padding-12">
                    <MoreHorizIcon className="color-content" />
                </div>
            )}
            {props?.actions && props?.type !== 'vertical' && <Stack direction="row">{props.actions}</Stack>}
        </>
    );
});

/* The `renderTabComponent` function is a helper function that determines how to render the tabs
component based on the provided props. It takes in the `CustomTabsProps` as its argument and returns
a JSX element. */
const TabWrapperComponent = React.memo((props: any): JSX.Element => {
    switch (true) {
        case props.hideAppbar && props.hideToolbar: {
            return <TabsComponent {...props} />;
        }
        case props.hideAppbar: {
            return (
                <Toolbar className="min-height-56">
                    <TabsComponent {...props} />
                </Toolbar>
            );
        }
        case props.hideToolbar: {
            return (
                <AppBar
                    sx={props?.hideShadow ? { boxShadow: 'none' } : {}}
                    position={props?.actions ? 'static' : 'relative'}
                    className="bg-white"
                >
                    <TabsComponent {...props} />
                </AppBar>
            );
        }
        default: {
            return (
                <AppBar
                    position={props?.actions ? 'static' : 'relative'}
                    className={`bg-white`}
                    ref={props?.appbarRef}
                    sx={{
                        '&.MuiPaper-root.MuiAppBar-root': {
                            zIndex: 1000,
                            position: 'sticky',
                            left: 'auto',
                            right: 0,
                        },
                        ...(props?.hideShadow ? { boxShadow: 'none' } : {}),
                    }}
                >
                    <Toolbar className="min-height-56">
                        <TabsComponent {...props} />
                    </Toolbar>
                </AppBar>
            );
        }
    }
});

/* The code is exporting a React component called `CustomTabs` using the `export` keyword. The
component is created using the `React.memo` function, which is a higher-order component that
memoizes the component to optimize performance by preventing unnecessary re-renders. */
export const CustomTabs = React.memo((props: CustomTabsProps): JSX.Element => {
    const location = useLocation();
    const [selectedTab, setSelectedTab] = React.useState<string>(location.hash.replace('#', '') ?? '');
    const selectedTabData = props?.tabsData?.find((tab) => tab?.id === selectedTab);
    const appbarRef: any = React.useRef(null);

    React.useLayoutEffect(() => {
        window.scrollTo({ top: 0 });
        if (appbarRef.current) {
            if (props?.variableHeader) {
                window.addEventListener('scroll', () => {
                    const headerElement = document.getElementById('app-collapsible-header');
                    if (headerElement && appbarRef.current) {
                        const variableHeight = headerElement?.getBoundingClientRect().height;
                        appbarRef.current.style.top = `${variableHeight}px`;
                    }
                });
            } else {
                appbarRef.current.style.top = `${appbarRef.current.getBoundingClientRect()?.top}px`;
            }
        }
    }, []);
    React.useEffect(() => {
        props?.onTabChange?.(selectedTab);
    }, [selectedTab]);
    return (
        <Box className={`${props.parentWrapper ?? ' '}`}>
            <TabWrapperComponent
                {...props}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                appbarRef={appbarRef}
            />
            <Box className={`w-100 ${props.contentWrapper ?? ' '}`}>{selectedTabData?.renderContent}</Box>
        </Box>
    );
});
