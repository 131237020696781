import { api } from '../../app/api/baseApi';
export const subscriptionApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllSubscriptions: builder.query<any, any>({
            query: ({ body, headers, params }) => ({
                url: 'es/v1/subscriptions/filter',
                params,
                method: 'POST',
                body,
                ...(headers && {
                    headers,
                }),
            }),
            providesTags: ['allSubscriptions'],
        }),
        updateSubscription: builder.mutation<any, any>({
            query: ({ id, body }) => ({
                url: `es/v1/subscriptions/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['subscriptionDetails'] : []),
        }),
        createSubscription: builder.mutation<any, any>({
            query: ({ body, realmPrefix, orgId }) => ({
                url: `es/v1/subscriptions`,
                method: 'POST',
                body,
                headers: { ...(realmPrefix && { 'x-realm': realmPrefix }), ...(orgId && { organizationId: orgId }) },
            }),
            invalidatesTags: (result, error): any => (!error ? ['allSubscriptions'] : []),
        }),
        getSubscriptionByid: builder.query({
            query: (id) => ({
                url: `es/v1/subscriptions/${id}`,
                method: 'GET',
            }),
            providesTags: ['subscriptionDetails'],
        }),

        deleteSubscriptionById: builder.mutation({
            query: (id) => ({
                url: `es/v1/subscriptions/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['allSubscriptions'] : []),
        }),
        getSubscriptionByOrgId: builder.query({
            query: () => ({
                url: 'es/v1/subscriptions/org',
                method: 'GET',
            }),
            providesTags: ['orgSubscription'],
        }),
        activateSubscription: builder.mutation({
            query: ({ id, realmPrefix }) => ({
                url: `es/v1/subscriptions/activate/${id}`,
                method: 'PUT',
                headers: realmPrefix && { 'x-realm': realmPrefix },
            }),
        }),
    }),
});

export const {
    useGetAllSubscriptionsQuery,
    useGetSubscriptionByidQuery,
    useCreateSubscriptionMutation,
    useUpdateSubscriptionMutation,
    useDeleteSubscriptionByIdMutation,
    useGetSubscriptionByOrgIdQuery,
    useActivateSubscriptionMutation,
} = subscriptionApi;
