import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

interface StatusStripeProps {
    statusColor?: string;
}

export const StatusStripe = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'statusColor',
})<StatusStripeProps>(({ statusColor }) => ({
    position: 'absolute',
    top: 0,
    bottom: '-4%',
    left: 0,
    width: 6,
    zIndex: 100,
    backgroundColor: statusColor,
}));
