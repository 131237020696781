import { RootState } from '../../app/store';
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';

const timelineSlice = createSlice({
    name: 'alarmtimeline',
    initialState: {
        acknowledge: false,
        selectedAlarm: {},
        timerString: '',
        closeSnooze: true,
        snoozeIsVisible: false,
        timelineId: '',
        eventSearch: '',
        expandedEventCategories: {} as any,
    },
    reducers: {
        setUpdateProperties: (state: RootState, action: PayloadAction<any>) => {
            state.propertiesPayload[action.payload['key']] = action.payload['value'];
        },
        setAcknowledgeFlag: (state, action: PayloadAction<any>) => {
            state.acknowledge = action.payload;
        },
        setSelectedAlarm: (state, action: PayloadAction<any>) => {
            state.selectedAlarm = action.payload;
        },
        setTimerString: (state, action: PayloadAction<any>) => {
            state.timerString = action.payload;
        },
        setCloseSnooze: (state, action: PayloadAction<any>) => {
            state.closeSnooze = action.payload;
        },
        setSnoozeIsVisible: (state, action: PayloadAction<any>) => {
            state.snoozeIsVisible = action.payload;
        },
        setTimeLineId: (state, action: PayloadAction<any>) => {
            state.timelineId = action.payload;
        },
        setEventSearch: (state, action: PayloadAction<any>) => {
            state.eventSearch = action.payload;
        },
        setAllExpandedEventCategories: (state, action: PayloadAction<any>) => {
            const requiredCategories: { [key: string]: boolean } = {};
            Object.keys(action.payload)?.forEach((key) => {
                requiredCategories[key] = true;
            });
            state['expandedEventCategories'] = requiredCategories;
        },
        setExpandedEventCategories: (state) => {
            const expandedCategoriesClone = current(state['expandedEventCategories']);
            const isExpanded = Object?.values(expandedCategoriesClone)?.every((item: any) => item === true);

            for (const key in expandedCategoriesClone) {
                if (isExpanded) {
                    state['expandedEventCategories'][key] = false;
                } else {
                    state['expandedEventCategories'][key] = true;
                }
            }
        },
        setEventExpandedCategory: (state, action: PayloadAction<any>) => {
            state['expandedEventCategories'][action.payload] = !state['expandedEventCategories'][action.payload];
        },
    },
});

export const {
    setAcknowledgeFlag,
    setSelectedAlarm,
    setTimerString,
    setCloseSnooze,
    setSnoozeIsVisible,
    setEventSearch,
    setTimeLineId,
    setEventExpandedCategory,
    setAllExpandedEventCategories,
    setExpandedEventCategories,
} = timelineSlice.actions;

export default timelineSlice;
