import React, { useRef, PropsWithChildren, ReactElement } from 'react';
import { Node } from './Node';
import { Placeholder } from './Placeholder';
import { HierarchyFilter } from './types';
import { useTreeContext, useDropRoot, useContainerClassName } from './hooks';
import { getFilterExpression } from './utils';
import { useTranslation } from 'react-i18next';

type Props = PropsWithChildren<{
    parentNode: any;
    depth: number;
    nodes: any;
    disabled?: boolean;
    controlledSelected?: boolean;
}>;

export const Container = <T,>(props: Props): ReactElement => {
    const treeContext = useTreeContext<T>();
    const ref = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const [isOver] = useDropRoot<T>(ref, props?.parentNode, props?.disabled ?? false);

    const className = useContainerClassName(isOver);

    const [nodes, setNodes] = React.useState(props?.nodes);

    React.useEffect(() => {
        if (treeContext?.pagination) {
            setNodes(props?.nodes?.[treeContext?.pagination?.records ?? 'records']);
        } else {
            setNodes(props?.nodes);
        }
    }, [props?.nodes]);

    React.useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [treeContext.hierarchyMappingData]);

    const filteredHierarchyNodes = nodes?.map?.((node: any, index: number) => {
        let filterPayload: HierarchyFilter[] = [];
        if (typeof treeContext?.filters === 'function') {
            filterPayload = treeContext?.filters?.(node);
        } else if (treeContext?.filters?.length) {
            filterPayload = treeContext?.filters.filter((filter) => !getFilterExpression(node, filter));
        }
        if (filterPayload?.find((filter) => filter.action === 'hidden')) {
            return null;
        }
        return (
            <React.Fragment key={node.id}>
                {!props?.disabled && (
                    <Placeholder
                        depth={props?.depth}
                        listCount={props?.nodes?.length}
                        dropTargetId={props?.parentNode?.id}
                        index={index}
                    />
                )}
                <Node
                    {...node}
                    parentNode={props?.parentNode}
                    depth={props.depth}
                    controlledSelected={props?.controlledSelected}
                    disabled={props?.disabled}
                    filterPayload={filterPayload}
                />
            </React.Fragment>
        );
    });

    const getPaginationNode = (): JSX.Element => {
        if (
            treeContext?.pagination &&
            (Math.ceil(
                props?.nodes?.[treeContext?.pagination?.total ?? 'total'] / (treeContext?.pagination?.size ?? 20)
            ) -
                1 >
                (treeContext?.paginationMappingData?.[props?.parentNode?.id] ?? 0) ||
                treeContext?.loadingNode?.includes(`more-button-${props?.parentNode?.id}`))
        ) {
            return (
                <Node
                    id={`more-button-${props?.parentNode?.id}`}
                    displayName={
                        treeContext?.loadingNode?.includes(`more-button-${props?.parentNode?.id}`)
                            ? t('COMMON:LOADING')
                            : t('COMMON:SHOW_MORE')
                    }
                    parentNode={props?.parentNode}
                    depth={props.depth}
                />
            );
        }
        return <></>;
    };

    return (
        <div ref={ref} role="list" style={{ ...className, listStyleType: 'none', height: '100%' }}>
            {filteredHierarchyNodes?.every?.((item: any) => item === null) && props?.depth === 0
                ? treeContext?.noDataFoundComponent
                : filteredHierarchyNodes}
            {getPaginationNode()}
            {!props?.disabled && (
                <Placeholder depth={props.depth} listCount={props?.nodes?.length} dropTargetId={props.parentNode?.id} />
            )}
        </div>
    );
};
