import React from 'react';

import { Button } from '@mui/material';
import { WidgetList } from '../WidgetManagement';
import { CustomTabs, CustomTransComponent } from '../../components';
import { DashboardList } from '../DashboardManagement/DashboardList';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setAddWidgetModal } from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from 'hooks';
import { useTheme } from '@mui/material/styles';
import DashboardCustomize from '@mui/icons-material/DashboardCustomize';

const tabsStyle = { display: 'flex', flexDirection: 'row', marginBottom: '0' };

export const DashboardAndWidgets = (): JSX.Element => {
    const theme: any = useTheme();
    const dispatch = useAppDispatch();
    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canViewSystemDashboard = hasPermission('view-system-dashboards');
    const canViewUserDashboard = hasPermission('view-user-dashboards');
    const canCreateSystemWidgets = hasPermission('create-system-widgets');
    const canCreateUserWidgets = hasPermission('create-user-widgets');
    const canViewSystemWidgets = hasPermission('view-system-widgets');
    const canViewUserWidgets = hasPermission('view-user-widgets');

    const tabsData: any[] = [];
    if (canViewSystemDashboard || canViewUserDashboard) {
        tabsData.push({
            id: 'dashboards',
            sx: tabsStyle,
            renderTitle: <CustomTransComponent translationKey={'COMMON:DASHBOARDS'} />,
            renderContent: <DashboardList />,
        });
    }
    if (canViewSystemWidgets || canViewUserWidgets) {
        tabsData.push({
            id: 'widgets',
            sx: tabsStyle,
            renderTitle: <CustomTransComponent translationKey={'COMMON:WIDGETS'} />,
            renderContent: <WidgetList />,
        });
    }

    const [selectedTab, setSelectedTab] = React.useState<string>(tabsData?.[0]?.id);

    const onTabChange = (activeTab: string): void => {
        setSelectedTab(tabsData?.find((tab) => tab.id === activeTab)?.id ?? '');
    };

    return (
        (canViewSystemDashboard || canViewUserDashboard || canViewSystemWidgets || canViewUserWidgets) && (
            <>
                <CustomTabs
                    wrapperClass="start-align"
                    tabsData={tabsData}
                    showExtraTabsInMenu
                    actions={
                        selectedTab === 'widgets' &&
                        (canCreateSystemWidgets || canCreateUserWidgets) && (
                            <Button
                                id="add-widget"
                                variant="outlined"
                                className=" min-width-140"
                                startIcon={<DashboardCustomize />}
                                sx={{
                                    border: `1px solid ${theme?.palette?.primary?.main}`,
                                    '&:hover': {
                                        backgroundColor: theme?.palette?.primary?.[50],
                                    },
                                }}
                                onClick={(): void => {
                                    dispatch(setAddWidgetModal({ isOpen: true, mode: 'widget' }));
                                }}
                            >
                                <CustomTransComponent size={{ width: '100px' }} translationKey={'WIDGETS:ADD_WIDGET'} />
                            </Button>
                        )
                    }
                    onTabChange={onTabChange}
                />
            </>
        )
    );
};
