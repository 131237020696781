import React from 'react';

import { GroupPath } from 'components';
import { useDefaultConfig } from './hooks';
import { Close, DragHandle } from '@mui/icons-material';
import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

export const DraggableListItem = ({
    item,
    widgetTypeId,
    providedItem,
}: {
    item: any;
    widgetTypeId: any;
    providedItem?: any;
}): JSX.Element => {
    const { handleRemoveChannel } = useDefaultConfig(widgetTypeId);

    return (
        <ListItem
            {...(providedItem && {
                ref: providedItem.innerRef,
            })}
            className="border-bottom-1 list-item-hover"
            disablePadding
            secondaryAction={
                <IconButton
                    edge="end"
                    aria-label="close"
                    onClick={(): void => {
                        handleRemoveChannel({
                            type: 'selectedNodes',
                            channel: item,
                        });
                    }}
                >
                    <Close />
                </IconButton>
            }
        >
            <ListItemButton
                className="padding-8"
                {...(providedItem && {
                    ...providedItem.draggableProps,
                })}
                role={undefined}
                dense
            >
                <ListItemIcon
                    role={undefined}
                    {...(providedItem && {
                        ...providedItem.dragHandleProps,
                    })}
                >
                    {providedItem && <DragHandle />}
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant="body2" fontWeight={'600'}>
                            {item['name']}
                        </Typography>
                    }
                    secondary={
                        item['groupPath'] && (
                            <GroupPath
                                {...(item?.parentNode?.name
                                    ? {
                                          pathName: [item?.parentNode?.name, ...item['groupPath']],
                                      }
                                    : {
                                          pathName: item['groupPath'],
                                      })}
                                deviceName={item['name']}
                            />
                        )
                    }
                />
            </ListItemButton>
        </ListItem>
    );
};
