import { Skeleton } from '@mui/material';
import { useNewCustomTranslations } from 'hooks';
import i18next from 'i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const CustomTransComponent = ({
    translationKey,
    size,
    replace,
    components,
    skip,
    customT,
}: {
    translationKey: any;
    size?: any;
    replace?: any;
    components?: any;
    skip?: boolean;
    customT?: any;
}): any => {
    const { t } = useTranslation();
    useNewCustomTranslations(translationKey?.split(':')[0], skip);
    return !i18next.hasResourceBundle('en', translationKey?.split(':')[0]) ? (
        <Skeleton
            animation="wave"
            variant="rounded"
            width={size?.width ?? 200}
            height={size?.height ?? 15}
            sx={{ marginBottom: '10px', marginTop: '10px' }}
        />
    ) : customT ? (
        t(translationKey)
    ) : (
        <Trans i18nKey={translationKey} values={replace} components={components} />
    );
};
