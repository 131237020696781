import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    payload: {},
};

const virtualLoadSlice = createSlice({
    name: 'virtualLoad',
    initialState: initialState,
    reducers: {
        resetVirtualLoadPayload: () => {
            return initialState;
        },
        setVirtualLoadPayload: (state: RootState, action: PayloadAction<any>): void => {
            state.payload[action.payload.key] = action.payload.value;
        },
        setDefaultPayload: (state: RootState, action: PayloadAction<any>): void => {
            state.payload = action?.payload;
        },
    },
});

export const { resetVirtualLoadPayload, setVirtualLoadPayload, setDefaultPayload } = virtualLoadSlice.actions;

export default virtualLoadSlice;
