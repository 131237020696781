import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { setVisibleColumns } from '@fiji/common/src/features/component/dataGridSlice';

export const ColumnVisibilityMenu = ({ columns }: any): JSX.Element => {
    const { configurablePayload } = useTypedSelector((state) => state.dataGrid);
    const dispatch = useAppDispatch();
    const theme: any = useTheme();
    return (
        <FormGroup sx={{ m: '0' }}>
            {columns?.map(
                (column: any) =>
                    Boolean(
                        column.field &&
                            column.field !== 'actions' &&
                            column.field !== 'custom' &&
                            column?.field !== '__check__'
                    ) && (
                        <FormControlLabel
                            sx={{ m: '0', padding: '8px' }}
                            key={column.field}
                            required
                            control={
                                <Switch
                                    id={column.field}
                                    disabled={column.hideable === false}
                                    checked={configurablePayload?.visibleColumns[column.field] ?? false}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                        dispatch(setVisibleColumns({ key: e.target.id, value: e.target.checked }));
                                    }}
                                    sx={{
                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                            color: theme?.palette?.primary?.main,
                                            '& + .MuiSwitch-track': {
                                                backgroundColor: theme?.palette?.primary?.main,
                                            },
                                        },
                                        '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
                                            color: theme?.palette?.primary?.[50],
                                            '& + .MuiSwitch-track': {
                                                backgroundColor: theme?.palette?.primary?.[200],
                                            },
                                        },
                                    }}
                                />
                            }
                            label={column.headerName}
                        />
                    )
            )}
        </FormGroup>
    );
};
