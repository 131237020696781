import { Spacer } from '@brightlayer-ui/react-components';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { createNumArray } from '../../../utils/helpers';

/**
 * The `RealmHierarchySkeleton` function returns a skeleton loading component for a hierarchy with a
 * specified number of rows.
 * @param  - - `row`: The number of rows to render in the hierarchy skeleton.
 */
export const RealmHierarchySkeleton = ({ row }: { row: number }): any =>
    createNumArray(row).map((rowItem: any) => (
        <Stack flexDirection={'row'} alignItems={'center'} width={'100%'} key={rowItem}>
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                <Stack flexDirection={'row'} alignItems={'center'} ml={3}>
                    <Skeleton animation="wave" variant="circular" width={40} height={40} className="margin-x-16" />
                    <Box>
                        <Skeleton animation="wave">
                            <Typography variant="h5" component="h5">
                                Loading
                            </Typography>
                        </Skeleton>
                        <Skeleton>
                            <Typography variant="h6" component="h6">
                                SubTitle Loading
                            </Typography>
                        </Skeleton>
                    </Box>
                </Stack>
                <Spacer />
            </Stack>
        </Stack>
    ));
