import { RootState } from '../../app/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Organization } from '../../types';

const initialSelectedOrgState: Organization = {
    id: '',
    name: '',
};

export type OrgFlow = {
    flow: string;
    isOpen: boolean;
    type?: string;
    realmPayload?: {
        realmId: string;
        realmName?: string;
        realmPrefix?: string;
        level?: number;
    };
};

const orgSlice = createSlice({
    name: 'org',
    initialState: {
        selectedOrg: initialSelectedOrgState,
        switchOrgModal: false,
        orgFlowModal: { isOpen: false, flow: '' },
        deleteOrgModal: { isOpen: false, org: undefined },
    } as any,
    reducers: {
        setAddRealmModal: (state: RootState, action: PayloadAction<any>) => {
            state.addRealmModal = action.payload;
        },
        setSelectedOrg: (state, action: PayloadAction<any>) => {
            state.selectedOrg = action.payload;
        },
        switchOrgModal: (state, { payload }: PayloadAction<boolean | 'switched'>) => {
            state.switchOrgModal = payload;
        },
        setDeleteOrgModal: (state, { payload }: PayloadAction<{ isOpen: boolean; org: any }>) => {
            state.deleteOrgModal = payload;
        },
        orgFlowModal: (state, { payload }: PayloadAction<any>) => {
            state.orgFlowModal = payload;
        },
    },
});

export const { setAddRealmModal, setSelectedOrg, orgFlowModal, switchOrgModal, setDeleteOrgModal } = orgSlice.actions;
export const selectOrgFlowModal = (state: RootState): OrgFlow => state.org.orgFlowModal;
export const selectedOrg = (state: RootState): Organization => state.org.selectedOrg;
export const selectSwitchOrgModal = (state: RootState): boolean => state.org.switchOrgModal;

export default orgSlice;
