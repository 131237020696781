import React from 'react';

import Link from '@mui/icons-material/Link';
import { createNumArray } from 'utils/helpers';
import { IconButton, Skeleton, Stack, Typography } from '@mui/material';

export const LinkButtonSkeleton = ({ total }: any): JSX.Element => (
    <>
        {createNumArray(total)?.map(
            (item: any): JSX.Element => (
                <Stack
                    key={item}
                    sx={{ marginBottom: '20px' }}
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <Stack spacing={1} alignItems={'center'} direction={'row'}>
                        <Skeleton animation="wave" variant="circular" height={25} width={25}>
                            <IconButton>
                                <Link color="primary" />
                            </IconButton>
                        </Skeleton>
                        <Skeleton animation="wave">
                            <Typography variant="body2" color="primary" fontWeight={'600'} fontSize={'14px'}>
                                Link to a specific device or devices
                            </Typography>
                        </Skeleton>
                    </Stack>
                </Stack>
            )
        )}
    </>
);
