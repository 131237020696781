import React from 'react';
import { Stack, Paper, Container, Typography, Skeleton } from '@mui/material';
import { createNumArray } from 'utils/helpers';

export const DisplayPreferencesSkeleton = (): JSX.Element => (
    <Paper className="padding-16">
        <Container className="bg-content-wrapper min-height-828 padding-16">
            <Stack className="bg-white">
                {createNumArray(4)?.map((item: any, index: any) => (
                    <Stack
                        key={`unque${item}${index}`}
                        direction={'row'}
                        p={2}
                        className="border-bottom-1"
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Skeleton animation="wave" variant="rounded" height={15}>
                            <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'} className="black-700">
                                Maximum number of events to display
                            </Typography>
                        </Skeleton>

                        <Stack direction={'row'} alignItems={'center'}>
                            <Skeleton animation="wave" variant="rounded" sx={{ mx: '5px' }} height={15}>
                                <Typography px={3} variant="body1" fontWeight={'600'} textAlign={'center'}>
                                    hello
                                </Typography>
                            </Skeleton>
                            <Skeleton animation="wave" variant="circular" height={28} width={28} />
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </Container>
    </Paper>
);
