import React from 'react';
import { TableRow, TableCell, Skeleton, TableBody, Checkbox } from '@mui/material';
import { createNumArray } from '../utils/helpers';

/**
 * The `TableSkeleton` function generates a skeleton table with a specified number of rows and headers,
 * displaying loading placeholders for each cell.
 * @param {any} header - The `header` parameter is an object that represents a table header. It may
 * have properties like `skeleton` and `isSelectable`.
 * @returns The `TableSkeleton` component is returning a JSX element.
 */
export const TableSkeleton = ({ row = 10, headers }: { row?: number; headers: any }): JSX.Element => {
    /**
     * The function `getSkeletonHtml` returns a JSX element that represents a skeleton loading state
     * for a header component in a React application.
     * @param {any} header - The `header` parameter is an object that contains information about the
     * header. It may have the following properties:
     * @returns The function `getSkeletonHtml` returns a JSX element.
     */
    const getSkeletonHtml = (header: any): JSX.Element => {
        if (header?.skeleton) {
            return header?.skeleton;
        } else if (header?.isSelectable) {
            return (
                <Skeleton animation="wave" variant="rounded" width={20} height={20} className="margin-0-auto">
                    <Checkbox color="primary" />
                </Skeleton>
            );
        }
        return <Skeleton animation="wave" variant="text" />;
    };
    return (
        <TableBody>
            {createNumArray(row).map(() => (
                <TableRow key={window.crypto.randomUUID()}>
                    {headers?.map((header: any) => (
                        <TableCell key={window.crypto.randomUUID()} width={header?.width}>
                            {getSkeletonHtml(header)}
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    );
};
