const isNullOrUndefined = (item: string | number | [] | object | undefined | null): boolean =>
    item === null || item === undefined;

function customEndpointSelector(type: string): Array<{ key: string; endpoint: string }> {
    switch (type) {
        case 'details':
        case 'properties':
        case 'settings':
        case 'gauge':
            return [{ key: type, endpoint: 'getChannelDetails' }];
        case 'device_list':
            return [{ key: type, endpoint: 'getAllDevices' }];
        case 'timeline':
            return [
                { key: type, endpoint: 'getDeviceTimeline' },
                { key: type, endpoint: 'getDeviceAlertCount' },
            ];
        case 'trends':
            return [{ key: type, endpoint: 'getDeviceTrends' }];
        case 'group_list':
            return [{ key: type, endpoint: 'getAllFlattenedGroups' }];
        case 'map':
            return [{ key: type, endpoint: 'deviceMap' }];
        case 'loads':
            return [{ key: type, endpoint: 'getMultiDeviceLoads' }];
        case 'virtual_loads':
            return [{ key: type, endpoint: 'getAllVirtualLoads' }];
        case 'upcoming':
            return [{ key: type, endpoint: 'getPredictionFilters' }];
        case 'score':
            return [{ key: type, endpoint: 'getScore' }];
        case 'components':
            return [{ key: type, endpoint: 'getComponentFilter' }];

        default:
            return [{ key: type, endpoint: 'getChannelDetails' }];
    }
}

function getMqttTopiceByWidgetType(type: string): string {
    switch (type) {
        case 'trends':
            return 'BSSRM/TREND';
        default:
            return 'BSSRM';
    }
}

function retrieveChannelTypefromWidgetType(widgetType?: string): string {
    switch (widgetType) {
        case 'details':
            return 'DETAIL';
        case 'settings':
            return 'SETTING';
        case 'properties':
            return 'PROPERTIES';
        case 'loads':
            return 'LOAD';
        case 'trends':
        case 'gauge':
            return 'TREND';
        case 'command_bar':
            return 'CONTROL';
        default:
            return 'DETAIL';
    }
}

function getRandomColor(): string {
    const letters: any = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const formatTime = (timeData: any): string => {
    let timeStr = '';
    if (timeData?.hours > 0) {
        timeStr += `${timeData?.hours}h`;
    }
    if (timeData?.minutes > 0 || timeData?.hours > 0) {
        timeStr += timeData?.minutes < 10 ? `0${timeData?.minutes}m` : `${timeData?.minutes}m`;
    }
    return (timeStr += timeData?.seconds < 10 ? `0${timeData?.seconds}s` : `${timeData?.seconds}s`);
};
const getTimeInFromTo = (value: number, key: string): any => {
    const current: number = Date.now();
    let newValue = 0;
    if (key === 'h') {
        newValue = value * 60 * 60 * 1000;
    }
    return {
        from: current - newValue,
        to: current,
    };
};

const getListItemBackgroundColor = (data: any): string => {
    if (data?.status === 'Active' && data?.severity === 'Alarm') {
        return '#CA3C3D';
    } else if (data?.status === 'Active' && data?.severity === 'Offline') {
        return '#69B1C3';
    } else if (data?.status === 'Acknowledged') {
        return '#E0F1FD';
    }
    return 'transparent';
};

const getListItemTextColor = (status: string, severity: string): string => {
    if (status === 'Cleared' || severity === 'Cleared') {
        return '#424E54';
    } else if (status === 'Acknowledged') {
        return '#0088F2';
    }
    return '#FFFFFF';
};

const toCamelCaseAndCapitalize = (str: any): string => {
    const s =
        str &&
        str
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map((x: string) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
            .join('');
    return s.slice(0, 1).toUpperCase() + s.slice(1);
};

export {
    getRandomColor,
    isNullOrUndefined,
    customEndpointSelector,
    getMqttTopiceByWidgetType,
    retrieveChannelTypefromWidgetType,
    formatTime,
    getTimeInFromTo,
    getListItemBackgroundColor,
    getListItemTextColor,
    toCamelCaseAndCapitalize,
};
