import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { channelsHandler, handleChannelRemove, handleGroupDevicesFilter, handleReallocateChannels } from './helpers';

const groupListInitialState = {
    secondary: [],
    filters: {},
    selectedNodes: [],
    maxCount: 6,
    isTotalCountVisible: false,
    viewAllButton: false,
    sortPayload: {
        sortBy: 'name',
        sortType: 'ASC',
    },
};
const groupListWidgetSlice = createSlice({
    name: 'group_list',
    initialState: groupListInitialState,
    reducers: {
        resetGroupListConfiguration: () => {
            return groupListInitialState;
        },
        setGroupData: (state: RootState, action: PayloadAction<any>) => {
            state.secondary = action.payload
                ?.filter?.((item: any) => item.type === 'GROUP')
                ?.map((group: any) => ({
                    id: group?.id,
                    name: group?.name,
                    icon: group?.icon,
                    deviceCount: group?.deviceCount,
                    indicator: group?.indicator ?? '',
                    groupPath: group?.groupPath,
                    statusCount: {
                        alarms: group?.alarmCount ?? 0,
                        offline: group?.offlineCount ?? 0,
                        warnings: group?.warningCount ?? 0,
                        information: group?.informationalCount ?? 0,
                        snooze: group?.snoozeCount ?? 0,
                        upcomming: group?.upcommingCount ?? 0,
                    },
                }));
        },
        setGroup: (state: RootState, action: PayloadAction<any>) => {
            channelsHandler(state, action);
        },
        setGroupListFilters: (state: RootState, action: PayloadAction<any>) => {
            state['filters'] = action.payload;
        },
        setGroupListSelectedNodes: (state: RootState, action: PayloadAction<any>) => {
            handleGroupDevicesFilter(state, action);
        },
        setGroupListSortPayloadOrder: (state: RootState, action: PayloadAction<any>) => {
            state['sortPayload']['sortType'] = action.payload;
        },
        setGroupListSortPayloadKey: (state: RootState, action: PayloadAction<any>) => {
            state['sortPayload']['sortBy'] = action.payload;
        },
        setGroupHierarchyFilter: (state: RootState, action: PayloadAction<string>) => {
            state['filters'] = action.payload;
        },
        reallocateGroupList: (state: RootState, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },
        removeGroup: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
        },
        setGroupListPreferences: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state: RootState) => {
            state.secondary = [];
            state.selectedNodes = [];
            state.filters = {};
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state: RootState, action: any) => {
            if (!action.payload.reset) {
                state.secondary = [];
            }
            state['deviceId'] = action.payload?.data?.id;
        });
    },
});

export const {
    reallocateGroupList,
    removeGroup,
    setGroupListPreferences,
    setGroupHierarchyFilter,
    setGroupListFilters,
    setGroupListSelectedNodes,
    setGroupListSortPayloadKey,
    setGroupListSortPayloadOrder,
    resetGroupListConfiguration,
    setGroupData,
} = groupListWidgetSlice.actions;

export default groupListWidgetSlice;
