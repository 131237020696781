import React, { ReactNode } from 'react';
import clsx from 'clsx';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Typography,
} from '@mui/material';
import { Loader, Transition } from './';
import { useTheme } from '@mui/material/styles';

/**
 * The above type defines the props for a custom modal component in a TypeScript React application.
 * @property {boolean} hidePadding - The `hidePadding` property is an optional boolean that is used to
 * remove the padding from Dialog Content.
 * @property {string} type - The `type` property is a string that represents the type or category of
 * the modal. It can be used to differentiate between different types of modals and apply specific
 * styling or behavior based on the type.
 * @property {ModalActions} actions - The `actions` property is of type `ModalActions`. It is used to
 * define the actions or buttons that can be performed in the modal.
 * @property {boolean} isOpen - A boolean value indicating whether the modal is currently open or not.
 * @property {string} header - The `header` property is a string that represents the main title or
 * heading of the modal.
 * @property {boolean} isLoading - The `isLoading` property is an optional boolean that indicates
 * whether the modal is currently in a loading state. This can be used to show a loading spinner or any
 * other loading indicator while the modal content is being fetched or processed.
 * @property {string} subHeader - The `subHeader` property is an optional string that represents a
 * subheading or additional information for the modal. It can be used to provide more context or
 * details about the content of the modal.
 * @property children - The `children` property is a special prop in React that allows you to pass
 * components or elements as children to another component. In this case, it is used to pass any React
 * nodes or components that should be rendered inside the modal.
 * @property onClose - The `onClose` property is a function that will be called when the modal is
 * closed. It is optional and can be used to perform any necessary cleanup or trigger any additional
 * actions when the modal is closed.
 * @property {boolean} noDividers - The `noDividers` property is a boolean flag that indicates whether
 * or not to display dividers within the modal. If set to `true`, dividers will not be displayed. If
 * not provided or set to `false`, dividers will be displayed.
 */
type CustomModalProps = {
    type: string;
    hidePadding?: boolean;
    actions: ModalActions[];
    isOpen: boolean;
    unTrimHeader?: boolean;
    header?: ReactNode;
    isLoading?: boolean;
    contentClass?: string;
    subHeader?: ReactNode;
    children?: React.ReactNode;
    onClose?: () => void;
    noDividers?: boolean;
    actionsDivider?: boolean;
    customHeader?: JSX.Element;
    footerClass?: string;
    isStacked?: boolean;
};

/**
 * The above type defines an object where each key represents an action, and each value contains
 * properties related to that action.
 * @property [key: undefined] - The `[key: string]` represents the key of each property in the
 * `ModalActions` object. It can be any string value.
 */
export type ModalActions = {
    key: string;
    label: ReactNode;
    className?: string;
    sx?: { [key: string]: unknown };
    handleClick?: (arg0?: NonNullable<unknown>) => void;
    variant: 'text' | 'outlined' | 'contained';
    color?: 'success' | 'error';
    disabled?: boolean;
    close?: boolean;
    footerClass?: string;
    stackedKey?: string;
    icon?: any;
    isLoading?: boolean;
    testId?: string;
};

/* The code is defining a custom modal component called `CustomModal` using React's `forwardRef`
function. This component takes in various props defined by the `CustomModalProps` type. */
export const CustomModal = React.forwardRef((props: CustomModalProps, ref): JSX.Element => {
    const theme: any = useTheme();
    const [isOpen, setIsOpen] = React.useState<boolean>(props?.isOpen || false);
    const [stackedActions, setStackedActions] = React.useState<any>([]);

    const { type = true } = props;
    React.useEffect(() => {
        if (props?.isStacked) {
            setStackedActions(props?.actions?.filter((item) => item?.stackedKey));
        }
    }, [props?.isStacked, props.actions]);

    /* The `React.useImperativeHandle` hook is used to expose certain functions or methods of a child
    component to its parent component. In this case, it is used to expose the `modalHandler`
    function to the parent component. */
    React.useImperativeHandle(ref, () => ({
        modalHandler: (action: boolean) => modalHandler(action),
    }));

    /* The `React.useEffect` hook is used to perform side effects in a React component. In this case,
    it is used to update the state of the `isOpen` variable whenever the `props.isOpen` value
    changes. */
    React.useEffect(() => {
        if (props?.isOpen !== isOpen) {
            setIsOpen(props.isOpen);
        }
    }, [props?.isOpen]);

    /**
     * The function `modalHandler` takes a boolean parameter `action` and sets the state variable
     * `isOpen` to the value of `action`.
     * @param {boolean} action - The `action` parameter is a boolean value that determines whether the
     * modal should be opened or closed. If `action` is `true`, the modal will be opened. If `action`
     * is `false`, the modal will be closed.
     */
    const modalHandler = (action: boolean): void => {
        setIsOpen(action);
    };

    /**
     * The function `handleActionButtonClick` handles the click event of an action button and calls the
     * corresponding action's `handleClick` function, and closes a modal if the button text is
     * "Cancel".
     * @param {string} buttonText - The `buttonText` parameter is a string that represents the text of
     * the button that was clicked.
     */
    const handleActionButtonClick = (buttonAction?: ModalActions): void => {
        if (buttonAction?.handleClick) {
            buttonAction?.handleClick();
        }

        if (buttonAction?.close) {
            modalHandler(false);
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={(): void => {
                const closeButton = props?.actions?.find((action) => action.close === true);
                handleActionButtonClick(closeButton);
            }}
            className={clsx({
                'modal-wrapper': true,
            })}
            classes={{
                paper: clsx({
                    'width-300': type === 'popup',
                    'width-480': type === 'primary',
                    'width-572': type === 'small',
                    'width-712': type === 'medium',
                    'width-900': type === 'secondary',
                    'width-1178': type === 'large',
                    'max-width-1200': true,
                }),
            }}
            TransitionComponent={Transition}
        >
            <DialogTitle className="modal-header">
                {props?.customHeader ? (
                    props.customHeader
                ) : (
                    <Typography
                        variant="body1"
                        component={'h1'}
                        className={`modal-text-header ${props?.unTrimHeader ? 'overflow-wrap' : 'text-truncate'}`}
                    >
                        {props?.header}
                    </Typography>
                )}
                {props?.subHeader && (
                    <Typography variant="body1" fontSize={'16px'} mt={'16px'}>
                        {props?.subHeader}
                    </Typography>
                )}
            </DialogTitle>

            {!props?.noDividers && <Divider />}

            <DialogContent
                className={clsx({
                    'modal-body': true,
                    'min-height-200': props?.isLoading,
                    'padding-0': props?.hidePadding,
                    [props.contentClass as any]: props?.contentClass ?? false,
                })}
            >
                {props.isLoading ? <Loader size={40} /> : props?.children}
            </DialogContent>

            {!props?.noDividers && <Divider />}
            {props.actionsDivider && <Divider />}

            <DialogActions className={`gap-16 padding-y-16 ${props?.footerClass ?? ''}`}>
                {props?.actions?.map(
                    (action: ModalActions) =>
                        !action?.stackedKey && (
                            <Button
                                data-test={action?.testId}
                                key={`q${action?.key}`}
                                className={action?.className}
                                disabled={action?.disabled ?? false}
                                {...(action?.isLoading && {
                                    startIcon: <CircularProgress color="inherit" size={20} />,
                                })}
                                {...(action?.color === 'error' && { color: action?.color })}
                                onClick={(): void => handleActionButtonClick(action)}
                                autoFocus
                                variant={action?.variant}
                                sx={{
                                    ...(action?.sx ?? {}),
                                    border:
                                        action?.variant === 'outlined'
                                            ? `1px solid ${theme?.palette?.primary?.main}`
                                            : '',
                                    backgroundColor:
                                        action?.variant === 'contained' ? theme?.palette?.primary?.main : '',

                                    '&:hover': {
                                        backgroundColor:
                                            action?.variant === 'outlined'
                                                ? theme?.palette?.primary?.[50]
                                                : action?.variant === 'contained'
                                                ? theme?.palette?.primary?.main
                                                : '',
                                    },

                                    '&.Mui-disabled': {
                                        border:
                                            action?.variant === 'outlined'
                                                ? `1px solid ${theme?.palette?.primary?.[200]}`
                                                : '',
                                        color: action?.color === 'error' ? '#fff' : theme?.palette?.primary?.[200],

                                        backgroundColor:
                                            action?.color === 'error'
                                                ? '#CA3C3D'
                                                : action?.variant === 'outlined' || action?.variant === 'contained'
                                                ? theme?.palette?.primary?.[50]
                                                : '',
                                    },
                                }}
                            >
                                {action?.label}
                            </Button>
                        )
                )}
                {props?.isStacked && (
                    <Stack direction={'row'} spacing={2}>
                        {stackedActions?.map((action: any) => (
                            <Button
                                data-test={action?.testId}
                                key={`q${action?.key}`}
                                className={action?.className}
                                startIcon={action?.icon}
                                disabled={action?.disabled ?? false}
                                {...(action?.color === 'error' && { color: action?.color })}
                                onClick={(): void => handleActionButtonClick(action)}
                                autoFocus
                                variant={action?.variant}
                                sx={{
                                    ...(action?.sx ?? {}),
                                    border:
                                        action?.variant === 'outlined'
                                            ? `1px solid ${theme?.palette?.primary?.main}`
                                            : '',
                                    backgroundColor:
                                        action?.variant === 'contained' ? theme?.palette?.primary?.main : '',
                                    color: action?.variant === 'contained' ? '#fff' : '',

                                    '&:hover': {
                                        backgroundColor:
                                            action?.variant === 'outlined'
                                                ? theme?.palette?.primary?.[50]
                                                : action?.variant === 'contained'
                                                ? theme?.palette?.primary?.main
                                                : '',
                                    },
                                    '&.Mui-disabled': {
                                        color: action?.color === 'error' ? '#fff' : theme?.palette?.primary?.[200],
                                        backgroundColor:
                                            action?.color === 'error'
                                                ? '#CA3C3D'
                                                : action?.variant === 'outlined' || action?.variant === 'contained'
                                                ? theme?.palette?.primary?.[50]
                                                : '',
                                    },
                                }}
                            >
                                {action?.label}
                            </Button>
                        ))}
                    </Stack>
                )}
            </DialogActions>
        </Dialog>
    );
});
