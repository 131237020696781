import { RootState } from '../../app/store';
import { createSlice } from '@reduxjs/toolkit';
import { channelsHandler } from './helpers';

const tempSlice = createSlice({
    name: 'trendsTempData',
    initialState: {
        channelId: [],
        legendData: [],
        hierarchyChannelData: [],
        sourceId: undefined,
        deviceId: undefined,
    },

    reducers: {
        setSourceId: (state, action) => {
            state.sourceId = action.payload;
        },
        setDeviceId: (state, action) => {
            state.deviceId = action.payload;
        },
        trendsChannelId: (state, action) => {
            state.channelId = action.payload;
        },
        trendsLegendData: (state, action) => {
            state.legendData = action.payload;
        },
        trendsHierachyChannelData: (state, action) => {
            state.hierarchyChannelData = action.payload;
        },
        channelChangeHandler: (state, action) => {
            const newLegendData = JSON.parse(JSON.stringify(state?.legendData));
            const index = newLegendData?.findIndex((legend: any): any => legend.channelId === action?.payload.id);
            if (index > -1) {
                newLegendData[index].visible = action.payload.checked;
            }
            const newChannelIds: any = [];
            newLegendData.forEach((legend: any) => {
                if (legend.visible) {
                    newChannelIds.push(legend.channelId);
                }
            });
            state.legendData = newLegendData;
            state.channelId = newChannelIds;
        },
        setMobileTrends: (state, action) => {
            channelsHandler(state, action);
        },
    },
});

export const {
    trendsChannelId,
    setMobileTrends,
    trendsHierachyChannelData,
    setSourceId,
    setDeviceId,
    channelChangeHandler,
    trendsLegendData,
} = tempSlice.actions;
export const selectChannelId = (state: RootState): any => state.trendsTempData.channelId;
export const selectLegendData = (state: RootState): any => state.trendsTempData.legendData;
export const sourceId = (state: RootState): any => state.trendsTempData.sourceId;
export const deviceId = (state: RootState): any => state.trendsTempData.deviceId;
export const hierarchyChannelData = (state: RootState): any => state.trendsTempData.hierrachyChannelData;

export default tempSlice;
