import CorporateFare from '@mui/icons-material/CorporateFare';
import Language from '@mui/icons-material/Language';
import { Avatar } from '@mui/material';
import React from 'react';
import * as Colors from '@brightlayer-ui/colors';
import CustomIcon from '../CustomIcon';

export const getHierarchyIcons = (type: string): JSX.Element => {
    switch (type) {
        case 'Organization': {
            return <CorporateFare id="org" />;
        }
        case 'Realm': {
            return <Language />;
        }
        default: {
            return <></>;
        }
    }
};

const getAvatarSx = (treeItem: any, selectionType?: string, selectedNodes?: string[] | string): any => {
    if ((selectionType === 'radio' || selectionType === 'node') && selectedNodes !== treeItem?.id) {
        return { color: Colors.gray[500], backgroundColor: Colors.gray[50] };
    }
};

const getSpanStyle = (treeItem: any, selectionType?: string, selectedNodes?: string[] | string, theme?: any): any => {
    if (
        !selectionType ||
        selectionType === 'checkbox' ||
        ((selectionType === 'radio' || selectionType === 'node') && selectedNodes !== treeItem?.id)
    ) {
        return { color: Colors.gray[500] };
    }
    return { color: theme?.palette?.primary?.main };
};

export const renderRealmHierarchyIcons = (
    treeItem: any,
    selectionType?: string,
    selectedNodes?: string[] | string,
    theme?: any
): JSX.Element => (
    <Avatar
        className="height-40 width-40 margin-right-16"
        src={treeItem?.logo ?? ''}
        sx={{
            ...(treeItem?.id?.includes('preview-') ? { border: `2px solid ${theme?.palette?.primary?.main}` } : {}),
            ...getAvatarSx(treeItem, selectionType, selectedNodes),
        }}
    >
        {getHierarchyIcons(treeItem?.type)}
    </Avatar>
);

export const renderGroupHierarchyIcons = (
    treeItem: any,
    selectionType?: string,
    selectedNodes?: string[] | string,
    theme?: any,
    coloredIcon?: string
): JSX.Element => (
    <div
        style={{
            ...getSpanStyle(treeItem, selectionType, selectedNodes, theme),
            ...(treeItem?.id?.includes('preview-') ? { color: theme?.palette?.primary?.main } : {}),
            marginRight: '8px',
            display: 'flex',
        }}
    >
        <CustomIcon
            iconName={treeItem?.icon?.web?.name}
            family={treeItem?.icon?.web?.family}
            {...(treeItem?.alarmCount && coloredIcon && { iconProps: { ...treeItem?.icon?.web, color: coloredIcon } })}
            {...(treeItem?.icon?.web?.sx && { iconProps: { ...treeItem?.icon?.web } })}
        />
    </div>
);
