import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { removeHistory } from '@fiji/common/src/features/common/historySlice';
import { useNavigate } from 'react-router-dom';

export const useNavigationBack = (): any => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const history = useTypedSelector((state) => state.history.history);

    const navigationBack = (level: number = 1): void => {
        const previousLocation = history[history.length + (-1 - level)];
        dispatch(removeHistory(level));
        navigate(`${previousLocation.pathname}${previousLocation.hash ?? ''}`, { state: previousLocation.state });
    };
    return navigationBack;
};
