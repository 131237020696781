import { createSlice } from '@reduxjs/toolkit';

const handleDataOperations = (state: any, action: any, stateKey: string): void => {
    switch (action.payload.filterType) {
        case 'input-text':
        case 'calendar':
        case 'single-select':
        case 'multi-chips': {
            if (!state[stateKey][action.payload.selectorKey]) {
                state[stateKey][action.payload.selectorKey] = {
                    [action.payload.filterKey]: action.payload.value,
                };
            } else if (action.payload.value === '' || !action.payload.value) {
                delete state[stateKey][action.payload.selectorKey][action.payload.filterKey];
            } else {
                state[stateKey][action.payload.selectorKey][action.payload.filterKey] = action.payload.value;
            }
            if (
                !state[stateKey][action.payload.selectorKey]?.[action.payload.filterKey]?.length &&
                action.payload.filterType === 'multi-chips'
            ) {
                delete state[stateKey][action.payload.selectorKey][action.payload.filterKey];
            }
            break;
        }
        case 'multi-select': {
            if (!state[stateKey][action.payload.selectorKey]) {
                state[stateKey][action.payload.selectorKey] = {
                    [action.payload.filterKey]: Array.isArray(action.payload.value)
                        ? action.payload.value
                        : [action.payload.value],
                };
            } else if (!state[stateKey][action.payload.selectorKey][action.payload.filterKey]) {
                state[stateKey][action.payload.selectorKey][action.payload.filterKey] = Array.isArray(
                    action.payload.value
                )
                    ? action.payload.value
                    : [action.payload.value];
            } else if (Array.isArray(action.payload.value)) {
                state[stateKey][action.payload.selectorKey][action.payload.filterKey] = action.payload.value;
            } else {
                const filterIndex = state[stateKey][action.payload.selectorKey][action.payload.filterKey].findIndex(
                    (item: string) => item === action.payload.value
                );
                if (filterIndex !== -1) {
                    state[stateKey][action.payload.selectorKey][action.payload.filterKey].splice(filterIndex, 1);
                } else {
                    state[stateKey][action.payload.selectorKey][action.payload.filterKey].push(action.payload.value);
                }
            }
            if (!state[stateKey][action.payload.selectorKey][action.payload.filterKey].length) {
                delete state[stateKey][action.payload.selectorKey][action.payload.filterKey];
            }
            break;
        }
        case 'multi-calendar': {
            const { selectorKey, filterKey, value } = action.payload;

            if (!state[stateKey][selectorKey]) {
                state[stateKey][selectorKey] = {};
            }

            if (value === '' || !value) {
                if (filterKey === 'from' || filterKey === 'to') {
                    const hasFromDate = state[stateKey][selectorKey]?.['from'];
                    const hasToDate = state[stateKey][selectorKey]?.['to'];

                    if (!hasFromDate || !hasToDate) {
                        delete state[stateKey][selectorKey]['from'];
                        delete state[stateKey][selectorKey]['to'];
                    } else {
                        delete state[stateKey][selectorKey][filterKey];
                    }
                }
            } else {
                state[stateKey][selectorKey][filterKey] = value;

                if (filterKey === 'from' && !state[stateKey][selectorKey]['to']) {
                    state[stateKey][selectorKey]['to'] = ''; // Or some default value
                } else if (filterKey === 'to' && !state[stateKey][selectorKey]['from']) {
                    state[stateKey][selectorKey]['from'] = ''; // Or some default value
                }
            }

            if (!state[stateKey][selectorKey]['from'] && !state[stateKey][selectorKey]['to']) {
                delete state[stateKey][selectorKey];
            }
            break;
        }

        default: {
            break;
        }
    }
};

const handleResetApplyOperations = (state: any, action: any, preStateKey: string, stateKey: string): void => {
    if (action.payload.type === 'RESET') {
        delete state[preStateKey][action.payload.selectorKey];
        delete state[stateKey][action.payload.selectorKey];
    } else {
        state[stateKey][action.payload.selectorKey] = state[preStateKey][action.payload.selectorKey];
    }
};

const dataOperationsSlice = createSlice({
    name: 'dataOperations',
    initialState: {
        preSortValues: {},
        preFilterValues: {},
        selectedFilterValues: {},
        selectedSortValues: {},
        availableOptions: {},
        searchKey: {},
    } as any,
    reducers: {
        handleRemoveFilter: (state, action) => {
            if (
                action.payload?.selectorKey &&
                action.payload?.filterKey &&
                state.preFilterValues?.[action.payload.selectorKey]?.[action.payload.filterKey]
            ) {
                if (action.payload.filterKey === 'from') {
                    delete state.preFilterValues[action.payload.selectorKey][action.payload.filterKey];
                    delete state.preFilterValues[action.payload.selectorKey]['to'];
                } else delete state.preFilterValues[action.payload.selectorKey][action.payload.filterKey];
            }
        },
        handleChangePreFilterValue: (state, action) => {
            handleDataOperations(state, action, 'preFilterValues');
        },
        handleResetPreFilterValue: (state, action) => {
            handleResetApplyOperations(state, action, 'selectedFilterValues', 'preFilterValues');
        },
        handleSetAvailableOptions: (state, action) => {
            if (!state.availableOptions[action.payload.selectorKey]) {
                state.availableOptions[action.payload.selectorKey] = {
                    [action.payload.fieldKey]: action.payload.options,
                };
            } else if (action.payload.options.length === 0) {
                delete state.availableOptions[action.payload.selectorKey][action.payload.fieldKey];
            } else {
                state.availableOptions[action.payload.selectorKey][action.payload.fieldKey] = action.payload.options;
            }
        },
        handleChangeSortValue: (state, action) => {
            if (!state.preSortValues[action.payload.selectorKey]) {
                state.preSortValues[action.payload.selectorKey] = {
                    [action.payload.key]: action.payload.value,
                };
            } else {
                state.preSortValues[action.payload.selectorKey][action.payload.key] = action.payload.value;
            }
        },
        handleApplyResetSorting: (state, action) => {
            handleResetApplyOperations(state, action, 'preSortValues', 'selectedSortValues');
        },
        handleApplyResetFiltering: (state, action) => {
            handleResetApplyOperations(state, action, 'preFilterValues', 'selectedFilterValues');
        },
        handleGlobalSearch: (state, action) => {
            if (action.payload.value === '') {
                delete state.searchKey[action.payload.selectorKey];
            } else {
                state.searchKey[action.payload.selectorKey] = action.payload.value;
            }
        },
    },
});

export const {
    handleRemoveFilter,
    handleChangePreFilterValue,
    handleResetPreFilterValue,
    handleSetAvailableOptions,
    handleChangeSortValue,
    handleApplyResetSorting,
    handleApplyResetFiltering,
    handleGlobalSearch,
} = dataOperationsSlice.actions;

export default dataOperationsSlice;
