import React from 'react';

import { FormHelperText, List, RadioGroup, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { LinkButtonSkeleton } from './LinkButtonSkeleton';

export const FormSkeleton = (): JSX.Element => (
    <>
        <Skeleton animation="wave">
            <Typography variant="subtitle1" fontWeight={'600'} my={2}>
                Give your new widget a name and description
            </Typography>
        </Skeleton>
        <Skeleton sx={{ maxWidth: 'none' }} animation="wave">
            <TextField fullWidth name="name" label="Name" variant="filled" className="margin-top-18" required />
        </Skeleton>
        <Stack direction={'row'} justifyContent={'space-between'}>
            <Skeleton animation="wave">
                <FormHelperText>Required</FormHelperText>
            </Skeleton>
            <Skeleton animation="wave">
                <FormHelperText>Required</FormHelperText>
            </Skeleton>
        </Stack>
        <Skeleton sx={{ maxWidth: 'none' }} animation="wave">
            <TextField fullWidth name="name" label="Name" variant="filled" className="margin-top-18" required />
        </Skeleton>
        <Skeleton animation="wave">
            <FormHelperText>Required</FormHelperText>
        </Skeleton>
        <Skeleton animation="wave">
            <Typography variant="subtitle1" fontSize={'16px'} fontWeight={'600'} mt={'18px'}>
                Is this a System or User widget?
            </Typography>
        </Skeleton>
        <List>
            <RadioGroup>
                <LinkButtonSkeleton total={2} />
            </RadioGroup>
        </List>
    </>
);
